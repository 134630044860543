$cover-page-color-bg: rgba(0, 0, 0, 0.6);
.page-cover {
    border-bottom: 1px solid #ccc;
    padding: 0;
    position: relative;
    // margin-bottom: $line-height-computed / 2;
    margin-bottom: 0;
    // margin-top: $line-height-computed / -2;
    background-color: transparent;
    color: #333;
    .container-fluid {
        /*     margin-top: -60px; */
        position: relative;
    }
    .thumbnail-actions {
        /*     top: -40px; */
    }
    .thumbnail {
        /*     margin-top: -40px; */
        .thumbnail-actions {
            top: 0;
        }
    }

    // @media (max-width: $screen-xs-max) {
    // padding: 10px 0;
    // }
}
.page-cover-image {
    @extend %cover-background;
    position: relative;
    overflow: hidden;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;

        bottom: 0;
        background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.66));
    }

    // &:after{
    //      content:'';
    //      position: absolute;
    //      // bottom: 0px;
    //      // top: 0;
    //      // left:0px;
    //      // right:0px;
    //      background:inherit;
    //      // background-size: 50%;
    //      width:200%;
    //      height:200%;
    //      top: -50%;
    //      left: -50%;
    //      // background:red;
    //      // background-position: center bottom;
    //      mask-image: linear-gradient(top, rgba(0,0,0,0) 40%, rgba(0,0,0,1) 80%);
    //      filter: blur(100px) brightness(.99);

    //    }

    .container-fluid {
        height: 220px;
        position: relative;

        @media (max-width: $screen-xs-max) {
            height: 170px;
        }
    }
    .thumbnail-actions {
        top: 15px;
        // bottom: 10px;
        opacity: 1;
        right: 8px;
        z-index: 1;
    }
}

.page-cover-content {
    margin: -185px 0 30px;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-end;

    @media (max-width: $screen-sm-max) {
        margin: -185px 0 20px;
    }
    @media (max-width: $screen-xs-max) {
        margin: -50px 0 20px;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;
    }
    .thumbnail-actions {
        opacity: 1;
    }

    h1,
    h2 {
        font-weight: 600;
        // float:left;
        // clear:left;
        color: #fff;
        text-shadow: none;
        //text-shadow: 0 0 4px black;
        letter-spacing: 1px;
        //text-shadow: 0 1px 0 #999,
        //           0 2px 0 #666,
        //          /*   0 3px 0 #bbb,
        //          0 4px 0 #b9b9b9,
        //           0 5px 0 #aaa, */
        //           0 6px 1px rgba(0,0,0,.1),
        //           0 0 5px rgba(0,0,0,.1),
        //           0 1px 3px rgba(0,0,0,.3),
        //           0 3px 5px rgba(0,0,0,.2),
        //           0 5px 10px rgba(0,0,0,.25),
        //           0 10px 10px rgba(0,0,0,.2),
        //           0 20px 20px rgba(0,0,0,.15);

        margin: 0;
        @media (max-width: $screen-xs-max) {
            text-shadow: none;
            color: #333;
        }
    }
    h1 {
        font-size: 40px;
        // margin-top: 15px + 60px;
        @media (max-width: $screen-sm-max) {
            font-size: 40px;
        }
        @media (max-width: $screen-xs-max) {
            font-size: 24px;
        }
    }
    h2 {
        button {
            margin-left: 15px;
        }
        font-size: 24px;
        @media (max-width: $screen-sm-max) {
            font-size: 24px;
        }
        @media (max-width: $screen-xs-max) {
            font-size: 16px;
            line-height: 2;
            button {
                margin-left: 0;
            }
        }
    }
}
.page-cover-thumbnail {
    flex: 0 0 170px;
    padding: 0 20px 0 0;
    // padding: 0 20px;
    // background: red;

    @media (max-width: $screen-xs-max) {
        flex: 0 0 auto;
        width: 100px;
        padding: 0 0 20px 0;
    }

    .thumbnail,
    .thumbnail-content {
        margin: 0;
    }
}
.page-cover-text {
    flex: 1 1 auto;
    padding: 0 0 0 20px;
    // background: yellow;

    .page-cover-title {
        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    }

    .page-cover-subtitle {
        font-size: 16px;
    }

    @media (max-width: $screen-xs-max) {
        align-self: stretch;
        padding: 0;

        .page-cover-title {
            font-size: 22px;
            text-shadow: none;
        }

        .page-cover-subtitle {
            font-weight: 300;
            color: #9b9b9b;
        }
    }
}
.page-cover- .page-cover-actions {
    @extend .thumbnail-actions;
    background: transparent;
    border: none;
    .thumbnail-action {
        background: $btn-default-bg;
        border: 1px solid $btn-default-border;
        margin-bottom: 0.15em;
    }
}
.page-cover-actions {
    @media (max-width: $screen-xs-max) {
        top: 0;
    }
}
