.devices-device-settings-set {
    // padding-left: 30px;
    padding-bottom: 20px;
    padding-top: 20px;
}

.devices-device-settings-set-title {
    //font-weight: 700;
    color: #737373;
    font-size: 20px;

    &:hover,
    &:focus {
        text-decoration: none !important;
        //font-weight: bold;
    }

    i.fa {
        line-height: normal;
    }
}

.devices-device-settings-set-subtitle {
    font-size: 16px;
    display: block;
    color: #919191;
    margin-top: -7px;
}

.devices-device-settings-set-input-group {
    padding-left: 20px;

    div {
        padding-bottom: 15px;
    }
}

.devices-device-settings-set-input-label {
    color: #737373;
    font-size: 18px;
    padding-bottom: 4px;
    padding-left: 5px;
}

.devices-device-settings-set-input {
    font-weight: bold;
    font-size: 16px;
    padding-left: 10px;
    width: 250px;
}

.devices-device-settings-schedule-time {
    font-size: 14px;
}

.devices-device-settings-brightness {
    width: 50px;
    font-size: 14px;
}

.devices-device-settings-schedule-table {
    tr {
        height: 40px;
    }

    span {
        font-size: 18px;
    }

    margin-left: 30px;
    padding-bottom: 20px;
}

.devices-device-settings-simple-checkbox {
    padding-top: 10px;

    button {
        //    vertical-align: baseline;
        vertical-align: 2px;
    }

    span {
        font-size: 18px;
        padding-left: 0px;
    }
}

.devices-device-settings-simple-input {
    padding-top: 10px;

    span {
        font-size: 18px;
    }

    input {
        width: 75%;
    }
}

.devices-device-settings-color-preview {
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: -8px;
    border: black solid 1px;
}

.devices-device-settings-schedule-line {
    input {
        font-size: 16px;
    }
}

.devices-content-header {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 18px;
}

.custom-select {
    select {
        background: transparent;
        border: 0;
        -webkit-appearance: none;
        width: 100%;
        height: 35px;
        //    padding-left: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #737373 !important;
    }

    i {
        position: relative;
        //    top: -33px;
        //    right: 10px;
        left: -38px;
        top: 6px;
        color: #737373 !important;
        z-index: -1;
    }

    span {
        border-color: #737373;
        border-style: solid;
        border-width: 1px;
        width: 250px;
        height: 35px;
        display: inline-block;
        vertical-align: 3px;
    }
}

.custom-select[disabled] {
    span {
        border-color: #b5b5b5 !important;
    }

    select {
        color: #b5b5b5 !important;
    }

    i {
        color: #b5b5b5 !important;
    }
}
