.search {
    height: 48px;
    vertical-align: middle;
    cursor: pointer;
    align-items: center;

    .image-container {
        display: inline-block;
        width: 44px;
        height: 44px;
        border: 1px solid $gray-light-2;
        background-color: $gray-lighter;
        vertical-align: middle;
        margin-right: 5px;

        &.image-container-device {
            border: none;
        }

        .search-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    //.active > & {
    //    background-color: $gray-lighter !important;
    //}
}
