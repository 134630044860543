.btn-primary {
    &:hover,
    &:focus,
    &:active {
        background: lighten($brand-primary, 5%);
        color: #fff;
        border-color: lighten($brand-primary, 5%);
    }

    // &.disabled:hover,
    // &[disabled]:hover,
    // {
    // color: lighten($brand-primary, 5%);
    // }
}

.btn-default {
    &:hover,
    &:focus,
    &:active {
        background: $brand-primary;
        color: #fff;
        border-color: $brand-primary;
    }

    &.disabled:hover,
    &[disabled]:hover {
        color: $brand-primary;
    }
}

.btn-success {
    &:hover,
    &:focus,
    &:active {
        background: $brand-success;
        color: #fff;
        border-color: $brand-success;
    }

    &.disabled:hover,
    &[disabled]:hover {
        color: $brand-success;
    }
}

.btn-info {
    &:hover,
    &:focus,
    &:active {
        background: $brand-info;
        color: #fff;
        border-color: $brand-info;
    }

    &.disabled:hover,
    &[disabled]:hover {
        color: $brand-info;
    }
}

.btn-warning {
    &:hover,
    &:focus,
    &:active {
        background: $brand-danger;
        color: #fff;
        border-color: $brand-danger;
    }

    &.disabled:hover,
    &[disabled]:hover {
        color: $brand-danger;
    }
}

.btn-danger {
    background: $brand-danger !important;
    border-color: $brand-danger !important;
    color: #fff !important;

    &:hover,
    &:focus,
    &:active {
        background: lighten($brand-danger, 5%) !important;
        border-color: lighten($brand-danger, 5%) !important;
    }

    // &.disabled:hover,
    // &[disabled]:hover,
    // {
    //   color: $brand-danger;
    // }
}

.action-bar button.btn.btn-link.active {
    color: $brand-primary;
}

.action-bar button.btn.btn-link {
    color: lighten($brand-primary, 30%);
}

.listview-actions {
    min-width: 150px;
}

.listview-action-btn {
    display: none;
}

.listview-action-btn.btn {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 6px !important;
    padding-right: 6px !important;
}

.listview-row:hover .listview-action-btn,
.listview-show-actions .listview-action-btn {
    display: inline-block;
}

.listview-action-ph {
    display: inline-block;
    text-align: center;
}

.listview-row:hover .listview-action-ph,
.listview-actions.sticky .listview-action-ph,
.listview-show-actions .listview-action-ph {
    display: none;
}

.listview-actions.sticky .listview-action-btn {
    display: inline-block;
}

.event-content-action-row {
    display: none;
}

.event-content-question-row {
    display: block;
}

.event-content-question-row:hover .event-content-action-row {
    display: block;
    /*position: relative;*/
    /*position: absolute;
  top:0;
  right: 0;*/
}

.event-content-question-row:hover .event-content-action-row,
.event-content-question-row-editor {
    padding: 2px;
    margin-bottom: 5px;
    background-color: #eeeeee;
    /*margin-top: -40px;*/
}

.event-content-question-row:hover {
    /*border: 1px solid #eeeeee;*/
    padding: 2px;
    margin-bottom: 5px;
    background-color: #eeeeee;
    display: inline-block;
}

.discovery-button {
    .btn {
        text-transform: uppercase;
        font-weight: 500;
        padding: 8px 5px 6px 5px;
        height: 40px;
        margin-bottom: 5px;
        font-size: 16px;
        border: 1px solid #000000;
        letter-spacing: 0.36px;
        width: 200px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:hover {
            border: 1px solid #000000;

            &.btn-danger {
                border-color: lighten($brand-danger, 13.5%);
            }
        }

        span {
            height: 19px;
        }

        &-icon {
            margin-right: 10px;
            display: inline-flex;
            align-items: end;
        }

        &:focus {
            border: 1px solid #000000;
        }
    }

    .btn-primary {
        background-color: #231f20;
        //&:hover {
        //  background-color: #000000;
        //}

        //&:focus {
        //  background-color: #000000;
        //}
    }

    .btn-default {
        color: #000000;
    }

    .btn-default,
    .btn-warning {
        border-color: #b9b9b9;
        background-color: #fff;
        border-width: 0.6px;
        font-size: 14px;
    }

    .btn-danger {
        border-color: $brand-danger;
    }

    .btn-warning {
        color: #e02020;
        //  @include svg-color(#E02020);
    }
}

.join-wallet-connection-button {
    padding: 8px 16px;
    margin-bottom: 10px;
    width: 250px;
    border-radius: 8px;
    color: white;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        color: white;
    }
}

.home-wallet-connection-button {
    padding: 8px 16px;
    margin-bottom: 10px;
    width: 200px;
    border-radius: 8px;
    color: white;
    font-size: 18px;

    &:hover {
        color: white;
    }
}

.wallet-connect {
    background-image: linear-gradient(to right, #8702ff, #2201ff);
}

.metamask {
    background-image: linear-gradient(to right, #ff6a01, #ffdd27);
}

.portis-connect {
    background-color: white;
    border-color: #d8d8d8;
    color: #416c9e;
}

.inner-join-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.join-show-more {
    margin: 15px;
    color: #747070;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
    cursor: pointer;
}

.learn-more {
    margin: 5px;
    color: #747070;
    text-transform: capitalize;
    cursor: pointer;
}

.manage-wallets {
    background-color: #fff;
    border-radius: 5px;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
    min-width: 300px;
    margin-top: 30px;
    margin-bottom: 40px;
    border: 1px solid #d8d8d8;
    font-weight: 600;
    box-shadow: none;
    cursor: pointer;
    position: relative;

    .manage-wallets-content {
        display: flex;
        flex-direction: column;

        .manage-wallets-title {
            display: flex;
            color: #39393a;
        }

        .manage-wallets-subtitle {
            display: flex;
            color: #9b9b9b;
            font-size: 12px;
        }
    }

    .manage-wallets-pimple {
        background-color: #4bae00;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        font-size: 9px;
        color: #fff;
        position: absolute;
        top: 12px;
        right: 19px;

        .manage-wallets-pimple-text {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            -webkit-font-smoothing: antialiased;
            position: relative;
            top: 1px;
            right: -0.5px;
        }
    }
}

.header-wallet-button {
    border: none;
    padding: 15px 20px;
    margin-top: auto;
    background-color: transparent;
}

.header-wallet-navbar {
    border: none;
    padding: 15px 15px 15px 10px;
    margin-top: auto;
    background-color: transparent;
}
