$color-white: #fff;
// $color-hp: #23d4a4;
$color-hp: $brand-primary;

$slidestatic: 3s;
$slidetransition: 2s;
$slidetime: $slidestatic + $slidetransition;
$number-of-slides: 5;
$slidecycle: $slidetime * $number-of-slides;

body {
    &.home-page {
        padding-top: 50px;
    }

    &.unverified {
        padding-top: 60px;
    }
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    #{percentage($slidestatic / $slidecycle)} {
        opacity: 1;
    }

    #{percentage($slidetime / $slidecycle)} {
        opacity: 0;
    }

    #{100% - percentage($slidetransition / $slidecycle)} {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.btn-hp {
    @include button-variant($color-white, $color-hp, $color-hp);
    border-radius: 0;
    font-family: MuseoSans, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    font-weight: 300;
    padding: 10px 26px 6px;

    &:hover,
    &:active,
    &:focus {
        // background-color: #CF2D48;
        // border-color: #CF2D48;
    }
}

.hp-info {
    background: #f2f2f2;
    text-align: right;

    .col-md-6 {
        padding: 30px 80px 40px;

        @media (max-width: $screen-xs-min) {
            padding: 20px;
        }

        + .col-md-6 {
            @media (max-width: $screen-xs-min) {
                border-top: 1px solid #111;
            }
        }
    }

    p,
    h3 {
        text-align: left;
    }
}

.social-links {
    margin-right: 10px;

    li:nth-child(1),
    li:nth-child(2) {
        a {
            padding: 15px - 1px 5px 15px;
        }

        img {
            height: 19px + 1px;
        }
    }

    a {
        // background: red;
        padding: 15px 5px;
        margin: 0 4px;
        height: 49px;

        // vertical-align: bottom;
    }

    img {
        // vertical-align: middle;
        height: 22px;

        &:hover {
            opacity: 0.7;
        }

        // background: green;
    }

    @media (max-width: $screen-md-min - 1) {
        margin-left: 5px;
        // margin-bottom: 0;
    }
}

.home-page,
#home-page {
    .home-page-main .container-fluid {
        font-family: 'Muli', sans-serif;
        max-width: 800px;
        @media screen and (min-width: $screen-md-min) {
            min-height: calc(100vh - 235px);
            display: flex;
            flex-direction: column;
            align-items: strech;
            justify-content: center;
            margin: auto;
        }
    }

    // .home-page .home-page-main .container-fluid {
    //     display: flex;
    //     flex-direction: column;
    //     margin: auto;
    //     justify-content: stretch;
    //     align-items: center;
    // }

    .btn-cta {
        font-size: 16px;
        font-weight: 500;
        height: 42px;
        background-color: $brand-custom;
        border: 1px solid $brand-custom;
        color: #ffffff;
    }

    .btn-cta:hover,
    .btn-cta:focus {
        color: #fb052f;
        background-color: #ffffff;
        border: 1px solid $brand-custom-hover;
    }

    .btn-cta-light {
        background-color: #ffffff;
        color: $brand-custom;
        border: 1px solid $brand-custom;
    }

    .btn-cta-light:hover,
    .btn-cta-light:focus {
        background-color: $brand-custom;
        color: #ffffff;
        border: 1px solid $brand-custom;
    }

    &-main {
        overflow: hidden;

        .container-fluid {
            //position: relative;
            height: auto; // Set the height of the background image
            // padding-top: 200px;

            @media (max-width: $screen-xl-min - 1) {
                // padding-top: 130px;
                //margin-left:-212px;
                //top: 35px;
            }

            //@media (max-width: $screen-md-min - 1)  {
            //
            //}
            @media (max-width: $screen-md-min - 1) {
                padding-top: 80px;
            }
        }

        background-repeat: no-repeat;
        background-position: center top;
        //background-size: 100% 812px;
        background-size: cover;

        @media (max-width: $screen-xl-min - 1) {
            //background-position: center center;
            background-size: 1380px;
            background-position-y: 30%;
        }

        @media (max-width: $screen-sm-min - 1) {
            background-size: 1640px;
            background-position-y: 30%;
        }

        //@media (max-width: $screen-md-min - 1)  {
        //
        //}

        .home-page-main-text {
            text-align: center;

            button,
            a {
                font-size: 16px;

                &.btn-cta {
                    width: 245px;
                }
            }

            &-sub {
                margin-top: 20px;
                font-size: 18px !important;
            }

            h3 {
                font-size: 38px;
            }

            .big-title {
                margin-top: 8px;
                line-height: normal;
                margin-bottom: 5px;
                max-width: 20em;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                font-weight: 500;

                &.title2 {
                    margin-top: 33px;
                }
            }

            .label-minor {
                font-size: 24px;
                margin-bottom: 0;
            }

            @media (min-width: $screen-sm-min) {
                padding: 0px 34px;
            }

            @media (max-width: $screen-xl-min - 1) {
                padding-top: 5px;
                padding-left: 110px;
                margin-left: 45px;

                //h3 {
                //    font-size: 26px;
                //}
                p {
                    font-size: 16px;
                }

                // height: 560px;
                height: auto;

                .big-title {
                    margin-bottom: 20px;
                }
            }

            @media (max-width: $screen-lg-min) {
                margin-left: 0;
                padding-left: 0;
                padding-right: 0;
                padding-top: 10px;

                h3 {
                    font-size: 38px;
                }

                button {
                    font-size: 14px;
                }

                .big-title {
                    margin-bottom: 20px;
                }
            }

            @media (max-width: $screen-md-min - 1) {
                margin-top: -40px;

                h3 {
                    font-size: 28px !important;
                }

                &-sub {
                    margin-top: 5px;
                }

                .big-title {
                    margin-bottom: 20px;
                }
            }
        }

        .home-page-main-scenario {
            height: 366px;
            left: 50%;
            margin-left: 80px;
            position: absolute;
            top: 275px;
            width: 539px;

            @media (max-width: $screen-xl-min - 1) {
                top: 200px;
                margin-left: 0;
                width: 391px; // Kept ratio http://andrew.hedges.name/experiments/aspect_ratio/
                height: 266px;
            }

            @media (max-width: $screen-sm-min - 1) {
                //display:none;
                //top: 65px;
            }

            @media (max-width: $screen-md-min - 1) {
                display: none;
            }

            .video-js,
            .home-page-main-scenario-placeholder {
                display: inline-block;
            }

            .vjs-loading-spinner {
                display: none;
            }

            // &:after {
            //     background: url('../modules/home-page/img/one-screen.png');
            //     background-repeat: no-repeat;
            //     background-position: left top;
            //     background-size: cover;
            //     content: '';
            //     height: 100%;
            //     position: absolute;
            //     width: 100%;

            //     // z-index: 10;
            //     @media (max-width: $screen-xl-min - 1) {
            //         left: 80px;
            //     }

            //     @media (max-width: $screen-md-min - 1) {
            //         left: 0;
            //     }
            // }

            //         &.scenario-1 {
            //             &:after {
            //                 background: url('../modules/home-page/img/one-screen.png');
            //                 background-size: cover;
            //                 background-repeat: no-repeat;

            //                 @media (max-width: $screen-sm-min - 1) {
            //                     background: url('../modules/home-page/img/one-screen-no-cord.png');
            //                     top: 0px;
            //                     background-size: 356px 242px;
            //                     background-repeat: no-repeat;
            //                 }
            //             }

            //             video {
            //                 height: auto;
            //                 //left: 7px;
            //                 left: 30px;
            //                 position: absolute;
            //                 //top: 3px;
            //                 top: 3px;
            //                 //width: 412px;
            //                 width: 480px;

            //                 @media (max-width: $screen-xl-min - 1) {
            //                     left: 100px;
            //                     width: 350px;
            //                     height: 197px;
            //                 }

            //                 @media (max-width: $screen-sm-min - 1) {
            //                 }

            //                 @media (max-width: $screen-md-min - 1) {
            //                     left: 21px;
            //                     top: 3px;
            //                     width: 316px;
            //                     height: auto;
            //                     position: absolute;
            //                 }
            //             }
            //         }

            //         &.scenario-2 {
            //             &:after {
            //                 background: url('../modules/home-page/img/two-screens.png');
            //             }

            //             video {
            //                 height: auto;
            //                 //left: 7px;
            //                 left: 33px;
            //                 position: absolute;
            //                 //top: 3px;
            //                 top: 3px;
            //                 //width: 412px;
            //                 width: 474px;

            //                 @media (max-width: 1280px) {
            //                     left: 95px;
            //                     width: 351px;
            //                 }

            //                 //@media (max-width: $screen-md-min - 1)  {
            //                 //    left: 125px;
            //                 //    top: 62px;
            //                 //    width: 173px;
            //                 //}
            //             }
            //         }
            //     }
            // }

            &-banner-text {
                text-align: center;
                padding-top: 45px;
                padding-bottom: 36px;

                p {
                    max-width: 24em;
                    text-align: center;
                    margin-left: auto;
                    margin-right: auto;
                }

                @media (max-width: $screen-xl-min - 1) {
                    p {
                        font-size: 28px !important;
                    }
                }

                @media (max-width: $screen-lg-min) {
                    p {
                        font-size: 24px !important;
                    }
                }

                @media (max-width: $screen-md-min - 1) {
                    p {
                        font-size: 19px !important;
                    }
                }
            }

            &-section-title {
                @extend %cover-background;
                //background-size: 1268px auto!important;
                background-color: #000;

                &.home-page-pro-tools-separator {
                    background-color: #444;
                    background-size: cover !important;

                    .container-fluid {
                        max-width: none;
                    }
                }

                color: #fff;
                position: relative;
                text-shadow: 0 1px 1px rgba(#000, 0.7);

                //&:before {
                //  content: "";
                //  position: absolute;
                //  height:100%;
                //  width:100%;
                //  top:0;
                //  // background-image: radial-gradient(50% 0%, ellipse farthest-side, rgba(0, 0, 0, 0), #000000 100%),
                //  // linear-gradient(to left, rgba(#000, 1) 0, rgba(#000, 0) 50%, rgba(#000, 1) 100%);
                //  // background-image:
                //  //    linear-gradient(to bottom, rgba(#000, .2) 0, rgba(#000, .6) 100%),
                //  //    linear-gradient(to left, rgba(#000, 1) 0, rgba(#000, 0) 50%, rgba(#000, 1) 100%);
                //}
                &:before {
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    background-image: url('../modules/home-page/img/pattern2x2.png');
                    background-repeat: repeat;
                }

                .container-fluid {
                    padding: 130px 0 170px;

                    //background-image: radial-gradient(50% 0%, ellipse farthest-side, rgba(0, 0, 0, 0), #000 100%);
                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
                    //background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
                    //background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
                    //background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    //filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
                    @media (max-width: $screen-xs-min) {
                        padding: 10px 0 30px;
                    }
                }

                // background: #F2F2F2;
                h2 {
                    margin-bottom: 20px;
                    letter-spacing: 2px;
                    font-weight: 500 !important;
                    font-size: 40px !important;
                }

                p {
                    @extend .col-md-6;
                    @extend .col-md-offset-3;
                    // font-size: 20px;
                    float: none;
                    font-size: 23px;
                    margin-bottom: 40px;
                    // font-weight: 100;
                    color: #fff;
                }

                button {
                    // clear: both;
                }
            }

            &-checkers {
                .col-md-6 {
                    height: 500px;
                    // background: #F2F2F2;
                    padding: 100px;

                    @media (max-width: $screen-xs-min) {
                        padding: 20px;
                        height: auto;
                    }

                    // &:nth-child(4n-1),
                    position: relative;

                    .store-icons {
                        position: absolute;
                        height: 101px !important;

                        a {
                            width: 142px;
                            height: 48px;
                            display: block;
                            background-repeat: no-repeat;
                            margin-bottom: 5px;
                            background-color: #f2f2f2;
                            border-radius: 3px;
                            border: 1px solid #5d5d5d;

                            &.app-store {
                                background-image: url('../modules/home-page/img/apple_app_store_large.svg');
                            }

                            &.google-play {
                                background-image: url('../modules/home-page/img/google_play_large.svg');

                                &.disabled {
                                    background-image: url('../modules/home-page/img/google_play_large_dis.svg');
                                    border-color: #a8a8a8;
                                }
                            }
                        }
                    }

                    &:nth-child(2n) {
                        @media (max-width: $screen-xs-min) {
                            background: #f2f2f2;
                            margin: 0 10px 10px;
                            border-radius: 0 0 10px 10px;
                        }
                    }

                    &:nth-child(2n-1) {
                        // border:2px solid red;
                        background: #f2f2f2;
                        padding: 0;

                        @media (max-width: $screen-xs-min) {
                            margin: 10px 10px 0;
                            border-radius: 10px 10px 0 0;
                            overflow: hidden;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            width: 1000px;
                            height: 100%;
                            background: #f2f2f2;
                            top: 0;
                            left: 100%;
                        }

                        > div {
                            height: 500px;

                            @media (max-width: $screen-xs-min) {
                                height: 250px;
                            }

                            background-position: center bottom;
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }

                    &:nth-child(4n-1) {
                        &:after {
                            left: auto;
                            right: 100%;
                        }
                    }

                    // &:nth-child(4n-1){background-image: linear-gradient(to left, #F9f9f9, #fff);}
                    // &:nth-child(4n-2){background-image: linear-gradient(to right, #F9f9f9, #fff);}
                }

                .btn-hp {
                    margin-top: 20px;
                }
            }

            &-hr {
                height: 2px;
                background: #111;
                border: 0;
                margin: 0;
                display: none;
            }

            .hp-boxes {
                img {
                    width: 100%;
                    height: auto;
                }

                .separator {
                    border-top: 1px solid #979797;
                    margin-top: 50px;
                }

                .fixed-height-text-block {
                    height: 9em;
                    min-height: 9em;

                    &.high2 {
                        height: 10em;
                        min-height: 10em;
                    }

                    p {
                        text-align: center;
                        font-family: 'Open Sans', sans-serif;
                        font-size: 18px;
                        color: #000000;

                        @media (max-width: $screen-xs-min) {
                            font-size: 14px;
                        }
                    }

                    .label2 {
                        font-size: 22px !important;
                        /*line-height: 55px !important;*/
                        font-family: 'Muli', sans-serif;
                        font-weight: 600;

                        @media (max-width: $screen-xs-min) {
                            font-size: 18px !important;
                        }
                    }
                }
            }
        }
    }
}

.form-validation-link {
    color: #a94442;
    text-decoration: underline;
    cursor: pointer;
}

.recaptcha-join {
    font-size: 12px;
}

.checkbox-terms-join {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        width: 34%;
    }

    .checkbox-terms {
        label {
            font-size: 12px;
        }
    }

    @media (max-width: $screen-md) {
        flex-wrap: wrap;
    }

    @media (max-width: $screen-xs) {
        button {
            width: 100%;
        }
    }
}

.existing-account-footer {
    padding: 10px;

    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 15px;
    }

    &-text {
        color: #444444;
        font-size: 12px;
        margin-bottom: 10px;
    }

    &-button {
        border: 2px solid #444444;
        color: #444444;
        max-width: 300px;
        width: 100%;
        padding: 8px;
        font-weight: 600;
        font-size: 13px;
        cursor: pointer;
    }
}

.forgot-password-login {
    color: rgba(0, 0, 0, 0.4);
    font-size: 16px;
    display: flex;
    flex-direction: column;

    .login-btn {
        order: 1;
    }

    .forgot-password-link {
        order: 2;
        margin-bottom: 0;
    }

    .join-link {
        order: 3;
        margin-bottom: 0px;
    }

    a {
        color: black;
    }

    span {
        font-size: 12px;
        text-align: center;
        margin: 0 auto;
        margin-top: 8px;
    }

    @media (max-width: $screen-md) {
        .login-btn {
            width: 100%;
            order: 3;
        }

        .forgot-password-link {
            order: 2;
            margin-bottom: 15px;
        }

        .join-link {
            order: 2;
            margin-bottom: 20px;
        }
    }
}

.login-btn {
    display: block;
    margin-top: 0;
    margin-bottom: 15px;

    @media screen and (min-width: $screen-md) {
        width: 65%;
    }
    @media screen and (max-width: $screen-md) {
        width: 100%;
    }
}

.signin-or-separator {
    position: relative;
    margin-top: 0;
    padding-bottom: 0;

    hr {
        width: 45%;
        height: 1px;
        color: #ccc;
        background-color: #ccc;
        border: none;
    }

    .social-or {
        display: inline-block;
        width: 10%;
        text-align: center;
        font-size: 10px;
        transform: translateY(5.5px);
        margin-top: 9px;
        font-weight: bold;
    }

    .social-or-text {
        display: none;
    }

    @media (max-width: $screen-lg) and (min-width: 383px) {
        hr {
            width: 35%;
        }

        .social-or {
            width: 30%;
        }

        .social-or-text {
            display: inline-block;
        }
    }
}

.social-buttons-container {
    margin-top: 10px;

    .social-terms {
        clear: both;
        font-size: 12px !important;
        padding-top: 3px;
        padding-bottom: 15px;
        text-align: left;
    }
}

.social-buttons {
    @media screen and (min-width: 992px) {
        button {
            width: 100%;
            max-width: 100%;
        }
    }

    @media screen and (max-width: 992px) {
        button {
            width: 45%;
            max-width: 160px;
        }
    }

    clear: both;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    button {
        background-color: #fff;
        font-size: 12px;
    }

    .social-btn {
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: 40px;
        width: 100%;
        background-color: #fff;
        border-radius: 2px;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12);
        margin: 0 10px;

        &:hover {
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
        }

        &:disabled,
        [disabled] {
            opacity: 0.5;
            color: #8d8d8d;
            background-color: #ebebeb;
            border: none;

            &:hover {
                box-shadow: none;
            }

            svg {
                use,
                path {
                    fill: #8d8d8d;
                }
            }
        }
    }

    .social-btn > span:first-child {
        margin-right: auto;
    }

    .social-btn > span:last-child {
        margin-right: auto;
    }

    @media (max-width: $screen-xs) {
        .social-btn {
            margin: 5px 0;
        }
    }

    .google-button {
        color: #8d8d8d;
        border: 1px solid #8d8d8d;
    }

    .metamask-button {
        border: 2px solid #df4930 !important;
        color: #df4930;
    }

    .facebook-button {
        color: #1877f2;
        border: 1px solid #1877f2;
    }

    .apple-button {
        border: 1px solid #000;
    }

    @media (max-width: $screen-lg) {
        button {
            margin: 0 5px;
        }

        .social-text {
            display: none;
        }
    }

    @media (max-width: $screen-md) {
        justify-content: center;

        button {
            width: 100%;
            max-width: 100%;
        }
    }

    @media (max-width: $screen-xs) {
        flex-direction: column;
        margin-bottom: 0;

        button {
            margin: 5px 0;
        }
    }
}

.home-page,
#home-page {
    background: #f7f7f7;
    .wrap {
        overflow: hidden;
    }

    //  background-image: url(/modules/home-page/img/bencebakonyi_1366.jpg);
    // @extend %cover-background;
    //font-family: adobe-garamond-pro, serif;
    // font-family: MuseoSans, Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 1.4;

    * {
        // font-family: 'Montserrat', 'Helvetica Neue',Helvetica,Arial,sans-serif;
        // font-family: "proxima-nova",sans-serif;
    }

    h1,
    h2,
    h3,
    h4 {
        // font-family: MuseoSans, Helvetica, Arial, sans-serif;
        font-weight: 700;
        // font-style: italic;
    }

    h1 {
        font-size: 40px;
    }

    h2 {
        font-size: 36px;
    }

    .home-page-body h3 {
        font-size: 34px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: center;
    }

    .navbar-brand svg {
        top: -4px;
    }

    b {
        font-weight: 500;
    }

    .home-page-body p {
        white-space: normal;
        font-size: 28px;
    }

    .form-group {
        input {
            font-size: 18px;
            // font-weight: 700;
            line-height: 1.33;
        }

        .help-block {
            font-weight: normal;
            font-size: 16px;
            margin-left: 16px;
        }
    }

    .required-asterisk {
        color: #a94442;
    }

    .root-url {
        // line-height: 46px;
        // text-align:right;
        margin-bottom: 10px;

        span {
            font-weight: bold;
        }
    }

    .hp-form {
        padding-bottom: 30px;
    }

    @media (max-width: $screen-xs-max) {
        // .main-footer{
        //   .navbar-text{
        //     // color:red;
        //     font-size:11px;
        //   }
        // }
    }

    .btn.disabled,
    .btn[disabled],
    fieldset[disabled] .btn {
        opacity: 1;
    }
}

.home-page-cover {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background-size: cover;

    background-position: center center;
    background-repeat: no-repeat;
    z-index: -1;
}

.home-page-form {
    border-radius: 15px;
    border: 0 none;
    box-shadow: none;
    background: rgba(255, 255, 255, 0.7);
    // margin-left: -19px;
    // margin-right: -19px;
    margin-bottom: $line-height-computed * 3;
}

.home-page-form-field {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    @media (max-width: $screen-xs-max) {
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.home-page-text,
.home-page-logo {
    font-size: $font-size-large * 1.5;
    padding: 0 19px;
}

.home-page-text {
    @media (max-width: $screen-xs-max) {
        font-size: $font-size-large;
    }
}

.home-page-center {
    position: relative;
    text-align: center;
    color: #111;
    @include clearfix;
    // margin-bottom: 30px;
    // top:50%;
    // left:50%;
    // transform: translate(-50%, -50%);
    padding-top: 50px;
    padding-bottom: 50px;

    h1 {
        font-weight: 700 !important;
        font-size: 56px;
        margin-top: 0;
        margin-bottom: 0;
    }

    h3 {
        font-weight: 100 !important;
        font-size: 22px;
        margin-top: 10px;
        margin-bottom: 10px;
    }
}

.home-page-divider {
    // width: 540px;
    border-top: 1px solid #111;
    margin-top: 20px;
    margin-bottom: 20px;
}

.home-page-text {
    margin-bottom: $line-height-computed * 2;
}

.home-page .modal-body {
    font-size: 18px;
}

.modal-title {
    overflow-wrap: anywhere;
}

/*
.home-page-footer {
    position: absolute;
    !*right: 1em;*!
    bottom: 1em;
    width: auto;
}

.home-page-footer .container {
    padding-right: 0px;
}


.home-page-footer a,
.home-page-footer span {
    font-family: Muli-Regular , 'Muli', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff !important;
    !*text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);*!
}
.home-page-footer a {
    text-decoration: none;
}
.home-page-footer a:hover {
    color: $link-hover-color!important;
}
.home-page-footer .main-footer-text {
    float: right !important;
    margin: 0;
}
@media (max-width: 820px) {
    .home-page-footer .main-footer-text {
        float: none !important;
        padding-right: 40px !important;
    }
}

.home_text_area {
    !*position: relative;*!
    !*left:13%;*!
    !*top:30%;*!
}

.home_credit_line_wrapper {
    position:absolute;
    left:13%;
    bottom:1em;
}

@media (max-width: 820px) {
    .home_text_area {
        top:30%;
    }
    .home_credit_line_wrapper {
        bottom: 3em;
    }
}

@media (max-width: 500px) {
    .home_text_area {
        top:16%;
    }
    .home_credit_line_wrapper {
        bottom: 4em;
    }
}

.home_upper_ph {
    height: 25%;
}
@media (max-width: 820px) {
    .home_upper_ph {
        height: 20%;
    }
}
!*@media (max-width: 450px) {
    .home_upper_ph {
        height: 10%;
    }
}*!


.home_links_area.large {
    margin-top: 4em;
}
.home_links_area.small {
    margin-top: 2.5em;
}
.home_links_area.large .home_links_title,
.home_links_area.large .home_links_title > a {
    font-family: 'Muli', sans-serif;
    font-size: 15px;
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: #ffffff;
}

.home_links_area.small a,
.home_links_area.large  a {
    //font-family: 'Muli-ExtraLightItalic', 'Muli', sans-serif;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.8;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: none;
}

.home_links_area.small a:hover,
.home_links_area.large  a:hover {
    //color: #3594c5 !important;
    color: $link-hover-color!important;
}

@media (max-height: 820px) {
    .hidden-short {
        display: none !important;
    }
    .visible-long{
        display: block !important;
    }
}

@media (min-height: 821px) {
    .visible-short {
        display: block !important;
    }
    .hidden-long {
        display: none !important;
    }
}

@media (max-width: 500px) {
    .home_links_area.small {
        margin-top: 1.5em;
    }
    .home_links_area.small .row {
        margin-top: 0.5em !important;
    }
    .home_links_area.small a {
        font-size: 14px !important;
    }
}


@media (max-height: 610px) {
    .home_upper_ph {
        height: 15%;
    }
}

@media (max-height: 550px) {

    .home_title_1 {
        font-size: 14px;
    }
}
@media (max-height: 500px) {

    .home_links_area
    {
        display: none !important;
    }

}
@media (max-height: 370px) {
    .home_credit_line_wrapper
    {
        display: none !important;
    }
}

@media (max-height: 300px) {
    .home_cta_btn
    {
        margin-top: 1em !important;
    }
}

body.home-page {
    padding-top: 0px !important;
}

body.home-page .wrap {
    margin-bottom: 0px;
    height: 100% !important;
}

.home-page .view-container,
section#home-page-intro,
.home-page-main {
    height: 100% !important;
}

.home-page-main .container-fluid {
    padding: 0px !important;
}

@media (max-width: 767px) {
    .home-page-main .container-fluid {
        margin-right: auto;
        margin-left: auto;
        padding-left: 40px  !important;
        padding-right: 40px  !important;
    }
}

.home-page-main {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility:    hidden;
    -ms-backface-visibility:     hidden;
}

nav.main-nav.navbar.navbar-fixed-top.navbar-default.intro {
    background:transparent;
    color: #ffffff;
    border-color: transparent;
}

*/

.navbar-fixed-top .collapse.in {
    height: 100vh !important;
}

@media (min-width: 991px) {
    .intro .navbar-brand {
        padding: 24px 20px 24px 0px;
    }
}

@media (max-width: $screen-xs-min) {
    .home-page .navbar-brand {
        margin-left: -20px;
    }
}

/*
.intro .navbar-brand:after {
    content: '';
    font-size: 10px;
    line-height: 1;
    border: none;
    color: #666;
    position: absolute;
    padding: 1px 2px;
    top: 49px;
    right: 20px;
    width: 36px;
    text-align: center;
    letter-spacing: 1px;
}
*/

/*.animated-bg {
    !*background: url("../assets/img/home1.jpg") no-repeat transparent;*!
    background-size: cover;
    z-index: 1;
    background-repeat: repeat-x;
    -webkit-animation: animatedBackgroundLTR 30s linear infinite;
    animation: animatedBackgroundLTR 30s linear infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;

}

!*.home_step_1,*!
.home_step_2,
.home_step_3 {
    -webkit-animation: animatedFadeIn 1s, animatedBackgroundLTR 30s linear infinite;
    animation: animatedFadeIn 1s, animatedBackgroundLTR 30s linear infinite;
    -webkit-animation-direction: alternate;
    animation-direction: alternate;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards; }

.home_title_1 {
    width: auto;
    height: auto;
    font-family: 'Muli-Light', 'Muli', sans-serif;
    font-size: 22px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    !*text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);*!
    line-height: 1.54;
}

@media (max-width: 500px) {
    .home_title_1 {
        font-size: 14px;
    }
}

@media (max-width: 320px){
    .home_title_1 {
        font-size: 14px;
    }
}

.tour_title_black {
    font-family: 'Muli-Light', 'Muli', sans-serif;
    font-size: 21px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.62;
    letter-spacing: normal;
    text-align: left;
    color: #373737; }

.tour_skip_button {
    font-family: 'Muli-Regular', 'Muli', sans-serif;
    font-size: 12px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff; }

.black_credit .tour_skip_button {
    color: #000000; }

.home_credit_line {
    font-family: 'Muli-ExtraLightItalic', 'Muli', sans-serif;
    font-size: 14px;
    font-style: italic;
    font-weight: 200;
    line-height: 1.38;
    text-align: left;
    color: #ffffff;
    !*text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);*!
}

.black_credit .home_credit_line {
    color: #000000; }

.home-bar-btn {
    font-size: 12px !important;
    color: #ffffff !important;
    font-family: 'Muli-Regular', 'Muli', sans-serif !important;
    font-weight: 500 !important; }

.home_cta_btn {
    width: 240px;
    height: 42px;
    background-color: #ff526c;
    border: solid 2px #ff526c;
    margin-top: 3em;
}

.home_cta_btn a {
    width: 143px;
    height: 42px;
    font-family: 'Muli-Regular' , 'Muli', sans-serif;
    font-size: 15px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-decoration: none;
}

.home_cta_btn div {
    font-family: 'Muli-Regular', 'Muli', sans-serif;
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 40px;
    letter-spacing: normal;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    color: #ffffff; }

@media (max-width: 500px){
    .home_cta_btn {
        margin-top: 1.5em;
        width: 200px;
        height: 32px;
    }

    .home_cta_btn div {
        font-size: 13px;
        line-height: 30px;
    }
}*/

.home-page .navbar-right > li > a {
    font-family: 'Muli-Regular', 'Muli', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #000000 !important;
}

h3.big-title.title2 {
    font-size: 24px !important;
    font-weight: 600;
    margin-bottom: 22px;
    color: #666;
}

.terms-of-service-container {
    display: flex;
    justify-content: center;
    width: 100%;
}

.terms-of-service-text {
    font-size: 10px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    margin-top: 10px;
}

.terms-of-service-container .terms-of-service-text bold {
    font-weight: 600;
    color: black;
}

// Add media query for mobile
@media screen and (min-width: 992px) {
    .desktop-login-buttons-container {
        // border-left: 1px solid black;
        height: 300px;
    }

    .signin-or-separator {
        display: none;
    }

    .login-content-container {
        display: flex;
        gap: 50px;
    }

    .login-content-container > * {
        width: 100%;
    }

    .hr-container {
        border-left: 1px solid rgba(0, 0, 0, 0.3);
        height: 220px;
        width: 1px;
    }

    .terms-of-service-text {
        max-width: 360px;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 992px) {
    .terms-of-service-text {
        width: 100%;
        max-width: 100%;
    }
}

.country-suggestions-container {
    align-items: center;
    display: flex;
}

.country-suggestions-icon {
    background-color: rgba(0, 0, 0, 0.1);
    width: 54px !important;
    padding: 2px 6px 2px 6px;
    cursor: pointer;
    font-size: 22px;
}

.country-suggestions-container select {
    width: 50px;
}

.form-control {
    border: 1px solid #ccc !important;
}

.form-control:focus {
    box-shadow: unset !important;
    border: 1px solid #ccc !important;
}

.phone-suggestion-invalid {
    padding: 5px;
    color: #a94442;
}

.show-all-link {
    text-decoration: underline;
}
