@charset "UTF-8";
@import '../../niio-components/stylesheets/progress_bars.scss';
@import '../../niio-components/stylesheets/tooltips.scss';
@import '../../niio-components/stylesheets/thumbnails.scss';
@import '../../niio-components/stylesheets/plan-cards.scss';
@import '../../niio-components/stylesheets/page-cover.scss';
@import '../../niio-components/stylesheets/sidebar.scss';
@import '../../niio-components/stylesheets/footer.scss';
@import '../../niio-components/stylesheets/style-guide-helper.scss';
@import '../../niio-components/stylesheets/fonts.scss';
@import '../../niio-components/stylesheets/home-page.scss';
@import '../../niio-components/stylesheets/for-you.scss';
@import '../../niio-components/stylesheets/docs.scss';
@import '../../niio-components/stylesheets/buttons.scss';
@import '../../niio-components/stylesheets/videojs.scss';
@import '../../niio-components/stylesheets/panels.scss';
@import '../../niio-components/stylesheets/bootstrap-social.scss';
@import '../../niio-components/stylesheets/web-queue.scss';

@import 'progress_bars.scss', 'tooltips.scss', 'payment', 'thumbnails.scss', 'plan-cards', 'page-cover.scss',
    'sidebar.scss', 'footer.scss', 'style-guide-helper.scss', 'fonts.scss', 'home-page.scss', 'docs', 'buttons',
    'videojs', 'panels';

%cover-background {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: center center;
}

body {
    padding-top: $navbar-height;

    .modal-open {
        position: fixed;
    }
}

.tooltip {
    @media (max-width: $screen-md) {
        display: none;
    }
}

// Hide reCaptcha badge - Taken from: https://hometreedigital.com/insights/hide-recaptcha-badge/
.grecaptcha-badge {
    opacity: 0;
}

[pending-requests],
.spinner {
    display: none;

    &.active {
        display: block;
        //    background: rgba(#fff,0.8);
        position: fixed;

        &.spinner {
            position: absolute;
        }

        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 999999;
        pointer-events: none;

        &:before,
        &:after {
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(0deg);
        }

        &:not(.spinner):before {
            width: 50px;
            height: 50px;
            background: #fff;
            border-radius: 5px;
            border: 2px solid #333;
        }

        &:after {
            // display:none;
            width: 20px;
            height: 20px;
            border: 4px solid #333;
            border-left-color: transparent !important;
            // border-color: white white $gray-dark;
            // background: linear-gradient(0deg, transparent 80%, $gray-dark 90%), radial-gradient(center, circle,  $gray-dark 19px, white 20px);
            // background: radial-gradient(circle at top right, $gray-dark 30%, transparent 60%), radial-gradient(circle at center center, $gray-dark 16px, white 17px);
            border-radius: 50%;

            animation: spin2 1s infinite linear;
        }
    }
}

*:focus {
    outline-style: none !important;
}

/* Webkit */
::selection {
    background: $brand-primary;
    color: #fff;
}

/* Gecko/Mozilla */
::-moz-selection {
    background: $brand-primary;
    color: #fff;
}

@keyframes spin2 {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.device {
    // From device, no padding is needed
    padding-top: 0 !important;
}

.wrap {
}

// Texts
.bold {
    font-weight: 600;
}

.text-success {
    color: $brand-success;
}

.text-info {
    color: $brand-info;
}

.text-warning {
    color: $brand-warning;
}

.text-danger {
    color: $brand-danger;
}

.text-energized {
    color: $energized;
}

.text-primary {
    color: $brand-primary;
}

.text-primary-hover {
    color: $brand-primary-hover;
}

.text-brand {
    color: $brand-custom;
}

.text-brand-hover {
    color: $brand-custom-hover;
}

.text-danger {
    color: $brand-danger;
}

.text-gray-light {
    color: $gray-light;
}

.container-fluid {
    max-width: $container-large-desktop;
    border: 40px solid transparent;
    border-width: 0 40px;

    @media (max-width: $screen-xs-max) {
        border: 0;
    }

    .xs-fix-fluid-padding {
        @media (max-width: $screen-xs) {
            padding-left: 0;
        }
    }

    @media (max-width: $screen-sm-max) {
        .main-nav & {
            border: 0;
        }
    }

    // background: linear-gradient(45deg, rgba(255,0,0,0.2), rgba(0,0,255,0.2));
}

.walletIcon {
    height: 22px;
    margin-right: 6px;
    padding: 0 5px;
    cursor: pointer;
}

.glyphicon {
    top: 0;
    font-size: 0.75em;
}

.main-nav {
    font-size: 18px;
    font-weight: 700;
}

.navbar-text {
    line-height: $line-height-computed;
    white-space: nowrap;
}

.secondary-nav {
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 1px;
    padding-top: 20px;
    padding-bottom: 20px;

    // @media (max-width: $screen-xs-max){
    //   &.is-collapsed {
    //     height: 20px;
    //     overflow: hidden;
    //     background: red;
    //   }
    // }
    @media (max-width: $screen-sm-max) {
        font-size: 24px;
        padding-top: 25px;
        padding-bottom: 20px;
    }

    @media (max-width: $screen-xs-max) {
        font-size: 16px;
        font-weight: 300;
        padding-top: 15px;
        padding-bottom: 10px;
    }
}

.secondary-nav-discover {
    font-size: 18px;
    font-weight: 700;
    line-height: 37px;

    // @media (max-width: $screen-xs-max){
    //   &.is-collapsed {
    //     height: 20px;
    //     overflow: hidden;
    //     background: red;
    //   }
    // }
    @media (max-width: $screen-sm-max) {
        font-size: 24px;
        padding-top: 25px;
        padding-bottom: 20px;
    }

    @media (max-width: $screen-xs-max) {
        font-size: 14px;
        font-weight: 700;
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

//@media (max-width: $screen-iphone5-max) {
//  #discover-secondary-nav {
//    padding-top: 50px;
//  }
//}

.nav-tabs {
    font-size: 24px;
    margin: 20px 0;
}

h5,
h6 {
    font-weight: 400;
}

p,
ul,
ol {
    color: #666;
    margin: 0 0 20px;
}

.media {
    margin-bottom: 15px;
}

.margin-bottom-2em {
    margin-bottom: 2em;
}

.navbar-nav,
.navbar-form {
    @media (max-width: $screen-sm-max) {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.navbar-nav {
    @media (max-width: $screen-sm-max) {
        > li {
            float: left;

            a {
                padding-top: 18px;
                padding-bottom: 18px;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        float: right;
        margin-left: -15px;
        margin-right: 15px;

        > li {
            a {
                padding-right: 0;
                padding-left: 22px;
                font-size: 16px;
            }
        }
    }
}

.navbar-compact {
    text-transform: uppercase;

    @media (max-width: 450px) {
        text-transform: none;

        > li {
            a {
                font-size: 14px;
                padding-left: 12px;
            }
        }
    }
}

.navbar-form {
    margin-bottom: 0;
    position: relative;

    > input#navbarSearch {
        max-width: 100%;
        padding: 6px 36px 6px 12px;

        @media (max-width: 1020px) {
            //width: 191px;
        }

        @media (min-width: 990px) {
            width: 191px;
        }

        @media (min-width: 990px) and (max-width: 1040px) {
            width: 128px;
        }

        @media (min-width: 1040px) and (max-width: 1070px) {
            width: 170px;
        }
    }

    > button {
        position: absolute;
        right: 20px;
        top: 0;
        color: #999 !important;

        @media (max-width: $screen-sm-max) {
            // background:red;
            top: 10px;
        }
    }

    .form-control {
        @media (max-width: $screen-sm-max) {
            width: 100%;
        }
    }
}

@media (min-width: $screen-md-min) {
    .row.equal-height {
        display: flex;
        flex-wrap: wrap;
    }
}

.search-spinner {
    @media (max-width: $screen-sm-max) {
        top: 10px;
    }

    // background: rgba(red,.5);
    right: 20px;

    &:before,
    &:after {
        position: absolute;
        content: '';
    }

    &:before {
        background: #fff;
        top: 4px;
        left: 4px;
        right: 4px;
        bottom: 4px;
    }

    &:after {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(0deg);
        // display:none;
        width: 15px;
        height: 15px;
        border: 2px solid #333;
        border-color: #333 #333 #333 transparent;
        // border-color: white white $gray-dark;
        // background: linear-gradient(0deg, transparent 80%, $gray-dark 90%), radial-gradient(center, circle,  $gray-dark 19px, white 20px);
        // background: radial-gradient(circle at top right, $gray-dark 30%, transparent 60%), radial-gradient(circle at center center, $gray-dark 16px, white 17px);
        border-radius: 50%;

        animation: spin2 1s infinite linear;
    }

    /*   position: relative; */
    /*   right: -33px; */
}

.navbar-left > li.active > a span {
    &:after {
        content: '';

        border-bottom: 2px solid #333;
        // display: block;
        // width: 50%;
        position: absolute;
        bottom: -2px;
        left: 0;
        width: 100%;
        display: block !important;
        // left:15px;
        // right:15px;
    }
}

.navbar-left > li > a {
    span {
        position: relative;
        display: inline-block;
        font-weight: 100;
        // float:left;

        // clear:both;
    }

    position: relative;

    &:hover {
        span {
            &:after {
                content: '';

                border-bottom: 2px solid #333;
                position: absolute;
                bottom: -2px;
                left: 0;
                width: 100%;

                @media (max-width: $screen-sm-max) {
                    display: none;
                }
            }
        }
    }
}

.navbar-right > li > a {
    font-size: 14px;
    // font-weight: 400;
    color: $link-color !important;

    &:hover {
        color: $link-hover-color !important;
        /*     text-decoration: $link-hover-decoration; */
    }

    &.dropdown-toggle {
        padding-left: 0;
    }

    &:hover {
        &:after {
            content: none;
        }
    }
}

.navbar-toggle {
    border: 0;
    height: 39px;
    width: 39px;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background: transparent;
}

.navbar-toggle {
    padding: 0px 10px;
    margin-top: 10px;
    margin-bottom: 0;
}

.navbar-default .navbar-toggle .icon-bar {
    background: #333;
    height: 3px;
}

.no-collapse {
    margin: 0;

    li {
        float: left;

        a {
            padding-bottom: 18px;
            padding-top: 18px;
        }
    }

    &.navbar-right {
        float: right !important;

        &:last-child {
            margin-right: -10px;
        }
    }

    @media (max-width: $screen-sm-max) {
        // font-size: $font-size-h4;
        font-size: 18px;

        li {
            // float:left;
            a {
                padding-left: 5px !important;
                padding-right: 5px !important;
            }
        }

        &.navbar-right {
            li {
                a {
                    &:last-child {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
}

.mobile-menu {
    position: static;
    padding: 0;
    margin: 10px -20px;
    border: 0;
    box-shadow: none;
    float: none;
    display: block;
}

//////underline custom nav
.nav-underline-container {
    background: $nav-underline-bg;
    border-bottom: 1px solid #ccc;
    margin: 0 0 50px;

    .nav-underline {
        margin-bottom: 0;
        border-bottom: none;
    }

    @media (max-width: $screen-sm-max) {
        margin: 0 0 20px;
    }

    @media (max-width: $screen-xs-max) {
        margin: 0 0 20px;
    }
}

.nav-underline {
    margin-bottom: $line-height-computed;
    background: $nav-underline-bg;
    // border-bottom:1px solid $nav-underline-container-border-color;

    a {
        color: #333;
        position: relative;
        // &:hover{
        //   &:after{
        //     position: absolute;
        //     content:'';
        //     left:0;
        //     right:0;
        //     border-bottom: 2px solid #333;
        //   }
        //   // color:$nav-underline-link-hover-color;
        // }
    }

    > li {
        margin-right: 30px;
        float: left;

        &:last-child {
            margin-right: 0;
        }

        > a {
            text-align: left !important;
            padding-left: 0;
            padding-right: 0;

            &:focus {
                background-color: #ffffff;
            }

            // border-bottom: $nav-underline-active-border-height solid $nav-underline-bg;
            &:hover {
                background: none;
                @extend .text-primary;
                // border-color: $nav-underline-border-hover-color ;
            }
        }

        &.active > a {
            /*       color: $link-color; */
            &,
            &:focus {
                &:after {
                    position: absolute;
                    content: '';
                    left: 0;
                    right: 0;
                    bottom: 15px;
                    border-bottom: 2px solid #333;

                    @media (max-width: $screen-sm-max) {
                        bottom: 10px;
                    }
                }

                // border-color: $nav-underline-active-border-color;
            }
        }

        @media (min-width: $screen-sm-max) {
            a:hover {
                /*       color: $link-color; */
                &,
                &:hover,
                &:focus {
                    &:after {
                        position: absolute;
                        content: '';
                        left: 0;
                        right: 0;
                        bottom: 15px;
                        border-bottom: 2px solid #333;
                    }

                    // border-color: $nav-underline-active-border-color;
                }
            }
        }
    }

    &.nav-justified {
        @extend .nav-justified;

        .nav-icon-item {
            width: 0.0001%;
        }
    }

    @media (max-width: $screen-xs-max) {
        > li {
            margin-right: 25px;

            > a {
                border: 0 !important;

                &:hover {
                    //background: #ddd;
                }
            }

            &.active > a {
                /*           background: #ddd!important; */
            }
        }
    }
}

//////underline custom nav end

//////disable nav bar background-colour bootstrap defaults in focus
.nav.nav-underline,
.nav.navbar-nav {
    > li {
        &.active > a {
            &:focus {
                background-color: transparent;
            }
        }
    }
}

//////disable nav bar defaults in focus end

.main-sidebar {
    margin-bottom: $line-height-computed;

    // background: rgba(255,0,0,0.2);
    .list-group {
        a {
            /*       color: #999; */
        }
    }
}

.main-content {
    margin-bottom: $line-height-computed;
    // background: rgba(255,255,0,0.2);
    position: initial;

    .filter-tags {
        display: flex;
        margin-bottom: 20px;
        gap: 10px;
        flex-wrap: wrap;
        align-items: center;

        .mobile-artworks-filter {
            color: black;
            background-color: white;
            border-radius: 5px;
            text-align: left;
            align-items: center;
            display: flex;
            padding: 10px 20px;
            width: 100%;
            border: 1px solid #d8d8d8;

            &.active {
                color: white;
                background-color: #231f20;
                border: none;
            }

            &-label {
                font-weight: 500;
            }
            &-icon {
                margin-left: auto;
                display: flex;
            }
            &-num {
                margin-left: 5px;
                height: 17px;
                width: 17px;
                background-color: white;
                color: black;
                font-weight: 300;
                border-radius: 50%;
                text-align: center;
                font-size: 12px;
            }
        }

        .filter-active-tag {
            user-select: none;

            padding: 7px 14px;
            height: 40px;

            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 30px;

            font-size: 14px;
            letter-spacing: 0;
            line-height: 16px;
            text-transform: capitalize;
            color: #39393a;

            display: flex;
            flex-grow: 0;
            align-items: center;

            &.clear-tags-btn {
                margin-left: auto;
                padding: 0;
                height: min-content;
                .mobile-artworks-filter-clean-icon {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    svg {
                        margin: 9px;
                        color: black;
                        g {
                            fill: currentColor;
                        }
                    }
                }
            }
        }

        .filter-genre-tag {
            user-select: none;
            padding: 7px 14px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 30px;
            font-size: 14px;
            color: black;
            letter-spacing: 0;
            line-height: 16px;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            height: 40px;
            position: relative;

            .genre-favorite-tooltip {
                position: absolute;
                top: 8px;
                left: 107%;
                z-index: 101;
                background-color: black;
                color: white;
                width: max-content;
                padding: 4px 5px;
                text-align: center;
                display: none;
                text-transform: none;
                font-size: 12px;

                &:after {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    margin-left: -1em;
                    margin-top: -1em;
                    right: 95%;
                    bottom: 30%;
                    z-index: -1;
                    background-color: black;
                    transform-origin: 50% 50%;
                    transform: rotate(-135deg); /* change to 45 deg for left arrow */
                }
            }

            &.favorite-genre {
                svg path {
                    fill: black;
                    color: white !important;
                    stroke: black;
                }
            }

            &:active,
            &:hover {
                color: white;
                background: black;

                .genre-favorite-tooltip {
                    display: block;
                }

                &.favorite-genre {
                    svg path {
                        fill: white;
                        color: white !important;
                        stroke: white;
                    }
                }

                svg path {
                    fill: none;
                    color: white !important;
                    stroke: white;
                }
            }

            &-heart {
                margin-left: 5px;
                cursor: pointer;

                svg path {
                    fill: none;
                    stroke: black;
                }
            }
        }
    }

    .title {
        font-size: 45px;
        margin-top: -15px;
        margin-bottom: 70px;
        letter-spacing: 1px;
        color: #333;

        &.discover-title {
            font-size: 45px;
            font-weight: 100;
            margin-bottom: 40px;
            font-family: 'Adobe Garamond Pro', sans-serif;
        }

        @media (max-width: $screen-sm-min) {
            font-size: 45px !important;
            margin-bottom: 15px;
        }
    }
}

.action-bar {
    margin-bottom: $line-height-computed;
    clear: both;
}

.action-bar-right {
    text-align: right;
}

.action-bar-left {
    > h3 {
        margin-top: 0px;
    }

    &.my-area-action-bar-left {
        > h3 {
            font-family: 'Adobe Garamond Pro', sans-serif;
            font-size: 40px;
            font-weight: 100;
        }
    }
}

.navbar-brand {
    position: relative;
    z-index: 999;
}

.no-user .navbar {
    margin-bottom: 0;
}

.btn {
    // @extend .btn-default;
    border-radius: 4px;
}

// NEEDED FOR THE TAGS 3RD PARTY TOOL
.tag-item {
    background: #f0f0f0 !important;
}

// NEEDED FOR THE VIDEOJS 3RD PARTY TOOL
// .video-js {padding-top: 56.25%}
// .vjs-fullscreen {padding-top: 0px}

p {
    /*   white-space: pre-line; */
}

.overflowHidden {
    overflow: hidden;
}

.positionRelative {
    position: relative;
}

.overflowXHidden {
    overflow-x: hidden;
}

.overflowYHidden {
    overflow-y: hidden;
}

.error-messages.ng-active {
    background: $brand-danger;
    color: white;
    padding: 5px;
    border-radius: 5px;
    margin-top: 15px;
    position: relative;
}

.error-messages.ng-active:after {
    position: absolute;
    top: -10px;
    content: '';
    left: 20px;
    border-style: solid;
    border-color: transparent transparent $brand-danger;
    border-width: 5px;
}

//display-array-box-location
//display-array-box-id
//display-array-box-name

.display-array-box {
    border: 2px black solid;
    width: 80px;
    height: 90px;
    color: black;
    //  display: inline-block;
    display: inline-table;
    cursor: pointer;
    //  text-align: center;
    line-height: 1;

    &.active {
        //    border: 3px black solid;
        background-color: #ffe488;
        font-weight: 400;
    }

    &.associated {
        background-color: $brand-success;
    }

    &.associated.active {
        background-color: #66cb66;
    }

    &[hover] {
        text-decoration: none;
        cursor: pointer;
    }

    .display-array-box-location {
        font-size: 10px;
        //    vertical-align: top;
        padding-left: 2px;
        padding-top: 2px;
        padding-bottom: 4px;
        margin-bottom: 0px;
    }

    .display-array-box-id {
        text-align: center;
        margin-bottom: 0px;
        padding-right: 5px;
        padding-bottom: 2px;
        font-size: 16px;
    }

    .display-array-box-name {
        text-align: center;
        margin-bottom: 0px;
        font-size: 12px;
    }

    .display-array-box-icon {
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

.modal-body p,
.alert {
    word-wrap: break-word;
}

.fa {
    line-height: inherit;
}

.artworks-list-table {
    th {
        white-space: nowrap;

        label {
            margin-bottom: 0;
        }
    }
}

.label-default {
    background-color: #fff;
    color: $brand-primary;
    border: 1px solid $brand-primary;
}

label {
    font-weight: 600;
}

.upload-options {
    a {
        font-size: 18px;
        font-weight: 600;
        display: block;

        &:after {
            content: ' »';
        }
    }

    .glyphicon {
        margin-right: 0.5em;
    }
}

.custom-popover {
    z-index: 10003 !important;
}

.remote-body {
    remote-artwork-thumbnail {
        display: block;
        height: 198px;
    }

    .modal-header {
        position: fixed;
        top: 0;
        left: 1px;
        right: 1px;
        z-index: 1;
        background-color: #333;
        border-bottom: 0;

        .btn-primary {
            background-color: $link-color;
            border: 5px solid #333;
        }

        h4 {
            color: #fff;
            text-align: center;
            margin-top: 0;
            font-weight: 400;
        }

        .btn-group {
            margin-bottom: 5px;

            &:first-child {
                margin-bottom: 10px;
            }
        }
    }

    .modal-body {
        margin-top: 210px;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        /*
    max-height: 350px;
    min-height: 350px;
    */

        overflow-y: hidden;
        .row {
            width: 100%;
            padding: 0;
        }
    }

    .modal-body .glyphicon.unchecked {
        opacity: 0;
    }

    .modal-body .btn-group.btn-group-sm.pull-right a {
        margin-right: 5px;
    }

    .modal-body .btn-group.btn-group-sm.pull-right a:last-child {
        margin-right: 0;
    }

    .modal-body .btn-group.btn-group-xs.pull-right a {
        margin-right: 5px;
    }

    .modal-body .btn-group.btn-group-xs.pull-right a:last-child {
        margin-right: 0;
    }

    .modal-body .liveStatus {
        text-align: center;

        &.notice {
            background: yellow;
        }

        .tooltip {
            z-index: 10002;
        }

        &.refreshing {
            .hide-on-refresh {
                display: none;
            }
        }

        .error-line {
            padding: 5px;
            color: darken($energized, 40%);
            text-align: center;

            &.error-line-subtitle {
                color: gray;
                font-size: 12px;
            }
        }

        .error-line-color {
            background: lighten($energized, 20%);
        }
    }

    @media (max-width: 425px) {
        .modal-body .liveStatus {
            margin-top: 20px;
        }
    }

    .modal-body .livePlaylist {
        padding: 15px;
        position: relative;

        /*
    max-height: 350px;
    min-height: 350px;
    */
        height: 740px;
        max-height: 740px;
        width: 90%;

        @media (max-height: 815px) {
            height: 430px;
            max-height: 430px;
        }

        @media (max-height: 1080px) {
            height: 595px;
            max-height: 595px;
        }

        @media (max-height: 768px) {
            height: 365px;
            max-height: 365px;
        }

        @media (max-width: $screen-xs-max) {
            height: 350px;
            max-height: 350px;
        }

        overflow-y: auto;

        .overlay {
            display: none;
        }

        &.refreshing {
            overflow-y: hidden;

            .overlay {
                display: block;
                background: rgba(230, 230, 230, 0.75);
                width: 100%;

                height: 740px;

                @media (max-height: 815px) {
                    height: 430px;
                }

                @media (max-height: 1080px) {
                    height: 595px;
                }

                @media (max-height: 768px) {
                    height: 365px;
                }

                @media (max-width: $screen-xs-max) {
                    height: 350px;
                }

                overflow-y: auto;
                left: 0px;
                position: fixed;
                margin-top: -15px;
                z-index: 10001;
            }
        }

        &.old-data {
            .livePlaylist-row {
                pointer-events: none;
                opacity: 0.3;
            }
        }

        hr:last-child {
            display: none;
        }

        /*@media (max-height: 768px) {
      .modal-fixed-height .modal-lg {
        height: 365px;
      }
    }
    .livePlaylist  {
      height: 885px;
      max-height: 885px;
    }


    @media (max-height: 1080px) {
      .livePlaylist  {
        height: 595px;
        max-height: 595px;
      }
    }

    @media (max-height: 768px) {
      .livePlaylist  {
        height: 365px;
        max-height: 365px;
      }
    }*/
    }
}

// .form-control[type$="file"][disabled]{
.form-control[disabled] {
    opacity: 0.5;
}

.has-error .form-control {
    border-color: #a94442 !important;
    border-width: 2px !important;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea,
select,
tags-input .tags {
    border: 1px solid #ccc !important;
    box-shadow: none !important;

    &:focus {
        border-color: #999 !important;
    }
}

tags-input:not(.no-hash) .tags .tag-item::before {
    content: '#';
}

input[type='text'],
input[type='email'],
input[type='password'],
select,
tags-input .tags {
    border-radius: 4px !important;
}

tags-input .tags {
    -moz-appearance: none;
    -webkit-appearance: none;

    input[type='text'],
    input[type='email'] {
        border: 0 !important;
    }
}

qr canvas {
    max-width: 100%;
    height: auto;
}

.list-group-item {
    &.virtual-collection:last-child {
        &::after {
            border-top: 1px solid #ccc;
            width: 30%;
            content: '';
            display: block;
            margin: 10px 0 10px 5px;
        }
    }
}

.list-group-item-child {
    font-size: 0.9em;
}

// Cookies Consent

#cookie_consent_placeholder {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #4a4a4a;
    z-index: 99999;
}

.cc-revoke,
.cc-window {
    position: unset;
}

.cc-window {
    &.cc-bottom {
        background-color: #4a4a4a !important;
        padding: 10px;
        font-size: 17px;

        .cc-message {
            color: white !important;
            font-family: Musao Sans, sans-serif;
            font-size: 16px;
            font-weight: 300;

            @media (max-width: 768px) {
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
                letter-spacing: 0.2px;
            }

            @media (max-width: 414px) {
                font-size: 11px;
            }
        }

        .cc-link {
            text-decoration: none;
            color: white !important;
            font-family: Musao Sans, sans-serif;
            font-weight: 500;
            font-size: 16px;
            opacity: 1;

            @media (max-width: 768px) {
                font-size: 12px;
                font-weight: 700;
            }

            @media (max-width: 414px) {
                font-size: 11px;
                font-weight: 700;
            }
        }

        .cc-compliance {
            .cc-btn {
                background-color: transparent;
                color: white !important;
                min-width: 90px;
                border: 2px solid white;
                border-radius: 7px;
                margin-left: 10px;
                // padding: 8px 15px !important;
            }
        }

        @media screen and (min-width: 768px) {
            padding: 15px 30px 15px;
        }

        @media screen and (max-width: 768px) {
            height: 80px;
            padding: 0 20px;
        }

        @media screen and (max-width: 500px) {
            font-size: 10px;

            .cc-compliance {
                .cc-btn {
                    min-width: 64px !important;
                    height: 32px !important;
                    font-size: 14px;
                    padding: 0;
                    line-height: 32px;
                    box-sizing: border-box;
                }
            }
        }

        @media screen and (max-width: 900px) {
            .cc-btn {
                white-space: nowrap;
            }
        }

        @media screen and (max-width: 414px) {
            padding: 20px !important;
            flex-direction: row !important;
            align-items: center !important;

            .cc-message {
                margin-bottom: 0;
            }
        }
    }
}

// Table
table.middle-align tr td {
    vertical-align: middle;
}

// Responsive Font Size
@mixin responsive-font($minFontSize: 16, $maxFontSize: 24, $minScreenSize: 400, $maxScreenSize: 800) {
    font-size: calc(
        #{$minFontSize}px + (#{$maxFontSize} - #{$minFontSize}) * (100vw - #{$minScreenSize}px) /
            (#{$maxScreenSize} - #{$minScreenSize})
    );
}

.responsive-font-large {
    @include responsive-font();
}

@media (min-width: 992px) {
    .modal-lg {
        width: 1000px;
    }

    .modal-medium-width .modal-lg {
        width: 790px;
    }
}

@media (min-width: 450px) and (max-width: $screen-xs-max) {
    .device-login-fields {
        width: 75%;
    }

    .device-login-qr {
        width: 33.33333%;
    }

    .device-login-metadata {
        width: 66.6666%;
    }

    // *{
    //   background:red;
    // }
}

.hide-if-small {
    @media (max-width: 376px) {
        display: none;
    }
}

.uploads-button {
    position: fixed;
    height: 48px;
    width: 48px;
    z-index: 2147483000;
    border-radius: 40px;
    bottom: 80px;
    right: 20px;
    background-color: #333333;
    color: white;
    border-color: #333 !important;
    transition: transform 100ms linear, opacity 80ms linear !important;
    transform-origin: center center;

    &:hover,
    &:active,
    &:focus {
        background-color: #333333 !important;
        color: white !important;
        border-color: #333 !important;
        transform: rotate(0deg) scale(1);
    }

    &:hover {
        transition: transform 250ms cubic-bezier(0.33, 0, 0, 1) 0s !important;
        transform: scale(1.1);
    }

    .glyphicon {
        font-size: 1.25em;
        top: 2px;
    }

    .uploads-badge {
        padding-right: 5px;
        padding-left: 5px;
        -webkit-border-radius: 30px;
        border-radius: 30px;
        position: absolute;
        top: -6px;
        right: 0;
        font-size: 12px;
        color: white;
        background-color: $brand-danger;
        border-color: white;
    }

    @media (max-width: 700px) {
        margin-right: unset;
    }
}

.uploads-progress {
    position: relative;
    height: 22px;
}

.well-gray-background {
    background-color: #f9f9f9;
}

@include bg-variant('.bg-warning2-light', lighten($state-warning2-bg, 33%));
@include bg-variant('.bg-warning2', lighten($state-warning2-bg, 20%));
@include bg-variant('.bg-gray-lighter', lighten($gray-lighter, 2%));
@include text-emphasis-variant('.text-gray', $gray-light);

.alert-warning3 {
    @include alert-variant($alert-warning3-bg, $alert-warning3-border, $alert-warning3-text);
}

//disable Play button in iOS
.IIV::-webkit-media-controls,
.IIV::-webkit-media-controls-play-button,
.IIV::-webkit-media-controls-start-playback-button {
    opacity: 0;
    pointer-events: none;
    width: 5px;
}

.set-lower-z-index {
    z-index: 901 !important;
}

.set-lower-z-index-backdrop {
    z-index: 900 !important;
}

.set-even-lower-z-index {
    z-index: 801 !important;
}

.set-even-lower-z-index-backdrop {
    z-index: 800 !important;
}

.larger-icon {
    font-size: 1em;
    //vertical-align: middle;
    vertical-align: -15%;
}

// tags-input bug in safari
.transfer-tags {
    span.input {
        visibility: visible !important;
        padding-left: 30px;
    }
}

// Table Borderless - Taken from http://stackoverflow.com/questions/18075794/bootstrap-table-without-stripe-borders
.table-borderless tbody tr td,
.table-borderless tbody tr th,
.table-borderless thead tr th,
.table-borderless thead tr td,
.table-borderless tfoot tr th,
.table-borderless tfoot tr td {
    border: none;
}

.table-no-margin-bottom {
    margin-bottom: 0;
}

.table-form-groups-no-margin-bottom {
    .form-group {
        margin-bottom: 0;
    }
}

.disabled-anchor {
    cursor: default;
    text-decoration: none !important;
    color: $gray-dark !important;

    :hover {
        color: $gray-dark !important;
    }
}

.anchor-tag {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}

/* ************************************** */
/* Mandatory CSS required for ng-sortable */
/* ************************************** */

// Source: https://gist.github.com/SebLours/224266714a2f52bfa253f03c6b518a45
.as-sortable-item,
.as-sortable-placeholder {
    display: block;
}

table .as-sortable-item,
table .as-sortable-placeholder {
    display: table-row;
}

.as-sortable-item {
    -ms-touch-action: none;
    touch-action: none;
    /* to disable context menu on iOS devices */
    -webkit-touch-callout: none;
}

.as-sortable-item-handle {
    cursor: grab;
}

.as-sortable-placeholder {
    //background: #eee;
}

.as-sortable-drag {
    position: absolute;
    pointer-events: none;
    z-index: 9999;
    opacity: 0.95;
}

.as-sortable-hidden {
    display: none !important;
}

.as-sortable-dragging {
    display: block; // Ensure it's not set to display: none;
    position: absolute;
    pointer-events: none;
    z-index: 9999;
    background: #fff; // Optional: makes it easier to see
    opacity: 0.9; // Optional: makes it slightly transparent to indicate it's being moved
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); // Optional: adds shadow for better visibility
}

.as-sortable-un-selectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

tr.listview-row.modified,
tr.listview-row.as-sortable-item.modified {
    background: lighten($brand-warning, 33%);
}

// Event submission colors
tr.listview-row.shortlisted {
    td.submission-status {
        background-color: rgb(255, 239, 212);
        text-align: center;
    }
}

tr.listview-row.finalist,
tr.listview-row.shortlisted.finalist {
    td.submission-status {
        background-color: rgb(211, 255, 211);
        text-align: center;
    }
}

tr.listview-row > td {
    vertical-align: middle !important;
}

.search-active {
    background-color: rgb(253, 245, 233);
}

span.required:after,
label.required:after {
    color: red;
    content: '*';
}

.signup-or-separator {
    position: relative;
    text-align: center;
    background: none;

    .social-or-text {
        display: none;
    }

    @media (max-width: $screen-lg) and (min-width: 383px) {
        .social-or-text {
            display: inline-block;
        }
    }
}

.signup-or-separator hr {
    /*width: 90%;*/
    margin: -16px auto 10px auto;
    border-top: 1px solid #dce0e0;
}

.signup-or-separator .text {
    display: inline-block;
    padding: 8px;
    margin: 0;
    background-color: #fff;
}

.social-profile-info-line > td,
.social-profile-info-line > td > span,
.social-profile-info-line {
    line-height: 40px !important;
}

.niio-stars {
    margin-left: -2px;

    li {
        font-size: $font-size-base;
    }
}

//Patch for dialog with scroller and overlay and position='fixed'
.footer-set-higher-z-index .modal-footer {
    z-index: 999999;
    position: inherit;
}

/* Patch for join dialog in mobile (smaller screens only).*/
$screen-dialogfix-max: 568px;

@media (max-width: $screen-dialogfix-max) {
    body.positionRelative.modal-open {
        position: fixed !important;
    }
}

@-webkit-keyframes animatedBackground {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 100% 0;
    }
}

@keyframes animatedBackground {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 100% 0;
    }
}

@-webkit-keyframes animatedBackgroundLTR {
    from {
        background-position: 100% 0;
    }

    to {
        background-position: 0 0;
    }
}

@keyframes animatedBackgroundLTR {
    from {
        background-position: 100% 0;
    }

    to {
        background-position: 0 0;
    }
}

@-webkit-keyframes animatedFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes animatedFadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes animatedFadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes animatedFadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
.admin-panel-item-data {
    margin-top: 20px;
}
.admin-panel-container {
    padding: 12px;
    display: flex;
    flex-direction: column;
    select {
        width: 65px;
        height: 34px;
        padding: 0 5px;
    }
    img {
        cursor: pointer;
    }
    & > div {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 2px;
        &:first-child {
            margin-bottom: 15px;
        }
        .admin-panel-item {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 70px;
            &-splitter {
                background-color: #979797;
                height: 56px;
                width: 2px !important;
            }
        }
    }
    &.float-panel {
        background-color: #fffde5;
        box-shadow: 0px 7px 7px 0px rgba(50, 50, 50, 0.62);
        width: 425px;
        position: absolute;
        top: 67%;
        z-index: 999;
        &.user {
            top: 82%;
        }
        &.artwork {
            top: 75%;
        }
        &.channel {
            top: 60%;
        }
    }

    @media (max-width: 1620px) {
        &.user {
            &.float-panel {
                width: 100%;
                left: 0px;
                & > div {
                    .admin-panel-item {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    @media (max-width: 1100px) {
        &.artwork,
        &.channel {
            &.float-panel {
                width: 100%;
            }
        }
        &.channel {
            &.float-panel {
                top: 55%;
            }
        }
    }
    @media (max-width: 480px) {
        &.artwork,
        &.channel {
            &.float-panel {
                & > div {
                    .admin-panel-item {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }
    @media (max-width: 823px) {
        &.user {
            &.float-panel {
                & > div {
                    justify-content: center;
                }
            }
        }
    }
    @media (max-width: $screen-xs-max) {
        &.user {
            &.float-panel {
                left: -5px;
            }
        }
        &.channel {
            &.float-panel {
                width: 95%;
            }
        }
    }
}

@mixin navbar-top-panel($color: black, $animation: false) {
    $main-color: darken($color, 30%);
    text-align: center;
    font-size: 16px;
    height: 50px;
    background: lighten($color, 30%);
    border: 1px solid lighten($color, 20%);
    line-height: 3.2;
    color: $main-color;
    font-weight: normal;

    a {
        color: $main-color;
    }

    @if $animation {
        opacity: 0;
        animation: fadeIn 1s ease-in-out both;
    }

    @media (max-width: $screen-sm-max) {
        line-height: 1.5;
        font-size: 12px;
        padding: 6px;
    }
}

.navbar-unverified {
    @include navbar-top-panel($brand-warning, true);
}

.navbar-pending-payment {
    @include navbar-top-panel($brand-success, true);
}

.navbar-expired-plan {
    @include navbar-top-panel($brand-warning, true);
}

.view-container-add-padding {
    padding-top: 50px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        display: none;
        transform: translate3d(0, -30%, 0);
    }

    to {
        opacity: 1;
        display: initial;
        transform: translate3d(0, 0, 0);
    }
}

.edit-unverified {
    height: fit-content;
    background-color: #ec4949;
    margin: 20px;
    margin-top: 0;
    padding: 10px;
    color: #fff;
    line-height: 1.7;

    a {
        color: #fff;
    }
}

.from-event .edit-unverified {
    height: 130px;
}

.preserve-newlines-and-spaces {
    white-space: pre-wrap;
}

.discover-view-all-link {
    font-size: 14px;
    margin-top: 15px;
    font-weight: normal;
}

.discover-about {
    hr {
        margin-top: 30px;
        margin-bottom: 40px;
    }

    h3 {
        margin-top: 20px;
        margin-bottom: 50px;
    }

    .discover-bio {
        p {
            width: 50%;

            @media (max-width: $screen-xs-max) {
                width: 100%;
            }
        }
    }
}

/* patch to fix page jumps in chrome 77 */
@media only screen and (min-width: 768px) {
    html {
        overflow-y: scroll;
    }
}

.page-menu-admin > a {
    &:before {
        content: '*';
    }
}

body.modal-open {
    overflow: visible;
}

.fa-spin {
    //transform-origin: 38% 50%;
    transform-origin: 50% 50%;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    /* IE 9 */
}

.sticky-slot {
    position: sticky;
    bottom: 0;
    z-index: 100003;
}

.cc-wrapper {
    background-color: #4a4a4a;
}

.mobile-banner {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: #fff;
    height: 70px;
    font-family: 'MuseoSansFixed', Helvetica, Arial, sans-serif;
    color: #000;
    padding: 0 50px;
    z-index: 10;
    flex-wrap: wrap;
    animation: animatedFadeIn 0.3s;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.16);

    &-left,
    &-right {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    &-content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &-logo {
        width: 50px;
        background-repeat: no-repeat;
        margin: 0 12px;
    }

    &-title {
        font-size: 16px;
        letter-spacing: 0.3px;
        line-height: 41px;
        font-weight: 500;
    }

    &-close {
        width: 10px;
        cursor: pointer;
    }

    &-subtitle {
        font-size: 19px;
        letter-spacing: 0.69px;
        line-height: 27px;
        font-weight: 500;
    }

    &-apps {
        & > a {
            display: inline-block;
        }

        .app-store {
            width: 125px;
            height: 48px;
        }

        .google-play {
            width: 160px;
            height: 48px;
        }
    }

    &-responsive {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #fff;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        height: fit-content;
        font-family: MuseoSansFixed, sans-serif;
        color: #000;
        padding: 20px 10px;
        z-index: 1001;
        flex-wrap: wrap;
        animation: animatedFadeIn 0.3s;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

        &.x-padding {
            padding: 20px 20px;
        }

        &-header {
            align-items: center;
        }

        &-close {
            position: absolute;
            top: 20px;
            right: 20px;
        }

        &-filter-group {
            width: 100%;
            margin-bottom: 5%;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-title {
            position: relative;
            font-size: 16px;
            font-weight: 500;

            &-hr {
                position: absolute;
                top: 60px;
                left: 0;
                right: 0;
                height: 1px;
                background-color: #231f20;
                opacity: 0.2;
            }
        }

        &-content,
        &-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
        }

        &-content-title {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        &-btn {
            border: 1px solid #d8d8d8;
            border-radius: 5px;
            margin-bottom: 0;
            flex-basis: 48%;

            &-checkbox {
                display: none;
            }

            &-label {
                padding: 10px 15px;
                font-size: 14px;
                width: 100%;
                min-height: 40px;
                color: #4a4a4a;
                display: inline-block;
                border-radius: 5px;
                text-align: center;
                font-weight: 500;
            }
        }

        &-content {
            padding-top: 15px;
            width: 100%;

            &-categories {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;
            }

            &-title {
                font-size: 13px;
                color: #6f6f6f;
                font-weight: 500;
                margin-top: 10px;
                margin-bottom: 10px;

                .mobile-banner-responsive-content-categories + & {
                    margin-top: 40px;
                }

                p {
                    margin: 0;
                    padding: 0;
                }
            }

            label {
                input[type='radio'] {
                    &:checked + span {
                        background-color: #231f20;
                        color: white;
                    }
                }
            }
        }

        &-logo {
            transform: scale(1.3);
            padding-top: 30px;
            margin-bottom: 15px;
        }

        &-icon {
            width: 52px;
            height: 52px;
            background-color: $brand-custom;
            border-radius: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 13px;

            span {
                height: 24px;
            }

            path {
                fill: #fff;
            }
        }

        &-content &-title {
            font-size: 22px;
            font-weight: 700;
            font-family: MuseoSansFixed, sans-serif;
            color: #39393a;
            text-align: center;
            margin: 0;
            line-height: 30px;
        }

        &-download {
            border: unset;
            border-radius: 10px;
            background: black;
            font-size: 18px;
            font-weight: 700;
            color: white;
            width: 340px;
            height: 55px;
            box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
            margin-bottom: 10px;
            cursor: pointer;

            &.full-w {
                background-color: #231f20;
                height: unset;
                padding: 12px;
            }
        }

        &-actions {
            gap: 5px;
            padding-bottom: 30px;
            &.no-padding {
                padding-bottom: 0;
            }
        }

        &-continue {
            border: none;
            background-color: inherit;
            padding: 14px 28px;
            font-size: 16px;
            font-weight: 300;
            cursor: pointer;
        }

        &-overlay {
            position: fixed;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
            animation: animatedFadeIn 0.3s;
            z-index: 1001;
        }

        &-strip {
            height: 70px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-around;
            box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.16);

            &-content {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 9px;
            }

            &-title {
                margin: 0;
                font-size: 16px;
                font-weight: 700;
            }

            &-download {
                border: unset;
                border-radius: 7px;
                background: black;
                font-size: 14px;
                font-weight: 700;
                color: white;
                width: 70px;
                height: 32px;
                box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
                cursor: pointer;
            }
        }
    }
}
.full-w {
    width: 100%;
    display: block;
}

show-more,
show-more-mobile {
    display: block;

    .read-more-container {
        position: relative;

        &-desktop {
            word-break: break-word;
        }

        .read-more {
            //  color: #1C658D;
            font-family: 'Adobe Garamond Pro', sans-serif;
            font-size: 16px;
            font-style: italic;
            letter-spacing: 0.14px;
            line-height: 28px;
        }

        .read-more-mobile {
            text-align: center;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 60px;
            background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3) -18%, rgb(255, 255, 255) 100%);
            display: flex;
            align-items: center;

            &.read-more-mobile-open {
                bottom: -15px;
                height: 0;
            }

            &-arrow {
                position: absolute;
                bottom: -10px;
                left: 0;
                right: 0;

                &-top {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

a {
    @extend .text-primary;

    &:hover,
    &:focus {
        @include transition(all 0.2s ease-in-out);
        text-decoration: none;
        @extend .text-primary-hover;
        //font-weight: bold;
    }
}

.btn-custom {
    @include button-variant($btn-custom-color, $btn-custom-bg, $btn-custom-border);
}

.btn {
    @include transition(all 0.1s ease-in-out);
}

.heart-icon {
    margin-right: 4px;
}

.prime-icon-sm {
    svg {
        width: 18px;
        height: 20px;
        vertical-align: text-top;
        margin-right: 4px;
    }
}

@mixin color-svg($color: $gray-dark) {
    svg > g > g {
        stroke: $color;
    }

    svg > g {
        stroke: $color;
    }
}

.black-color-svg {
    @include color-svg($gray-dark);
}

.white-color-svg {
    svg > g path {
        fill: #fff;
    }
    @include color-svg($white);

    svg {
        .fill-trash {
            fill: $white !important;
        }

        .stroke-trash {
            stroke: $white !important;
        }
    }
}

.danger-color-svg {
    @include color-svg($brand-danger);
}

.cut-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

@media (max-width: $screen-xs-min) {
    // For the header items when on small screens - to not break the line
    .navbar-nav > li > a {
        font-size: 14px;
    }
}

.secondary-nav-discover {
    @media (max-width: 640px) {
        .nav-underline > li > a {
            padding: 0;
        }

        // margin: 0 0 15px;
    }
}

#home-secondary-nav {
    @media (max-width: $screen-xs-min) {
        .nav-underline > li > a {
            padding: 5px 0 10px 0;
            font-size: 14px;
        }

        //  margin: 0 0 15px;
    }
}

.modal-body {
    position: relative;
    padding: 15px;

    .modal-carousel-content {
        width: 70%;
    }

    @media (max-width: $screen-xs-min) {
        .modal-carousel-content {
            width: 80%;
        }

        .carousel-control {
            width: 10%;
        }
    }
}

.server-changed {
    border-top: 2px solid $brand-info;
}

.table-selection-image {
    display: flex;
    align-items: center;

    .table-image {
        vertical-align: middle;
        width: 70px;
        margin-left: 10px;
        height: 70px;
        object-fit: cover;
        font-size: 70px;
        color: $gray-lighter;
    }
}

.table-middle > tbody > tr > td {
    vertical-align: middle;
}

.plan-actions {
    margin-bottom: 1em;

    .plan-action {
        margin-right: 2em;
    }
}

.modal-wallet {
    .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    iframe {
        border: none;
    }

    .modal-content {
        max-width: 414px;
        margin: 0 auto;
        border-radius: 20px;
        overflow: hidden;
    }

    @media (max-width: $screen-xs-min) {
        &.modal-dialog {
            position: absolute;
            width: 100%;
            margin: 0;
            bottom: 0;

            .modal-content {
                border-radius: 20px 20px 0 0;
            }
        }
    }
}

@media (max-width: $screen-xs-min) {
    .modal.fade .modal-dialog {
        transform: translate(0, 100%);
        transition: transform 0.3s ease-out;
    }

    .modal.in .modal-dialog {
        transform: translate(0, 0);
    }
}

.notifications-strip {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 250px;
    height: 60px;
    background-color: #fffbe0;
    border-bottom: 1px solid #d8d8d8;
    padding: 10px 20px;

    &-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 1060px;
    }

    &-text {
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding: 0;
        font-family: MuseoSans, sans-serif;
        font-size: 16px;
        font-weight: 300;
        color: #4a4a4a;

        &-address {
            font-family: MuseoSans, sans-serif;
            font-size: 16px;
            font-weight: 500;
        }

        @media (max-width: 700px) {
            display: unset;
            font-size: 12px;
            line-height: 20px;
            word-break: break-word;
        }

        @media (max-width: 910px) {
            font-size: 13px;
            display: unset;
            word-break: break-word;
        }
    }

    &-icon {
        margin-right: 20px;

        @media (max-width: 700px) {
            margin-right: 18px;
        }
    }

    &-cta {
        margin-left: auto;
    }

    &-btn {
        background-color: transparent;
        border: 2px solid #000;
        border-radius: 8px;
        width: 70px;
        height: 32px;
        font-family: MuseoSans, sans-serif;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        line-height: 32px;
        padding: 0;

        &-email {
            text-decoration: unset;
            font-family: MuseoSans, sans-serif;
            font-size: 14px;
            font-weight: 500;
            color: #000;
            cursor: pointer;
            margin-left: auto;
            display: flex;
            white-space: nowrap;
            gap: 5px;

            @media (max-width: 700px) {
                display: unset;
            }
        }

        @media (max-width: 700px) {
            width: 60px;
        }
    }

    @media (max-width: 700px) {
        height: 80px;
    }
}

.new-branding-input {
    background: white !important;
    border-radius: 4px;
    border: none;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 50px;
    font-size: 14px !important;
    padding: 20px;

    &.has-error {
        border: 1px solid red !important;
    }
}

.new-branding-input:focus,
.new-branding-input:active,
input[type='text']:focus,
input[type='email']:focus,
input[type='password']:focus,
tags-input .tags:focus {
    outline: none !important;
    border: 1px solid rgba(0, 0, 0, 1) !important;
    background: white !important;
}

.social-share-box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    .social-share-container {
        display: flex;
        align-items: center;
        width: 33%;
        font-size: 24px;
        justify-content: flex-start;

        @media (max-width: 700px) {
            width: 16%;
            justify-content: center;
        }

        .social-share-icon {
            margin-right: 10px;
        }

        .social-share-text {
            @media (max-width: 700px) {
                display: none;
            }
        }
    }
}

.cast-action-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'MuseoSansFixed', sans-serif;
    width: 250px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 7px;
    font-size: 16px;
    font-weight: 700;
    background-color: #fff;
    margin-bottom: 18px;

    @media (max-width: $screen-sm-max) {
        margin: 0 auto 18px auto;
    }

    .discover_channels_channel &,
    .discover_catalogs_channel & {
        margin: 0 auto 18px auto;
    }
}

.additional-actions {
    &-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        margin-top: 25px;
        padding: 0 36px;
    }

    &-button {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &-danger {
            color: #e02020;
        }

        &:not(:last-child) {
            margin-bottom: 30px;
        }

        &-icon {
            .artcast-info .channel-info-action-buttons &,
            .catalog-info .channel-info-action-buttons &,
            & {
                font-size: 26px !important;
            }

            svg {
                display: inline;
                vertical-align: middle;
                height: auto;
                width: 1em;
                aspect-ratio: 1/1;
            }
        }

        &-text {
            .artcast-info .channel-info-action-buttons &,
            .catalog-info .channel-info-action-buttons &,
            & {
                font-size: 14px !important;
            }

            font-weight: 700;
        }
    }
}

.modal-open mobile-banner .mobile-banner,
.modal-open mobile-banner .mobile-banner-responsive-strip,
.modal-open .cc-wrapper {
    display: none;
}
