/*
  Allow angular.js to be loaded in body, hiding cloaked elements until
  templates compile.  The !important is important given that there may be
  other selectors that are more specific or come later and might alter display.
 */
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
    display: none !important;
}

body {
    padding-right: 0 !important;
    padding-left: 0 !important;
}

@font-face {
    font-family: 'VideoJS';
    src: url('video.js/dist/font/VideoJS.svg') format('svg');
    src: url('video.js/dist/font/VideoJS.svg') format('embedded-opentype'),
        url('video.js/dist/font/VideoJS.woff') format('woff'), url('video.js/dist/font/VideoJS.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.color-preview {
    display: inline-block;
    width: 25px;
    height: 25px;
    vertical-align: -8px;
    border: black solid 1px;
}

.pull-right {
    float: right !important;
}
.pull-left {
    float: left !important;
}

// To enable search results to appear before header
.dropdown-menu {
    z-index: 10002 !important;
}
.dropdown-menu {
    &.header-dropdown {
        min-width: 260px;
        overflow: auto;
        max-height: calc(100vh - 60px);

        @media (max-width: 992px) {
            max-height: calc(100vh - 150px);
        }

        .is-admin & {
            @media (max-width: 768px) {
                max-height: calc(100vh - 230px);
            }
        }

        li {
            > a:hover {
                background: unset;
            }
        }

        .dropdown-link {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: 'MuseoSansFixed', sans-serif;
            font-weight: 700;
            font-size: 16px;
            line-height: 17px;
            color: #231f20;
            cursor: pointer;

            padding: 10px 20px;

            @media (max-width: 600px) {
                padding: 12px 20px;
            }

            &:hover {
                color: #4a4a4a;
            }

            span svg {
                display: inline;
                width: 1em;
                height: auto;
                font-size: 24px;
                vertical-align: middle;
                margin-right: 7px;
            }

            &-grey {
                color: #6f6f6f;
            }
        }
    }
}

// ng-tags hide input when max tags reached
// https://github.com/mbenford/ngTagsInput/issues/88
tags-input.has-tags input {
    display: none;
}

// http://stackoverflow.com/questions/30249095/ngtagsinput-issue-with-ng-invalid-style
.custom-tags.ng-pristine .tags {
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    border: 1px solid #ccc;
}

.blur {
    //filter: blur(5px) !important;
    -webkit-filter: blur(3px) grayscale(25%);
    //opacity: 0.9 !important;
}

$fa-font-path: 'font-awesome/fonts';

@import 'font-awesome/scss/font-awesome.scss';
@import '../niio-components/stylesheets/custom-variables.scss';
@import 'bootstrap-sass/assets/stylesheets/_bootstrap.scss';

@import '../modules/core/css/core.scss';
@import '../modules/core/css/notifications.scss';
@import '../modules/devices/css/devices.scss';
@import '../modules/artwork-info/css/artwork-info.scss';
@import '../modules/channel-info/css/catalog-info.scss';
@import '../modules/channel-info/css/artcast-info.scss';
@import '../modules/artwork-thumbnail/css/artwork-thumbnail.scss';
@import '../modules/catalog-thumbnail/css/catalog-thumbnail.scss';
@import '../modules/artcast-thumbnail/css/artcast-thumbnail.scss';
@import '../modules/form/css/form.radio.client.style.scss';
@import '../modules/admin-area/css/admin.scss';
@import '../modules/search/css/search.scss';

@import '../niio-components/stylesheets/styles.scss';

#niioSdkPortal {
    transition: height 0.5s ease-in-out;
    border: 0;
    height: 76vh;
    width: 100%;

    @media (max-width: $screen-xs-max) {
        max-height: calc(100vh - env(safe-area-inset-bottom));
    }
}

#innerModal {
    #loading {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: 42px;
        transform: translate(-50%, -50%);
        z-index: 0;
    }

    iframe {
        position: relative;
        z-index: 1;

        &[data-loaded] {
            + #modalLoader {
                display: none;
                + #loading {
                    display: none;
                    color: transparent;
                }
            }
        }
    }

    #modalLoader {
        svg {
            z-index: 0;
            position: absolute;
            inset: 0;
            margin: auto;
            animation: spin 2s linear infinite;
        }

        @keyframes spin {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }
}

.upload-original-file-name {
    word-break: break-all;
}

video:not([controls]) {
    &::-webkit-media-controls-panel,
    &::-webkit-media-controls-panel-container,
    &::-webkit-media-controls-start-playback-button {
        display: none !important;
        -webkit-appearance: none;
    }
}

[data-tooltip] {
    position: absolute;
    cursor: pointer;
    z-index: 1000;
    &:hover {
        &:after {
            position: absolute;
            content: attr(data-tooltip);
            bottom: 10px;
            left: 100%;
            margin-left: 10px;
            background-color: black;
            color: whitesmoke;
            padding: 5px 10px;
            border-radius: 5px;
            font-size: 12px;
            font-weight: bold;
            white-space: nowrap;
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
            z-index: 1000;
        }
    }
}

.favorites-anchor {
    color: inherit;
    display: inline-block;
    vertical-align: middle;

    .favorite-wrapper.rounded {
        background: none !important;
        border: none !important;
        padding: 0;
        font-size: 20px;
        vertical-align: middle;
    }

    .favorite-wrapper {
        .favorite-like-button {
            span.icon-wrapper {
                @media (max-width: 768px) {
                    --border-color: black;
                }
            }
        }
    }
}

.favorite-wrapper {
    --bgColor: rgba(0, 0, 0, 0.3);
    --bgColorHover: rgba(0, 0, 0, 0.4);

    @media (max-width: 768px) {
        --bgColor: transparent;
        --bgColorHover: transparent;

        border: 1px solid #d8d8d8;
        display: inline-block;
        width: fit-content;
        padding: 3px 15px;
    }

    background-color: var(--bgColor);
    border-radius: 7px;
    cursor: pointer;
    font-size: 14px;
    padding: 9px;

    &.rounded {
        border-radius: 50%;
        padding: 9px;
        width: 38px;
        height: 38px;
        border: unset;
    }

    &:hover {
        background-color: var(--bgColorHover);
    }

    .child-text-wrapper:empty {
        display: none;
    }

    .favorite-like-button {
        --border-color: white;
        align-items: center;
        justify-content: center;
        display: flex;
        gap: 5px;
        height: 100%;

        &.reverse {
            flex-direction: row-reverse;
        }

        span.icon-wrapper {
            line-height: 0;
            color: transparent;
            font-size: 20px;
        }

        &.active-favorite {
            span.icon-wrapper {
                color: red;
                --border-color: red;
            }
        }

        svg {
            height: auto;
            width: 1em;
            aspect-ratio: 1/1;

            g {
                fill: currentColor;
                stroke: var(--border-color);
            }
        }
    }
}

@media screen and (max-width: $screen-xs) {
    .thumbnail-content {
        .thumbnail-actions,
        .page-cover-actions {
            transition: none !important;
            opacity: 1;
        }
    }
}

.dropdown-menu > li > a {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 550px;
}

body > .modal {
    z-index: 10001 !important;

    &:first-child {
        z-index: 10002 !important;
    }
}
