.devices-add-device {
    .strip {
        background: #f5f5f2;
        margin: 20px 0 20px 0;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
            width: 200px;
        }
    }
    ol {
        counter-reset: custom-counter;
        list-style: none;
        padding-left: 40px;
    }
    ol li {
        margin: 3rem 0 0 0;
        counter-increment: custom-counter;
        position: relative;
        padding-left: 20px;
    }

    ol li::before {
        content: counter(custom-counter);
        color: #191919;
        font-size: 1.5rem;
        font-weight: bold;
        position: absolute;
        --size: 30px;
        left: calc(-1 * var(--size) - 10px);
        line-height: var(--size);
        width: var(--size);
        height: var(--size);
        top: 0;
        background: #f0f0ed;
        border-radius: 50%;
        text-align: center;
    }

    p {
        font-size: 1.8rem;
    }

    hr {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    .row > div:first-child {
        padding-right: 10px;
    }
    .row > div:last-child:not(:first-child) {
        padding-left: 10px;
    }

    @media (max-width: $screen-xs-min) {
        .strip {
            margin: 10px 0 10px 0;
            padding: 10px;
        }
        ol li {
            padding-left: 0;
        }
        hr {
            margin-top: 22px;
            margin-bottom: 22px;
        }
        .row > div:first-child {
            padding-right: 20px;
        }
        .row > div:last-child:not(:first-child) {
            padding-left: 20px;
        }
    }
}

.devices-add-device-main-title {
    @extend .gray-title-lg;
    font-size: 24px;
}

.devices-add-device-main-body p {
    font-size: 18px;
}

.devices-add-device-input-title {
    @extend .gray-text-md;
    font-size: 22px;
}

.devices-add-device-input-subtitle {
    @extend .gray-text-sm;
    font-size: 14px;
}
