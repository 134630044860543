.discovery-artwork-info {
    font-family: 'MuseoSans', sans-serif;

    .artwork-info-alert {
        .thumbnail-title-text {
            @media (max-width: $screen-xs-max) {
                font-size: 8px;
            }
        }
    }

    a {
        color: #333333;

        &:hover {
            color: #f85775 !important;
        }
    }

    ng-container {
        //display: flex;
        //flex-direction: column;
        //@media (max-width: $screen-sm) {
        //  display: inline-flex;
        //}
    }

    .table {
        min-width: 278px;

        & > tbody > tr > td {
            vertical-align: inherit;
            // padding: 2px;
        }
    }

    .artwork-info {
        &-title-price {
            display: flex;
            justify-content: space-between;
        }

        &-content {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-top: 40px;

            &-left {
                display: flex;
                flex-direction: column;
                margin-right: 25px;
                margin-top: -5px;
                max-width: 560px;
                width: 100%;
            }

            &-right {
                display: flex;
                flex-direction: column;
            }

            @media (max-width: $screen-md) {
                width: 100%;
                &-left {
                    margin-right: 0;
                    max-width: 100%;
                }
            }
        }

        &-alert {
            @media (max-width: $screen-sm-max) {
                margin-bottom: 10px;
            }
            margin: -1px 0 25px 0;
        }

        &-title {
            font-size: 24px;
            letter-spacing: 0.54px;
            line-height: 28px;
            margin-top: 5px;
            color: #4a4a4a;
            font-style: italic;
            font-weight: 300;
            font-family: 'Adobe Garamond Pro', sans-serif;
            word-break: break-all;
            @media (max-width: $screen-md) {
                font-size: 22px;
                margin-top: 0;
            }
            @media (max-width: $screen-sm) {
                font-size: 20px;
            }
        }

        &-titles {
            margin-bottom: 10px;
            padding: 0;
            max-width: 560px;
            width: 100%;

            &-artist-pre-actions {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
            }

            @media (max-width: $screen-md-max) {
                //   margin-bottom: 20px;
                max-width: 100%;
            }
            // @media (max-width: $screen-sm-max) {
            ////   margin-bottom: 20px;
            //   width: 100%;
            // }
        }

        &-period {
            @media (max-width: $screen-sm-max) {
                padding: 0;
                text-align: right;
            }
            text-align: left;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            display: block;
            color: #898989;
            padding: 0 0 5px 0;

            &-loan {
                color: #d9534f;
                font-weight: 500;
            }

            &-borrow {
                color: #d9534f;
                font-weight: 500;
            }
        }

        &-labels {
            display: none;

            &.labels-side {
                display: block;
                transition: opacity 0.5s;
                position: absolute;
                left: 0;
                text-align: left;
                top: 0;
                min-width: 110px;
                margin-left: 15px;
                z-index: 1;
            }

            &-item {
                color: #ffffff;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                text-transform: uppercase;
                margin-bottom: 5px;
                padding: 0 5px;
                display: inline-block;

                &-display {
                    background-color: #fc3d03;
                }

                &-rated {
                    background-color: #000000;
                }

                &-view {
                    background-color: #188d42;
                }
            }
        }

        &-meta-discovery {
            font-size: 14px;
            padding: 0;
            margin-right: 0;

            &-desktop {
                max-width: 250px;

                .artwork-info-meta-discovery-value {
                    width: auto;
                    min-width: auto;
                }

                .artwork-info-meta-discovery-item-hash {
                    flex-direction: column;

                    .artwork-info-meta-discovery-value {
                        max-width: 250px;
                    }
                }

                .artwork-info-meta-discovery-item-tags {
                    flex-direction: column;
                }
            }

            &-mobile {
                margin-top: 20px;

                .artwork-info-meta-discovery-item-tags {
                }
            }

            &-item {
                display: flex;
                align-items: baseline;
                justify-content: space-between;
                margin-bottom: 10px;
                line-height: 17px;

                &-tags {
                    //border-top: 1px solid #898989;
                    //padding: 30px 0;
                    margin: 0;
                    padding: 0;

                    .artwork-info-meta-discovery-value {
                        max-width: 500px;
                    }

                    .label {
                        border: 0.5px solid #b9b9b9;
                        border-radius: 11px;
                        color: #231f20;
                        font-size: 12px;
                        letter-spacing: 0;
                        display: inline-block;
                        margin: 3px;
                        padding: 6px 8px;
                        line-height: 6px;
                        cursor: pointer;

                        &:hover {
                            background-color: #231f20;
                            color: #ffffff;
                        }
                    }
                }

                &-hash {
                    span {
                        &:nth-child(2) {
                            overflow-wrap: break-word;
                            max-width: 300px;
                            display: inline-block;
                        }
                    }
                }
            }

            &-title {
                font-size: 12px;
                letter-spacing: 0;
                line-height: 17px;
                color: #898989;
                text-align: left;
                margin-bottom: 2px;
                flex: 1;
                font-weight: 500;
            }

            &-value {
                color: #231f20;
                min-width: 120px;
                max-width: 300px;
                width: 100%;
                flex: 2;
            }

            @media (max-width: $screen-md) {
                &-item {
                    &-tags {
                        flex-direction: column;
                        //border-top:1px solid #898989;
                        //border-bottom:1px solid #898989;
                        //padding: 40px 0;
                    }
                }
                &-value {
                    max-width: 100% !important;
                    //min-width: 100% !important;
                }
            }
            //@media (max-width: 400px) {
            //  &-value{
            //    max-width: 140px !important;
            //  }
            //}
        }

        &-meta-description {
            font-size: 16px;
            letter-spacing: 0.4px;
            margin-top: 10px;
            color: #898989;
            line-height: 24px;
            font-weight: 200;
            @media (max-width: $screen-md) {
                margin-top: 2px;
            }

            &-mobile {
                @media (max-width: $screen-md) {
                    display: flex;
                }
            }

            &-editions {
                font-size: 16px;
                letter-spacing: 0.4px;
                margin-top: 5px;
                color: #898989;
                line-height: 24px;
                font-weight: 200;
                @media (max-width: $screen-md) {
                    margin-top: 2px;
                    font-weight: 200;

                    &::before {
                        content: ',';
                    }
                }
            }
        }

        &-additional-files {
            //  padding-left: 0;
            padding: 0;
            margin-top: 20px;

            &-title {
                font-size: 14px;
                display: inline-flex;
                padding: 0 0 10px 0;

                span {
                    &:first-child {
                        width: 15px;
                        height: 20px;
                        display: inline-block;
                        margin-right: 5px;
                    }

                    color: #898989;
                }
            }

            @media (max-width: $screen-sm) {
                .table-responsive {
                    border: none;
                }
            }
        }

        &-additional-file {
            font-size: 12px;
            color: #898989;
        }

        &-additional-file-size {
            //@media (max-width: $screen-sm-max) {
            // display: none;
            //}
            small {
                font-size: 11px;
            }
        }

        &-additional-file-actions {
            text-align: right;
            width: 180px;

            button {
                border: none;
                color: #9b9b9b;
                padding: 6px 0;

                &:first-child {
                    margin-right: 5px;
                }

                &:hover {
                    background-color: white;
                }

                &:focus {
                    background-color: white;
                }
            }

            &-icons {
                color: #898989;
                font-size: 14px;
                display: flex;
                align-items: center;
                @include svg-color(#898989);

                &:hover {
                    color: #231f20;
                    @include svg-color(#231f20);
                }

                span {
                    &:nth-child(2) {
                        padding-left: 5px;
                        @media (max-width: $screen-xs) {
                            display: none;
                        }
                    }
                }
            }
        }

        &-additional-file-icon {
            i {
                font-size: 18px;
            }
        }

        &-artist {
            //@media (max-width: $screen-sm-max) {
            //  margin-top: 10px;
            //}
            font-family: 'MuseoSans', sans-serif;
            font-size: 22px;
            color: #4a4a4a;
            letter-spacing: 0.3px;
            line-height: 30px;
            font-weight: 500;
            padding-right: 10px;
            display: inline-block;

            .artwork-info-uploader {
                font-weight: 600;
            }

            @media (max-width: $screen-md) {
                font-size: 20px;
            }
            @media (max-width: $screen-sm) {
                font-size: 18px;
            }
        }

        &-action-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: 'MuseoSansFixed', sans-serif;
            width: 250px;
            height: 40px;
            border-radius: 7px;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            background-color: #fff;

            &.btn-default,
            &.btn-warning {
                border: 1px solid #d8d8d8 !important;
                font-size: 16px !important;
            }

            @media (max-width: 992px) {
                margin-bottom: 18px;
            }
        }

        &-actions {
            max-width: 250px;
            @media (max-width: $screen-md-max) {
                width: 100%;
                max-width: 100%;
            }
            @media (max-width: $screen-sm-max) {
                padding-right: 20px;
                padding-left: 20px;
            }

            &.actions-mobile {
                display: none;
                margin-right: 0;
                margin-top: 20px;
                padding-left: 0;
                padding-right: 0;
                @media (max-width: $screen-sm-max) {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }
            }

            padding-right: 0;
            padding-left: 0;
            z-index: 1;
            margin-right: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            @extend .discovery-button;

            .btn {
                width: 250px !important;
            }
        }

        &-link {
            font-size: 14px;
            font-family: 'MuseoSans', sans-serif;
            color: #4a4a4a;
            font-weight: 800;

            &-icon {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            a {
                word-break: break-word;
                font-family: 'MuseoSans', sans-serif;
            }
        }

        &-price {
            padding: 0 0 5px 0;
        }

        &-description {
            // padding-left: 0;
            padding: 0;

            &-text {
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 10px;
                color: #4a4a4a;
                font-family: 'MuseoSans', sans-serif;
                font-weight: 600;

                &-link {
                    border-bottom: 1px solid #4a4a4a;
                    cursor: pointer;
                }
            }
        }

        &-pre-actions {
            padding: 0;
            margin-right: 0;
            text-align: right;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            //width: 250px;
            @media (max-width: $screen-sm-max) {
                text-align: right;
                margin-left: 10px;
                // width: auto;
            }
            //
            //&.pre-actions-mobile {
            //  display: none;
            //  padding-right: 0;
            //  margin-right: 0;
            //  @media (max-width: $screen-sm-max) {
            //    text-align: right;
            //    display: inline-block;
            //  }
            //}
        }

        &-pre-title {
            font-family: 'MuseoSans', sans-serif;
            font-size: 22px;
            color: #6d6c6c;
            letter-spacing: 0.3px;
            line-height: 30px;
            font-weight: 700;
            display: inline-block;
            @media (max-width: $screen-md) {
                font-size: 20px;
            }
            @media (max-width: $screen-sm) {
                font-size: 18px;
            }
        }
    }
}

.artwork-info {
    &-box {
        display: flex;
    }

    &-alert {
        background: #333;
        color: #fff;
        font-size: 14px;
        font-weight: 400;
        margin: -1px 0 20px;
        padding: 3px 5px;
        text-align: center;
    }

    &-titles {
        margin-bottom: 20px;
    }

    &-title {
        font-weight: 700;
        font-size: 20px;
    }

    &-additional-files-title {
        font-size: 17px;
    }

    &-artist {
        font-size: 20px;
    }

    &-expiration-message {
        font-size: 20px;
    }

    &-meta {
        ul,
        li {
            list-style: none;
            padding: 0;
        }

        font-size: 14px;
        clear: right;

        dt {
            font-weight: 700;
            display: inline-block;
            min-width: 75px;
        }

        dd {
            display: inline;
            font-weight: 400;

            &:after {
                content: '';
                display: block;
                margin-bottom: 10px;
            }
        }
    }

    &-actions {
        @media (max-width: $screen-xs-max) {
            float: none !important;
        }
        margin-bottom: 18px;

        .btn {
            text-transform: unset !important;
        }

        @media (min-width: 425px) and (max-width: $screen-xs-max) {
            width: 50%;
        }
    }

    &-transfer {
        padding-top: 50px;
    }
}

.artworks-info-additional-actions {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 15px;

    @media (min-width: 1080px) {
        justify-content: space-between;
    }

    &-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
    }

    &-name {
        color: #818181;
        font-size: 11px;
    }
}

.video-bg {
    background: #333;
    background-clip: content-box;

    .img-responsive {
        // margin: 0 auto;
        // max-height: 400px;
    }
}

.artwork-image {
    height: 0;
    padding-top: 56.25%;
    position: relative;

    img {
        position: absolute;
        max-width: 100%;
        max-height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &-file {
        &:fullscreen {
            transform: none;
            position: static;
            object-fit: contain;
            display: block;
        }
    }
}

#uppyUploader {
    border: 0;
    height: 75vh;
    width: 100%;
}

.artwork-info-crypto-metadata {
    display: flex;
    flex-direction: column;
    margin: 25px 0;

    a[href='#'] {
        filter: contrast(5%);
        cursor: not-allowed;
        opacity: 0.4;
    }

    &-artist {
        display: flex;
        align-items: center;

        &-text {
            font-size: 14px;
            font-weight: 300;
            color: #6f6f6f;
            margin: 2px 0 0 0;
            padding: 0;
        }

        &-inner-box {
            display: flex;
            align-items: center;
            margin: 2px 0;
        }

        &-address {
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
        }

        &-address-text {
            font-size: 14px;
            margin: 0 6px 0 0;
            padding: 0;
        }
    }

    &-icon {
        width: 22px;
        height: 22px;
        margin-right: 12px;
    }

    &-title {
        font-size: 14px;
    }

    &-inner-box {
        display: flex;
        align-items: center;
        margin: 8px 0;
        position: relative;
    }

    &-text {
        font-size: 14px;
        font-weight: bold;
    }

    @media (max-width: 768px) {
        margin: 0;
    }
}

.crypto-tooltip {
    background-color: #1c1e21;
    color: #fff;
    font-size: 14px;
    width: max-content;
    font-weight: 600;
    padding: 1px 7px;
    border-radius: 5px;
    position: absolute;
    right: 0;
    top: -23px;
}

.pointer {
    cursor: pointer;
}

.disable-icon {
    pointer-events: none;
    filter: contrast(5%);
}
