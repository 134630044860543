.userPlans-container,
.event-container table {
    width: 100%;
}

.userPlans-sidebar-menu,
.events-sidebar-menu {
    //height: 48px;
    padding: 6px 10px 6px 0;
}

// Remove borders and backgrounds
.userPlans-sidebar > .panel-heading,
.events-sidebar > .panel-heading {
    background-color: transparent;
}

.userPlans-sidebar > .panel-heading:hover,
.events-sidebar > .panel-heading:hover {
    text-decoration: none;
}

.userPlans-sidebar.panel,
.events-sidebar.panel {
    border-color: transparent;
    box-shadow: none;
    border: 0;
}

.userPlans-sidebar > .panel-collapse > .panel-body,
.events-sidebar > .panel-collapse > .panel-body {
    border-top: none !important;
}

// Remove sides padding to have more space for the menu
.userPlans-sidebar > .panel-heading,
.events-sidebar > .panel-collapse > .panel-body,
.events-sidebar > .panel-heading,
.events-sidebar > .panel-collapse > .panel-body {
    padding-right: 0;
    padding-left: 0;
}

.userPlans-container,
.event-container {
    height: 45px;
    padding-top: 0;
    margin-top: 15px;
    padding-bottom: 0;
    margin-bottom: 15px;
}

.userPlans-sidebar-menu-item,
.events-sidebar-menu-item {
    top: 8px;
}

.userPlans-hl,
.events-hl {
    @media (max-width: $screen-xs-max) {
        min-height: 0 !important;
        height: auto !important;
    }
    min-height: 300px;
    color: #d3d3d3;
    // box-shadow: -1px 0 #eee;
    border-left-style: solid;
    border-left-width: 1px;
    margin: 0 0 0 -1px;
    padding: 0;
    width: 0px;
    float: left;
    position: relative;
    z-index: 100;

    transition: all 500ms ease-in-out;
}

#userPlans-sidebar,
#events-sidebar {
    // width: inherit;
    // min-width: 371px;
    // max-width: 371px;
    // float: left;
    // height:100%;
    // position:relative;
    // padding-right: 0;
}

#userPlans-main-area,
#events-main-area {
    height: 100%;
    overflow: auto;
}

.userPlans-main-area-ui-view,
.events-main-area-ui-view {
    overflow-x: hidden;
}

/*
 * off Canvas sidebar
 * --------------------------------------------------
 */
.userPlans-sidebar-row-offcanvas,
.events-sidebar-row-offcanvas {
    height: 100%;
}

.userPlans-container-separator,
.events-container-separator {
    padding-top: 12px;
}

.userPlans-sidebar-accordion,
.events-sidebar-accordion {
    .panel-group {
        margin-right: -10px;
    }
}
