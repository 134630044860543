//.devices-top-header {
//  height: 50px;
//}

.device-container table,
.group-container table {
    width: 100%;
}

.devices-table-select-button {
    width: 20px;
    padding-right: 20px;
}

.colorpicker > .dropdown-menu {
    background-color: white;
    opacity: 1;
}

.devices-table-icon {
    padding-right: 15px;
    width: 60px;
    .device-icon {
        margin-top: -4px;
    }
}

.devices-table-online {
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: -8px;
    vertical-align: middle;
    margin-left: -8px;
}

.devices-table-online.online {
    animation: pulse 1.5s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.05);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.devices-table-display {
    width: 155px;

    a:hover,
    a:focus {
        text-decoration: none;
    }

    .devices-table-display-title {
        display: block;
        font-size: 16px;
        font-weight: 700;
    }

    .devices-table-display-subtitle {
        display: block;
        padding-top: 5px;
        font-size: 14px;
    }
}

.devices-table-device-display {
    @extend .devices-table-display;
    width: 195px;
}
.panel-group {
    // margin-left: -10px;
    // margin-right: -10px;
}
.panel-group .panel + .panel {
    // margin-top: -8px;
}

.panel-body {
    // margin-top: -10px;
}

.devices-table-buttons {
    @extend .pull-right;
    width: 60px;
    height: 60px;
    padding-top: 17px;

    .devices-table-buttons-actions {
        @extend .pull-left;
        padding-top: 8px;
    }

    .devices-table-buttons-info {
        margin-left: 10px;

        img {
            margin-top: 12px;
        }
    }
}

.devices-table-arrow {
    width: 41px;

    i {
        margin-left: 16px;
        height: 73px;
        padding-top: 22px;
    }
}

// Device Sidebar
.devices-sidebar-device-in-group {
    //  .device-container {
    //    padding-left: 40px;
    //
    //    .devices-table-display {
    //      width: 140px;
    //    }
    //  }
    .devices-table-device-display {
        width: 155px;
    }

    .device-entity {
        margin-left: 40px;
        width: calc(100% - 41px);
        height: 74px;
    }

    .device-entity.selected-not-active {
        background: linear-gradient(to right, #ffffff 0%, #fffaec 16%, #fffaec 100%); /* W3C */
    }

    .device-entity.active {
        background: linear-gradient(to right, #ffffff 0%, #fffaec 16%, #fffaec 100%); /* W3C */
    }
}

.device-entity {
    height: 74px;
    width: 100%;

    border-right-color: black;
    border-right-style: solid;
    border-right-width: 0px;

    transition: all 200ms ease-in-out;
}

.device-entity.selected {
    border-right-color: black;
    border-right-style: solid;
    border-right-width: 5px;
}

.device-entity.active {
    background: linear-gradient(to right, #ffffff 0%, #fff2cc 25%, #fff2cc 100%); /* W3C */
}

.devices-sidebar-accordion {
    padding-top: 1px;
    padding-left: 0;
    padding-right: 0;
}

.btn-dropdown {
    color: #909090;
    background-color: transparent;
    border-color: darkgray;
    border-style: solid;
    border-width: 1px;
    width: 20px;
    height: 25px;
    border-radius: 7px !important;

    .caret {
        margin-left: -4px;
        margin-top: -7px;
    }
}

.devices-device-subheader {
    @extend .subheader;
    padding-top: 9px;
    padding-left: 27px;
    height: 48px;
    a {
        padding-right: 40px;
        padding-left: 40px;
        margin-right: 80px;
    }
}

.devices-device-subheader-selection {
    @extend .subheader-selection;
    padding-bottom: 6px;
    line-height: 35px;
}

// Devices Add Device  - end//

// *** Niio sidebar ***

.devices-sidebar-menu {
    height: 48px;
    padding: 6px 10px 6px 0;
}

// Remove borders and backgrounds
.devices-sidebar > .panel-heading {
    background-color: transparent;
}

.devices-sidebar > .panel-heading:hover {
    text-decoration: none;
}

.devices-sidebar.panel {
    border-color: transparent;
    box-shadow: none;
    border: 0;
}

.devices-sidebar > .panel-collapse > .panel-body {
    border-top: none !important;
}

// Remove sides padding to have more space for the menu
.devices-sidebar > .panel-heading,
.devices-sidebar > .panel-collapse > .panel-body {
    padding-right: 0;
    padding-left: 0;
}

// Chevron button of sidebar
.btn-chevron {
    background-color: transparent;
    border-color: transparent;
    color: #505050;
    transition: all 0.25s ease-out;
}

.btn-chevron:hover {
    color: #9c9c9c;
    background-color: transparent;
    border-color: transparent;
}

.btn-chevron:active,
.btn-chevron:focus,
.btn-chevron:visited {
    outline: none;
    border: none;
    color: #505050;
}

.devices-sidebar-menu .dropdown-menu,
.device-entity .dropdown-menu {
    background-color: white;
    opacity: 1;
}

.device-container,
.group-container {
    height: 45px;
    padding-top: 0;
    margin-top: 15px;
    padding-bottom: 0;
    margin-bottom: 15px;
}

.panel-heading,
.panel-body {
    // padding-top: 0;
    // padding-bottom: 0;
}

.panel-body {
    // padding-top: 10px;
}

.devices-sidebar-menu-item {
    top: 8px;
}

.devices-hl {
    @media (max-width: $screen-xs-max) {
        min-height: 0 !important;
        height: auto !important;
    }
    min-height: 300px;
    color: #d3d3d3;
    // box-shadow: -1px 0 #eee;
    border-left-style: solid;
    border-left-width: 1px;
    margin: 0 0 0 -1px;
    padding: 0;
    width: 0px;
    float: left;
    position: relative;
    z-index: 100;

    transition: all 500ms ease-in-out;
}

#devices-sidebar {
    // width: inherit;
    // min-width: 371px;
    // max-width: 371px;
    // float: left;
    // height:100%;
    // position:relative;
    // padding-right: 0;
}

#devices-main-area {
    height: 100%;
    overflow: auto;
}

.devices-main-area-ui-view {
    overflow-x: hidden;
}

/*
 * off Canvas sidebar
 * --------------------------------------------------
 */
.devices-sidebar-row-offcanvas {
    height: 100%;
}

// @media screen and (max-width: 768px) {
//   #devices-sidebar {
//     left: 0;
//   }

//   .devices-sidebar-row-offcanvas {
//     position: relative;
//     -webkit-transition: all 0.25s ease-out;
//     -moz-transition: all 0.25s ease-out;
//     transition: all 0.25s ease-out;
//     width:calc(100% + 371px);
//   }

//   .devices-sidebar-row-offcanvas-left
//   {
//     left: -388px;
//   }

//   .devices-sidebar-row-offcanvas-left.active {
//     left: 0;
//   }

//   .devices-sidebar-offcanvas {
//     top: 0;
//   }
// }

.devices-container-separator {
    padding-top: 12px;
}

.devices-sidebar-accordion {
    .panel-group {
        margin-right: -10px;
    }
}

@import './devices.add-device.scss';
@import './devices.add-group.scss';
@import './devices.device.settings.scss';
