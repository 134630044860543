.discover-channels-main-content {
    @media (max-width: $screen-sm) {
        padding-left: 0;
        padding-right: 0;
    }
}

.discovery-artwork-info-modal {
    .modal-title {
        font-size: 31px;
        font-family: 'Adobe Garamond Pro', sans-serif;
        font-weight: 300;
        @media (max-width: $screen-md) {
            font-size: 28px;
        }
        @media (max-width: $screen-sm) {
            font-size: 25px;
        }
    }

    .carousel-control {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: unset !important;
        top: 15px;

        .arrow-right {
            margin-right: -20px;
            width: 25px;
        }

        .arrow-left {
            margin-left: -20px;
            width: 25px;
            transform: rotate(180deg);
        }

        @media (max-width: $screen-md) {
            height: 30vw;
            .arrow-right {
                width: 15px;
            }

            .arrow-left {
                width: 15px;
            }
        }
        @media (max-width: $screen-xs) {
            width: 75px !important;
        }
    }
}

.artcast-info {
    font-family: 'MuseoSans', sans-serif;
    margin-top: -50px;
    margin-bottom: -50px;
    padding-bottom: 105px;

    .container-fluid {
        //padding-left: 0;
        //padding-right: 0;
        padding-left: 0;
        padding-right: 0;
        border: none;
    }

    artwork-thumbnail {
        .artwork-thumbnail {
            margin-bottom: 10px;
        }
    }

    .artwork-info-modal-container {
        width: 80%;

        .modal-title {
            font-family: 'MuseoSans', sans-serif;
            font-weight: 300;
        }

        ng-container {
            //  display: inline-block;
        }

        @media (max-width: $screen-md) {
            width: 90%;
        }
    }

    .featured-artists {
        color: #ffffff;
        font-family: 'MuseoSans', sans-serif;
        padding-left: 0;
        padding-right: 0;
        text-align: center;
        background-repeat: no-repeat;
        background-size: cover;

        //&-no-description {
        //  margin-bottom: 50px;
        //
        //  .featured-artists-title {
        //    margin: 0;
        //  }
        //
        //  .featured-artists-cover {
        //    justify-content: center;
        //    flex-direction: row;
        //    border-left: none;
        //    padding: 10px 80px;
        //    flex-wrap: wrap;
        //
        //    .featured-artists-splitter {
        //      display: none;
        //    }
        //
        //    .featured-artists-item {
        //      border-right: 1px solid #4a4a4a;
        //      padding: 0 10px;
        //      line-height: 1.1;
        //      margin-bottom: 10px;
        //
        //      &:last-child {
        //        border-right: none;
        //      }
        //    }
        //  }
        //}

        &-splitter {
            box-sizing: border-box;
            //margin: 0 0 30px 0;
            width: 64px;
            height: 2px;
            border: solid 2px #4a4a4a;
            display: inline-block;
        }

        &-cover {
            display: flex;
            align-items: center;
            flex-direction: column;
        }

        &-title {
            font-size: 25px;
            font-weight: 100;
            font-stretch: normal;
            font-style: normal;
            line-height: 40px;

            letter-spacing: 0.56px;
            color: #4a4a4a;
            font-family: 'Adobe Garamond Pro', sans-serif;
        }

        &-item {
            font-size: 17px;
            font-weight: 100;
            font-stretch: normal;
            font-style: normal;
            line-height: 2.18;
            letter-spacing: 0.38px;
            display: inline-block;
            color: #4a4a4a;
        }
    }

    .page-cover-text {
        text-align: left;
        padding-left: 0;
        margin-top: -10px;
        //padding-top: 5%;
    }

    a {
        color: #333333;

        &:hover {
            color: #f85775 !important;
        }
    }

    hr {
        width: 100%;
        margin-bottom: 58px;
    }

    h1,
    h2 {
        text-shadow: none;
    }

    .thumbnail-content {
        border: none;
    }

    .page-cover {
        border-bottom: none;
        position: initial;
    }

    .page-cover-channel {
        width: 100%;
        color: #4a4a4a;
        background-color: #ffffff;
        padding: 0;
        position: relative;

        &-label {
            position: absolute;
            top: -45px;
            display: flex;
            justify-content: center;
            left: 0;
            right: 0;

            span {
                font-family: 'Adobe Garamond Pro', sans-serif;
                font-size: 15px;
                font-weight: 500;
                letter-spacing: 2.73px;
                line-height: 28px;
                text-align: center;
                font-stretch: normal;
                font-style: normal;
                color: #000000;
                border-bottom: 1px solid #000000;
                padding: 0 25px;
                text-transform: uppercase;
            }

            //
            //&.Artcast {
            //  span {
            //    background-color: #1763bc;
            //  }
            //}
            //
            //&.Catalog {
            //  span {
            //    background-color: #000000;
            //  }
            //}
        }

        .channel-info-creator {
            color: #4a4a4a;
            font-family: 'Adobe Garamond Pro', sans-serif;
            font-size: 19px;
            letter-spacing: 0;
            line-height: 32px;
            text-align: center;
            font-weight: 300;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 10px 50px 0 50px;

            span,
            a {
                font-family: inherit;
                font-size: inherit;
                font-style: inherit;
                line-height: inherit;
                letter-spacing: inherit;
                color: inherit;
            }

            &-separator {
                height: 1px;
                width: 100px;
                background-color: #4a4a4a;
                margin-top: 10px;
                margin-bottom: 10px;
            }

            @media (max-width: $screen-xs) {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .channel-info-title {
            color: #4a4a4a;
            font-family: 'Adobe Garamond Pro', sans-serif;
            font-size: 60px;
            letter-spacing: 3.75px;
            padding: 0;
            text-transform: uppercase;
            text-align: center;
            font-weight: 300;
            line-height: 92px;
            word-break: break-word;
        }

        @media (max-width: $screen-sm) {
            .channel-info-title {
                font-size: 45px;
                line-height: 70px;
                letter-spacing: 2.7px;
            }
        }
        @media (max-width: $screen-xs) {
            padding: 20px 0 0 0;
            .channel-info-title {
                font-size: 30px;
                letter-spacing: 1px;
                line-height: 38px;
            }
        }

        .page-cover-search {
            input[type='search'] {
                &:focus {
                    box-shadow: none;
                    border: 1px solid #ccc;
                }

                &::-webkit-search-cancel-button {
                    -webkit-appearance: none;
                    height: 14px;
                    width: 14px;
                    display: block;
                    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
                    background-repeat: no-repeat;
                    background-size: 14px;
                    cursor: pointer;
                }
            }

            .page-cover-arrow {
                display: block;
                text-align: center;
                margin-bottom: 20px;
                margin-top: 30px;
            }
        }
    }

    .channel-info-search-box {
        display: flex;
        justify-content: center;
        align-items: center;

        .search-x {
            display: flex;
            margin-left: 5px;
        }
    }

    .page-cover-content {
        margin: 80px 0 0 0;

        @media (max-width: $screen-xs) {
            margin: 130px 0 0 0;
        }
    }

    .repeated-thumbnail {
        margin-bottom: 0 !important;
        &-collection-name {
            color: #000000;
            font-family: 'Adobe Garamond Pro', sans-serif;
            font-size: 35px;
            letter-spacing: 2.19px;
            float: none;
            margin-bottom: 40px;
            text-align: center;
        }
        &-order {
            color: #898989;
            font-size: 14px;
            display: block;
            letter-spacing: 0.4px;
            padding: 0;
        }
        &-center {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
        &.thumbnail-discovery-artcast {
            padding-left: 0;
            padding-right: 0;

            artwork-thumbnail {
                padding-left: 0;
                padding-right: 0;
            }

            .thumbnail-full-description {
                font-size: 16px;
                line-height: 28px;
                color: #4a4a4a;
                font-family: 'MuseoSans', sans-serif;
                font-weight: 600;
                margin-top: 40px;
                margin-bottom: 40px;
            }

            .caption {
                margin-top: 20px;
                min-height: 110px;

                &-no-image {
                    margin-top: 0;
                }

                @media (max-width: $screen-sm) {
                    .thumbnail-full-description {
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }
                }
                @media (max-width: $screen-xs-max) {
                    margin-top: 0;
                }
            }
        }

        hr {
            border-color: #d8d8d8;
            margin-top: 0;
        }

        &.double {
            padding-bottom: 8px;

            hr {
                padding-bottom: 20px;
            }

            padding-left: 30px;
            padding-right: 30px;
        }

        @media (max-width: $screen-xs-max) {
            margin-bottom: 30px;
            .thumbnail-full-description {
                margin-top: 20px !important;
            }
            .caption {
                margin-top: 20px;
            }
        }
        @media (max-width: $screen-sm-max) {
            margin-bottom: 0 !important;
        }
    }

    .repeated-thumbnail:not(:last-child) {
        padding-bottom: 40px;
    }

    @media (max-width: $screen-xs) {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }

    .channel-info-splitter {
        //padding: 0 0 70px 0;
        //@media (max-width: $screen-xs-max) {
        //  padding: 0;
        //}
    }

    .thumbnail-container {
        padding-left: 0;
        padding-right: 0;
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }

    .thumbnail-artist {
        .thumbnail-content {
            @media (max-width: $screen-xs-max) {
                height: 80px;
                width: 80px;
            }
            height: 140px;
            width: 140px;
        }

        .caption {
            .thumbnail-title {
                //   margin-top: 0;
                //   color: #4A4A4A;
                font-family: 'MuseoSans', sans-serif;
                font-size: 26px;
                font-weight: 500;
                letter-spacing: 0.02px;
                line-height: 31px;
            }

            .thumbnail-title-text {
                flex: 1;
            }

            .thumbnail-bio {
                color: #4a4a4a;
                font-family: 'MuseoSans', sans-serif;
                font-size: 16px;
                //font-style: italic;
                letter-spacing: 0.14px;
                line-height: 28px;
            }

            .thumbnail-about {
                color: #656565;
                font-family: 'MuseoSans', sans-serif;
                font-size: 14px;
                letter-spacing: 0.12px;
                line-height: 24px;
                margin-top: 20px;
            }
        }
    }

    .thumbnail-labels {
        top: 20px;

        .thumbnail-label {
            color: #ffffff;
            font-family: 'MuseoSans', sans-serif;
            font-size: 10px;
            font-weight: 500;
            padding: 0.2em 0.5em;
            line-height: normal;
            text-align: center;
            letter-spacing: 1.07px;
            margin-bottom: 8px;

            &.thumbnail-label-visible {
                background-color: #f95775;
            }
        }
    }

    .thumbnail-title,
    .thumbnail-subtitle {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .thumbnail-artwork-medium,
    .thumbnail-artwork-duration {
        font-size: 16px;
        letter-spacing: 0.4px;
        margin-top: 3px;
        color: #898989;
        line-height: 24px;
        font-weight: 600;
        font-family: 'MuseoSans', sans-serif;
        display: block;
        font-stretch: normal;
    }

    .thumbnail-artwork-name {
        color: #4a4a4a;
        font-family: 'Adobe Garamond Pro', sans-serif;
        font-size: 20px;
        font-style: italic;
        letter-spacing: 0.16px;
        line-height: 28px;
        font-weight: 100;
        font-stretch: normal;
    }

    .thumbnail-editions {
        color: #8a867a;
        font-family: 'MuseoSans', sans-serif;
        font-size: 15px;
        font-style: italic;
        letter-spacing: 0.13px;
        line-height: 28px;
    }

    .thumbnail-artist-name {
        color: #4a4a4a;
        font-family: 'MuseoSans', sans-serif;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.01px;
        line-height: normal;
        margin-bottom: 5px;

        //.thumbnail-artist-upload {
        //  font-weight: 300;
        //
        //  &-name {
        //    font-weight: 500;
        //  }
        //}
    }

    .thumbnail-title-extra {
        color: #514c3e;
        font-family: 'MuseoSans', sans-serif;
        font-size: 18px;
        letter-spacing: 0.01px;
        line-height: 22px;
        margin-left: 0;
    }

    .channel-info-action-buttons {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0;
        text-align: center;
        margin-bottom: 20px;
        margin-top: 30px;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        .btn {
            height: 55px;
            text-transform: none !important;
            border-radius: 7px;
            border: 1px solid #d8d8d8;
            font-family: 'MuseoSansFixed', sans-serif;
            font-weight: 700;

            &.btn-default,
            &.btn-warning {
                padding: 3px 22px 0 22px;
                margin-left: 10px;
                margin-right: 10px;
                font-size: 16px;
                border: 1px solid #d8d8d8;
                width: auto;
                display: inline-flex;
                align-items: center;
                line-height: normal;
                position: relative;
                margin-bottom: 30px;

                &.btn-default-active {
                    background-color: #000000;
                    color: #ffffff;

                    svg {
                        stroke: #ffffff;
                    }
                }
            }
        }

        @extend .discovery-button;

        &-block {
            &.btn-primary {
                display: flex !important;
                justify-content: center;
                align-items: center;
                width: 274px !important;
                height: 40px;
                border-radius: 7px;
                margin-left: auto;
                margin-right: auto;
                font-family: 'MuseoSansFixed', sans-serif;
                font-weight: 700 !important;
                margin-bottom: 20px !important;
                text-transform: none !important;

                svg {
                    stroke: #ffffff;
                    display: inline;
                    vertical-align: middle;
                }
            }
        }

        span {
            color: inherit !important;
            font-size: inherit !important;

            height: auto !important;
            &:nth-child(2) {
                padding-left: 8px;
                line-height: 16px;
            }
        }

        .channel-info-action-mobile-text {
            position: absolute;
            bottom: -20px;
            font-size: 12px !important;
            letter-spacing: 0;
            font-weight: 600;
            line-height: 16px;
            text-transform: capitalize;
        }

        @media (max-width: $screen-xs) {
            margin-top: 15px;
            &-block {
                &.btn-primary {
                    &.btn {
                        width: 250px !important;
                        text-transform: none !important;
                        border-radius: 7px !important;
                    }
                }
            }
        }
        @media (max-width: $screen-sm) {
            &-block {
                &.btn-primary {
                    width: 250px !important;
                    border-radius: 7px;
                }
            }
        }
        @media (max-width: $screen-xs-max) {
            .channel-info-toggle {
                width: 50px;
                bottom: -35px;
                &-active {
                    border-bottom: 1px solid #000;
                }
                & > span {
                    &:first-child {
                        width: auto;
                        height: 21px;
                        span {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                    &:last-child {
                        width: auto;
                        height: 21px;
                        span {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .channel-info-description {
        color: #4a4a4a;
        padding-left: 0;
        font-size: 16px;
        line-height: 28px;
        font-family: 'MuseoSans', sans-serif;
        letter-spacing: normal;
        font-weight: 600;
        padding-right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
        @media (max-width: $screen-sm) {
            padding-left: 20px;
            padding-right: 20px;
        }

        &-separator {
            background-color: black;
            width: 100px;
            height: 2px;
            margin: 20px auto 30px auto;
        }
    }
}
