.devices-add-group-include-devices {
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 80px;
    font-weight: bold;
    font-size: 18px;

    //  .devices-add-group-include-devices-checkbox {
    //
    //  }

    .devices-add-group-include-devices-text {
        padding-left: 25px;
    }
}

.devices-add-group-include-devices-all {
    @extend .devices-add-group-include-devices;

    margin-left: 20px;
    margin-top: 15px;
}
