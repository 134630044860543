.billing-plans section.container-fluid.payment-form {
    max-width: 669px;
}

section.container-fluid.payment-form {
    padding: 22px;
    @media (min-width: 768px) {
        padding: 22px 50px;
    }
    font-family: 'MuseoSansFixed', sans-serif;
    font-size: 18px;
    color: #4a4a4a;
    background-color: #ffffff;

    .plan-prime-icon {
        color: #c6c6c6;

        svg {
            width: auto;
            height: 32px;

            g {
                stroke: currentColor;
            }
        }
    }

    small {
        font-size: 0.7em;
    }
}

section.container-fluid.payment-form input[type='text'],
section.container-fluid.payment-form input[type='email'],
.payment-form-billing-state {
    width: 267px;
    height: 35px;
    margin-bottom: 30px;

    &.billing-email-info {
        width: 100%;
    }
}

.payment-form-input-column {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
}

.payment-form > .payment-form-header.page-header {
    //display: flex;
    //justify-content: center;
    margin-bottom: 35px;
    padding-bottom: 23px;
    border-bottom: #cbcbcb 1px dashed;
    h1 {
        font-size: 55px;
        font-weight: 100;
        font-family: 'Adobe Garamond Pro', sans-serif;
    }
}

.payment-form {
    .plan-prime-icon {
        display: flex;
        justify-content: center;
    }

    .required-asterisk {
        color: #a94442;
    }
}

.payment-form-invoice {
    margin: 0 0 5px;

    .artwork-thumbnail,
    .data-item {
        margin: 0 !important;
    }
}

.payment-coupon-form-toggle {
    color: $brand-custom;
    text-decoration: underline;
    font-weight: 100;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 25%;
}

.payment-form-coupon-form {
    display: flex;
    justify-content: flex-end;
    align-items: start;
    opacity: 0;
    transition: 0.5s opacity;
    pointer-events: none;

    #payment-coupon-form-controller:checked + & {
        opacity: 1;
        pointer-events: all;

        + label {
            display: none;
        }
    }

    input[type='text'] {
        margin: 0 !important;
    }

    .btn-link {
        color: $brand-custom;
        position: relative;
    }

    .payment-form-field {
        margin-bottom: 0;
        display: block;
        width: 100%;
    }
}

.payment-form-invoice-price-per-month {
    font-size: 14px;
    line-height: 17px;
    text-align: right;
}

.payment-form-invoice-coupon-container {
    position: relative;
    text-align: right;
    font-size: 14px;
    line-height: 17px;
    margin: 20px 0 0;
}

.payment-form-invoice-container {
    display: flex;
    justify-content: space-between;
    text-align: start;
    font-size: 32px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 30px;
    @media only screen and (max-width: 768px) {
        font-size: 20px;
        .payment-form-invoice-subscribe-to {
            font-size: 18px;
            color: #4a4a4a;
        }
    }

    @media only screen and (max-width: 420px) {
        display: initial;
    }

    .payment-form-invoice-subscribe-to {
        font-size: 18px;
        color: #4a4a4a;
    }

    .payment-form-invoice-price-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding-top: 30px;
        .form-price-container {
            text-align: right;
        }
    }
}

.payment-form-invoice-total-container {
    display: flex;
    justify-content: space-between;
    padding: 12px 0;
    border-top: #cbcbcb 1px dashed;
    border-bottom: 2px solid #cbcbcb;
    font-size: 22px;
    font-weight: 300;
    line-height: 27px;
}

.payment-form-form-inner-title {
    font-weight: 500;
    font-size: 16px;
    margin: 15px 0;
    display: block;
}

form.payment-form-form input,
form.payment-form-form select,
.payment-form-billing-state {
    box-sizing: border-box;
    padding: 7px 14px 7px 14px;
    font-size: 16px;
    font-weight: 100;
}

.payment-form-inputs-container {
    display: flex;
    justify-content: space-between;
    gap: 37px;

    > div {
        flex: 1 1 0;
    }
}

.payment-form-personal-info {
    margin-top: 35px;
}

.payment-form-personal-info-inputs {
    display: flex;
    justify-content: space-between;
    gap: 37px;
}

.payment-form-billing-country {
    width: 100%;
    height: 34px;
}

.payment-form-button-container {
    display: flex;
    justify-content: center;
    margin: 30px 0 22px 0;
}

.payment-form-billing-info-inputs-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 37px;
}

.payment-form-button-container button {
    width: 296px;
    height: 56px;
    background-color: $brand-custom;
    border-radius: 6px;
    font-size: 17px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 20px;
    color: #ffffff;
}

section.container-fluid.payment-form .payment-form-payment-info .payment-form-form-credit {
    width: 100%;
    @media only screen and (max-width: 768px) {
        background: url('../niio-components/images/credit.png') no-repeat right top;
        padding-top: 27px;
        background-size: 95px 24px;
    }

    input {
        width: 100%;
        margin: 0;

        @media only screen and (min-width: 768.1px) {
            background: url('../niio-components/images/credit.png') no-repeat right center;
            background-size: 95px 24px;
        }
    }
}

.payment-price {
    margin: auto;

    &.payment-price-line-through {
        text-decoration: line-through;
        margin-top: -25%;
        font-weight: 700;
        color: #fc3d03;

        @media only screen and (min-width: 768.1px) {
            font-size: 0.625em;
            margin-top: -10%;
        }
    }
}

.payment-form-field {
    $color-error: #b94b49;
    margin-bottom: 20px;
    position: relative;
    display: block;

    input {
        margin: 0 !important;
    }

    .payment-form-error-text {
        position: absolute;
        top: 100%;
        color: $color-error;
        font-size: 11px;

        &.show-if-invalid {
            display: none;
        }

        ~ input {
            border-color: $color-error !important;
        }
    }
}

.billing-form {
    section.container-fluid.payment-form input[type='text'],
    section.container-fluid.payment-form input[type='email'],
    .payment-form-billing-state {
        width: 100%;
        height: 35px;
        margin-bottom: 20px;
        font-weight: 100;
        font-size: 16px;
    }
}

.payment-form-form-checkbox-label {
    font-weight: 100;
    font-size: 16px;
}

:invalid + .payment-form-error-text.show-if-invalid {
    display: block;
}

.payment-form-card-info {
    margin-bottom: 60px;
}

.payment-sources {
    list-style: none;
    padding: 0;

    .form-radio-item {
        display: block;

        .circle-text {
            font-size: 16px;
            display: inline-flex;
            margin-left: 10px;
        }

        .circle,
        .circle-sm {
            width: 18px;
            height: 18px;
            padding-left: 8px !important;
            padding-right: 8px !important;
        }
    }

    .form-radio-item:last-child:is(:first-child) {
        label {
            display: none;
        }
    }
}

.cvc {
    background: url('../niio-components/images/cvc.png') no-repeat right center;
    background-size: 37px 28px;
    margin-bottom: 29px;
    height: 35px;
}

.payment-form-trial-text {
    font-size: 14px;
    color: #4a4a4a;
    font-style: italic;
    text-align: center;
    padding-bottom: 30px;
    font-weight: 300;
}

.payment-form-footer {
    display: flex;
    justify-content: end;
    height: 40px;
    border-top: #cbcbcb 1px dashed;
    align-items: center;
    padding-top: 30px;
}

.payment-form-footer > img {
    width: 127px;
    height: 23px;
}

.payment-form-error-msg {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    border: 1px solid #f7254b;
    min-height: 50px;
    align-items: center;
    border-radius: 5px;
    background-color: #ffe6e6;
    color: #f7254b;
    font-weight: bold;
}

@media only screen and (max-width: 900px) {
    .payment-form-billing-info-inputs-container,
    .payment-form-personal-info-inputs {
        flex-direction: column;
    }
    section.container-fluid.payment-form input[type='text'],
    section.container-fluid.payment-form input[type='email'],
    .payment-form-billing-state {
        width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .billing-plans section.container-fluid.payment-form {
        width: 100%;
    }
}

.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-default div {
    position: absolute;
    width: 6px;
    height: 6px;
    background: rgb(232, 4, 4);
    border-radius: 50%;
    animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
    animation-delay: 0s;
    top: 37px;
    left: 66px;
}

.lds-default div:nth-child(2) {
    animation-delay: -0.1s;
    top: 22px;
    left: 62px;
}

.lds-default div:nth-child(3) {
    animation-delay: -0.2s;
    top: 11px;
    left: 52px;
}

.lds-default div:nth-child(4) {
    animation-delay: -0.3s;
    top: 7px;
    left: 37px;
}

.lds-default div:nth-child(5) {
    animation-delay: -0.4s;
    top: 11px;
    left: 22px;
}

.lds-default div:nth-child(6) {
    animation-delay: -0.5s;
    top: 22px;
    left: 11px;
}

.lds-default div:nth-child(7) {
    animation-delay: -0.6s;
    top: 37px;
    left: 7px;
}

.lds-default div:nth-child(8) {
    animation-delay: -0.7s;
    top: 52px;
    left: 11px;
}

.lds-default div:nth-child(9) {
    animation-delay: -0.8s;
    top: 62px;
    left: 22px;
}

.lds-default div:nth-child(10) {
    animation-delay: -0.9s;
    top: 66px;
    left: 37px;
}

.lds-default div:nth-child(11) {
    animation-delay: -1s;
    top: 62px;
    left: 52px;
}

.lds-default div:nth-child(12) {
    animation-delay: -1.1s;
    top: 52px;
    left: 62px;
}

@keyframes lds-default {
    0%,
    20%,
    80%,
    100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}

body .spinner.active {
    &:after {
        border-color: $brand-custom;
        // vertical-align: middle;
    }

    &.active + .action-text {
        visibility: hidden;
    }
}

.main-content#billing {
    .artwork-data {
        max-width: 41%;
        font-size: 2rem;

        .data-item {
            line-height: 1.2;
            font-weight: 200;
            font-size: 1.7rem;

            &.artist-data {
                font-weight: 500;
            }

            &.art-data {
                font-style: italic;
            }

            &.edition-data {
                margin-block-start: 0.6em;
                font-size: 0.8em;
            }

            &:nth-child(1) {
                margin-block-end: 1.3em;
            }

            &:nth-child(n + 3) {
                margin-block-start: 0.5em;
            }
        }
    }
}

.event-name {
    margin: 0.25em 0;
}

.r-16-9 {
    height: 0;
    position: relative;
    padding-top: 56.25%;

    img {
        background-color: black;
        height: 100%;
        width: 100%;
        position: absolute;
        inset: 0;
    }
}
