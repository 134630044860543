@mixin footer-height($footer-height: 172px, $always-sticky: 'false') {
    .wrap {
        margin-bottom: -$footer-height + (-1);
        @media (max-width: $screen-xs-max) {
            @if $always-sticky == 'false' {
                margin-bottom: 0;
            } @else {
                margin-bottom: -$footer-height + (-1) !important;
            }
        }
    }
    .main-footer,
    .push {
        height: $footer-height + 1;
        @media (max-width: $screen-xs-max) {
            @if $always-sticky == 'false' {
                height: auto !important;
            } @else {
                height: $footer-height + 1 !important;
            }
        }
    }
}
body {
    @include footer-height;
}
html,
body {
    height: 100%;
    @media (max-width: $screen-xs-max) {
        height: auto !important;
    }
    &.home-page {
        height: 100% !important;
    }
}

.wrap {
    min-height: 100%;
    height: auto !important;
    /*height: 100%;*/
}
.main-footer {
    font-size: 16px;
    border-top: 1px solid #ccc;
    padding: 50px 0;
    @media (max-width: $screen-xs-max) {
        padding: 30px 0;
    }
    &-links {
        font-weight: 700;
        margin-bottom: 20px;
        a {
            color: #333;
        }
        &-texts {
            /*text-align: left;
      @media (max-width: $screen-md-max){
        text-align: center;
      }*/
            a {
                margin-right: 15px;
            }
        }
        &-social {
            text-align: right;
            /*@media (max-width: $screen-md-max){
        text-align: center;
      }*/
            a {
                margin-left: 15px;
                @media (max-width: $screen-xs-max) {
                    margin-left: 0;
                    margin-right: 15px;
                }
            }
            @media (max-width: $screen-xs-max) {
                text-align: left;
                margin-top: 5px;
            }
        }

        &-social.always-center {
            @media (max-width: $screen-xs-max) {
                text-align: center;
            }
        }
    }
}

social-panel > a {
    color: #333 !important;
}

.home-page,
#home-page {
    .main-footer-text {
        text-align: left;
        @media (max-width: $screen-md-max) {
            text-align: center;
        }
    }

    .main-footer-links-social {
        @media (max-width: $screen-md-max) {
            text-align: center;
        }
    }

    .main-footer-text {
        font-family: Muli-Regular, 'Muli', sans-serif;
        font-size: 18px;
        font-weight: 500;
        color: #000000 !important;
    }
}

.main-footer-text {
    color: #666;
    margin: 0 0 20px;
}
