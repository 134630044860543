.circle {
    width: 24px;
    height: 24px;
    background: transparent;
    border-radius: 50% !important;
    border: 1px solid gray;
    vertical-align: middle;
    margin-bottom: 4px;
    margin-top: 4px;
    outline: none !important;
    font-family: Helvetica;
    padding-left: 11px !important;
    padding-right: 11px !important;
    cursor: pointer;

    .disabled & {
        border-color: $gray-light-2;
        cursor: not-allowed;
    }
}

.circle.active::after,
.circle-sm.active::after {
    content: ' ';
    width: 12px;
    height: 12px;
    border-radius: 50% !important;
    background: $brand-custom;
    display: block;
    position: relative;
    left: calc(50% - 6px);
    opacity: 1;
    @include scale(1);
    @include transition(all 0.2s ease-in-out);

    .disabled & {
        background: $gray-light-2;
    }
}

.circle::after,
.circle-sm::after {
    content: ' ';
    opacity: 0;
    @include scale(0);
}

.circle.sm {
    width: 18px;
    height: 18px;
    //vertical-align: baseline;
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.circle.sm.active::after {
    width: 10px;
    height: 10px;
    left: calc(50% - 5px);
}

.circle-text {
    cursor: pointer;
    margin-right: 16px;
    font-size: large;

    &.sm {
        font-size: inherit;
    }

    .disabled & {
        color: $gray-light-2;
        cursor: not-allowed;
    }
}

// Backwards compatibility
.circle-sm {
    @extend .circle;
    width: 18px;
    height: 18px;
    //vertical-align: baseline;
    padding-left: 8px !important;
    padding-right: 8px !important;
}

.circle-sm.active::after {
    width: 10px;
    height: 10px;
    left: calc(50% - 5px);
}
