%thumbnail-subtitle {
    .thumbnail-subtitle {
        font-family: MuseoSans, Helvetica, Arial, sans-serif;

        .thumbnail-title-text {
            color: #4a4a4a;
            margin-top: 0;
            font-size: 14px;

            a {
                color: #4a4a4a;
            }

            & > span {
                color: #4a4a4a;
                font-weight: 600;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 28px;
                display: inline-block;
                margin-top: -4px;

                a {
                    color: #4a4a4a;
                }
            }
        }
    }
}

small {
    color: #4a4a4a;
    font-size: 12px;
    letter-spacing: 0;
    display: block;
    line-height: 12px;
    margin-top: 0;
}

.for-you {
    margin-bottom: 55px;
    .slide-group {
        position: relative;
        margin: 0 20px;

        > .row {
            overflow-x: auto;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch; // For safari-ios: https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-overflow-scrolling

            // Hide scrollbar: https://www.codegrepper.com/code-examples/css/hide+scrollbar+css+all+browsers
            -ms-overflow-style: none;
            scrollbar-width: none; /* Firefox */
            &::-webkit-scrollbar {
                display: none;
            }

            > .slide-item {
                display: inline-block;
                float: none;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        .carousel-prev,
        .carousel-next {
            background-image: url('../modules/core/img/down_arrow.svg');
            width: 28px;
            height: 17px;
            padding: 0;
            opacity: 1;
            position: absolute;
            cursor: pointer;
            top: 38%;
            background-repeat: no-repeat;
        }

        .carousel-prev {
            transform: rotate(90deg);
            left: -60px;
        }

        .carousel-next {
            transform: rotate(-90deg);
            right: -60px;
        }

        @media (max-width: $screen-md) {
            .carousel-prev {
                left: -55px;
            }
            .carousel-next {
                right: -55px;
            }
        }
        @media (max-width: $screen-sm) {
            .carousel-prev,
            .carousel-next {
                width: 26px;
                height: 15px;
                background-size: 25px;
            }
            .carousel-prev {
                left: -30px;
            }
            .carousel-next {
                right: -30px;
            }
            > .row {
                margin-left: 0;
                margin-right: 0;
            }
        }
        @media (max-width: $screen-xs) {
            .carousel-prev,
            .carousel-next {
                width: 20px;
                height: 15px;
                background-size: 20px;
            }
            > .row {
                > .slide-item {
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }
        }
    }

    .title-link {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 40px;
        a {
            font-size: 14px;
            font-weight: 600;
            color: #1c658d;
            font-family: MuseoSans, Helvetica, Arial, sans-serif;
        }
    }

    //.thumbnail-content {
    //  margin-bottom: 10px;
    //}

    h2 {
        color: #000000;
        font-family: MuseoSans, Helvetica, Arial, sans-serif;
        font-size: 26px;
        letter-spacing: 0;
        line-height: 28px;
        margin: 0 20px 0 20px;
    }

    h3 {
        margin: 8px 20px 20px 20px;
        font-family: MuseoSans, Helvetica, Arial, sans-serif;
        font-size: 14px;
    }

    .catalog-thumbnail,
    .artcast-thumbnail,
    .artwork-thumbnail,
    .member-thumbnail {
        margin-bottom: 0;
        @media (max-width: $screen-sm) {
            .thumbnail-label-featured {
                display: none;
            }
        }
    }

    catalog-thumbnail,
    artcast-thumbnail {
        .thumbnail-title-text {
            max-width: 28vw;
            padding-right: 5px;
        }
        @media (max-width: $screen-sm-max) {
            .thumbnail-title-text {
                font-size: 14px;
            }
        }
        @media (max-width: $screen-xs) {
            .thumbnail-userpic,
            .thumbnail-label {
                display: none;
            }
            .thumbnail-title {
                top: -62px;
            }
            .thumbnail-title-text {
                font-size: 12px;
            }
        }
        @media (max-width: 350px) {
            .thumbnail-title-text {
                font-size: 10px;
                max-width: 25vw;
            }
        }
    }

    .featured-items {
        .carousel-prev,
        .carousel-next {
            top: 38%;
        }
    }

    .channels {
        .carousel-prev,
        .carousel-next {
            top: 110px;
        }
        @media (max-width: 1200px) {
            .carousel-prev,
            .carousel-next {
                top: 9vw;
            }
        }
        @media (max-width: $screen-sm-max) {
            .carousel-prev,
            .carousel-next {
                top: 11vw;
            }
        }
        @media (max-width: 600px) {
            .carousel-prev,
            .carousel-next {
                top: 9vw;
            }
        }
        @media (max-width: $screen-xs-max) {
            .carousel-prev,
            .carousel-next {
                top: 10vw;
            }
        }
        @media (max-width: $screen-xs) {
            .carousel-prev,
            .carousel-next {
                top: 8vw;
            }
        }
        @media (max-width: 400px) {
            .carousel-prev,
            .carousel-next {
                top: 7vw;
            }
        }
        //@media (max-width: 360px) {
        //  .carousel-prev, .carousel-next {
        //    top: 6vw;
        //  }
        //}
    }

    .artworks {
        .carousel-prev,
        .carousel-next {
            top: 30%;
            @media (max-width: $screen-md) {
                top: 10vw;
            }
            @media (max-width: 600px) {
                top: 9vw;
            }
            @media (max-width: $screen-xs) {
                top: 8vw;
            }
            @media (max-width: 400px) {
                top: 7vw;
            }
        }

        artwork-thumbnail {
            //height: 189px;
            //width: 240px;
            //margin-right: 10px;
            //display: inline-block;

            .thumbnail-artwork-duration,
            .thumbnail-full-description,
            .thumbnail-artwork-medium {
                display: none !important;
            }
            .thumbnail-title {
                font-size: 14px;
                margin-top: 5px;
                font-weight: 600;

                .thumbnail-title-extra {
                    display: none;
                }
            }

            @extend %thumbnail-subtitle;
        }
    }

    .channels {
    }

    .members {
        .carousel-prev,
        .carousel-next {
            @media (max-width: $screen-xs) {
                top: 12vw;
            }
            @media (max-width: 400px) {
                top: 10vw;
            }
        }
        member-thumbnail {
            height: 220px;
            width: 167px;
            // display: inline-block;

            .thumbnail-title-text {
                margin-top: 5px;
                font-size: 14px;
            }
        }
    }

    .editorials {
        padding: 0 20px;

        .thumbnail-content {
            height: 236px;
            width: 324px;
            display: inline-block;

            .thumbnail-image {
                position: relative;
            }

            .thumbnail-text {
                color: #ffffff;
                padding: 0 5px;
                text-align: left;
                display: inline-block;
                position: absolute;
                bottom: 10px;
                font-size: 20px;
                font-weight: 600;
            }
        }
    }
    @media (max-width: $screen-sm) {
        h2 {
            margin-left: 35px;
        }
        h3 {
            margin: 8px 20px 20px 35px;
        }
    }
    @media (max-width: $screen-xs) {
        h2 {
            font-size: 22px;
        }
    }
}
