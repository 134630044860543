@mixin rounded($radius: 0.5em) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

@mixin flex-row-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

@mixin flex-row-even {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

@mixin flex-row-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

@mixin flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

@mixin flex-column-reverse {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
}

@mixin button-variant-niio($color, $background, $border) {
    color: $color;
    background-color: $background;
    border-color: $border;

    &:focus,
    &.focus {
        color: $color;
        background-color: $background;
        border-color: darken($border, 25%);
    }
    &:hover {
        color: $color;
        background-color: darken($background, 10%);
        border-color: darken($border, 12%);
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        background-color: $background;
        background-image: none;
        border-color: $background;

        &:hover,
        &:focus,
        &.focus {
            color: $color;
            background-color: darken($background, 17%);
            border-color: darken($border, 25%);
        }
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }

    .badge {
        color: $background;
        background-color: $color;
    }
}

$brand-disable: #d8d8d8;
$border-gray: rgba(206, 206, 206, 0.44);
.billing-plans {
    font-family: 'MuseoSans', sans-serif;

    &-modal {
        .main-content {
            padding: 0 20px;
            background-color: #ffffff;
        }

        .plan-card-list {
            @media (max-width: 1060px) {
                align-items: center;
            }
            .plan-card {
                &-header {
                    & > div {
                        &:nth-child(2) {
                            width: 114px;
                        }
                    }
                    &-billing-type {
                        ul {
                            min-width: 195px;
                        }
                    }
                }
            }
        }
    }

    .container-fluid {
        padding: 0;
        border-width: 0;
    }

    .page-header {
        border: 0;

        button {
            &.close {
                position: absolute;
                top: 15px;
                right: 15px;
            }
        }

        .plan-prime-icon {
            svg {
                width: 54px;
                height: 47px;
            }
        }

        h2,
        h3 {
            font-weight: 300;
            margin-top: 15px;
        }

        h2 {
            font-size: 32px;
            line-height: 38px;
        }

        h3 {
            font-size: 22px;
            line-height: 31px;
            margin-bottom: 0;
        }

        @media (max-width: 550px) {
            h2 {
                font-size: 20px;
                line-height: 24px;
            }
            h3 {
                font-size: 16px;
                line-height: 24px;
                margin: 0 auto;
                text-align: center;
                max-width: 230px;
            }
        }
    }

    .main-content {
        .nav-tabs {
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                min-width: 200px;
                text-align: center;
                height: 40px;

                a {
                    color: $brand-gray;
                    border: 0;
                    font-size: $font-size-base;
                    background-color: transparent;
                    padding: 8px 15px;

                    &:hover,
                    &:focus {
                        color: $brand-custom;
                    }
                }

                &.active {
                    a {
                        border-bottom: 2px solid $brand-custom;
                        color: $brand-custom;
                        font-weight: 500;
                    }
                }
            }
        }

        @media (max-width: 440px) {
            .nav-tabs {
                li {
                    min-width: 135px;
                }
            }
        }
    }
}

.plan-card-list {
    margin-top: 50px;
    @include flex-row-between;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;

    &.loading {
        .plan-card-container {
            .plan-card-header,
            .plan-card-more-less,
            .icons-shine,
            .twoLiner-shine,
            .plan-buttons {
                position: relative;
                overflow: hidden;
                border-radius: 4px;

                &::after {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
                    animation: shine 1.6s infinite linear;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
    }

    .freePlan .plan-card-body {
        min-height: 149px;
    }

    .parent-icons-shine {
        display: flex;
        justify-content: space-between;
        width: 100%;

        & ~ span {
            display: none;
        }
    }
    .icons-shine {
        width: 20%;
        height: 2rem;
        margin-bottom: 1rem;
    }
    .parent-twoLiner-shine {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        + .plan-card-body-twoLiner {
            display: none;
        }
    }
    .twoLiner-shine {
        height: 1.5rem;
        margin-bottom: 1rem;
    }

    .plan-card {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: stretch;
        width: 362px;
        border: 1px solid $border-gray;
        padding: 15px 20px 10px 20px;
        @include rounded(4px);
        position: relative;
        box-shadow: 0 4px 3px 0 rgba(100, 100, 100, 0.27);

        &.active {
            border-color: $brand-custom;
        }

        &.disabled {
            background-color: $brand-disable;
            pointer-events: none;
        }

        &-more-less {
            &-icon {
                @include flex-row-start;
                font-size: 12px;
                font-weight: 600;
                color: $brand-custom;
                cursor: pointer;

                &.disable {
                    span {
                        &:first-child {
                            svg {
                                .more-less-icon-fill {
                                    fill: $brand-disable;
                                }
                            }
                        }
                    }
                }

                span {
                    &:first-child {
                        width: 26px;
                        height: 26px;

                        svg {
                            width: inherit;
                            height: inherit;
                        }
                    }

                    &:nth-child(2) {
                        padding-left: 5px;
                        padding-top: 5px;
                    }
                }
            }
        }

        &-header {
            text-align: left;
            padding-bottom: 5px;
            @include flex-row-between;
            border-bottom: 1px solid #dadada;

            & > div {
                &:first-child {
                    display: flex;
                }

                &:nth-child(2) {
                    @include flex-column-center;
                    width: 108px;
                }
            }

            &-title-price {
                letter-spacing: 0.6px;
                @include flex-column-center;
                padding-left: 10px;
            }

            &-title {
                font-size: 26px;
                font-weight: 500;
                text-transform: uppercase;
            }

            &-price {
                font-size: 26px;
                font-weight: 600;

                &-bill {
                    font-size: 17px;
                    font-weight: 300;
                }
            }

            .plan-header-subtitle {
            }

            &-billing-type {
                font-size: 14px;
                color: $brand-gray;

                &.dropdown {
                    margin-top: 15px;
                }

                img {
                    width: 10px;
                    height: 10px;
                    margin-top: -2px;
                    margin-left: 2px;
                }

                ul {
                    padding: 5px;
                    min-width: 188px;
                    li {
                        cursor: pointer;

                        &:hover {
                            background-color: $border-gray;
                            color: #ffffff;
                        }
                    }
                }

                &-text {
                    color: $brand-gray;
                }

                &-item {
                    &-text {
                        font-size: 12px;
                        color: $brand-gray;
                        letter-spacing: 0.4px;

                        &-save {
                            color: $brand-custom;
                            font-weight: 500;
                        }
                    }

                    &-icon {
                        display: inline-block;
                        width: 14px;
                        height: 16px;
                        margin-right: 5px;
                        padding-left: 2px;

                        svg {
                            height: 10px;
                            width: 14px;
                        }
                    }
                }
            }

            &-type {
                letter-spacing: 0.47px;
                font-size: 14px;
                font-weight: 300;
                color: $brand-custom;
                margin-top: 5px;

                &.business {
                    background-color: $brand-custom;
                    opacity: 0.7;
                    color: #ffffff;
                    padding: 0 5px;
                    @include rounded(2px);
                    font-weight: 600;
                }
            }

            &-selector {
                padding-top: 5px;

                .circle {
                    width: 22px;
                    height: 22px;
                    padding-left: initial !important;
                    padding-right: initial !important;
                }
            }
        }

        &-body {
            padding-left: 15px;
            flex: 1;

            &-images {
                &-left {
                    @include flex-row-start;
                    justify-content: stretch;

                    & > span {
                        padding-right: 30px;
                    }
                }

                &-between {
                    @include flex-row-between;
                }

                margin: 15px 0;

                svg {
                    width: 64px;
                    height: 30px;
                }

                .prime {
                    svg {
                        width: 36px;
                    }
                }

                .four-k {
                    width: 60px;
                }
            }

            &-features {
                margin-top: 15px;
                @include flex-column-center;

                & > div {
                    padding-bottom: 6px;
                }

                &-icon {
                    &.prime {
                        width: 18px;
                        height: 14px;

                        svg {
                            width: inherit;
                            height: inherit;
                        }
                    }

                    &.check {
                        width: 17px;
                        height: 12px;

                        svg {
                            width: inherit;
                            height: inherit;
                        }
                    }

                    &.disable {
                        display: inline-block;

                        svg {
                            width: 17px;
                            height: auto;
                            vertical-align: text-top;
                        }
                    }
                }

                &-text {
                    font-size: 15px;
                    color: $brand-gray;
                    padding-left: 5px;
                }
            }

            &-twoLiner {
                span {
                    display: block;
                    font-size: 15px;
                    font-weight: 600;
                    line-height: 22px;
                }
            }

            .plan-body-features {
                list-style-type: none;
                padding: 0;
                margin: 0;

                li > i {
                    @extend .text-brand;
                    @extend .glyphicon, .glyphicon-ok;
                    padding-right: 10px;
                }
            }
        }
    }

    @media (max-width: 1190px) {
        padding: 0 10px;
        .plan-card {
            max-width: 360px;
            width: 100%;
        }
    }
    @media (max-width: 1030px) {
        align-items: center;
    }
}

.plan-continue-user {
    padding: 10px 0;
    text-align: center;

    a {
        color: $brand-custom;
        font-weight: 500;
    }
}

.terms-of-use {
    text-align: center;

    span {
        max-width: 380px;
        text-align: center;
        font-size: 12px;
        display: inline-block;
        color: $brand-gray;
    }

    a {
        color: $brand-custom;
    }
}

.plan-buttons {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;

    button,
    a.btn {
        width: 296px;
        height: 57px;
        line-height: 47px;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: 500;
        border-color: $brand-gray;
        color: $brand-gray;
        background-color: transparent;
        @include rounded(6px);

        &.active {
            @include button-variant-niio($btn-custom-color, $brand-custom, $brand-custom);
        }

        &.disabled {
            @include button-variant-niio($btn-custom-color, #e0e0e0, #e0e0e0);
            pointer-events: none;
        }

        &.muted {
            @include button-variant-niio($btn-custom-color, #e0e0e0, #e0e0e0);
        }
    }
}

@keyframes shine {
    0% {
        background-position: -100px;
    }
    100% {
        background-position: 140px;
    }
}
