// @import url("//hello.myfonts.net/count/2b930e");
// Museo Sans;
@font-face {
    font-family: 'MuseoSans';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_3_0.eot');
    src: url('../fonts/museo-sans/2B930E_3_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_3_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_3_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_3_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 500;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_8_0.eot');
    src: url('../fonts/museo-sans/2B930E_8_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_8_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_8_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_8_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_0_0.eot');
    src: url('../fonts/museo-sans/2B930E_0_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_0_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_0_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_0_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 700;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_5_0.eot');
    src: url('../fonts/museo-sans/2B930E_5_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_5_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_5_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_5_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_9_0.eot');
    src: url('../fonts/museo-sans/2B930E_9_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_9_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_9_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_9_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: 900;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_2_0.eot');
    src: url('../fonts/museo-sans/2B930E_2_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_2_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_2_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_2_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_4_0.eot');
    src: url('../fonts/museo-sans/2B930E_4_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_4_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_4_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_4_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: normal;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_7_0.eot');
    src: url('../fonts/museo-sans/2B930E_7_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_7_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_7_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_7_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: bold;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_1_0.eot');
    src: url('../fonts/museo-sans/2B930E_1_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_1_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_1_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_1_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSans';
    font-weight: bold;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_6_0.eot');
    src: url('../fonts/museo-sans/2B930E_6_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_6_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_6_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_6_0.svg#wf') format('svg');
}
// Museo Sans Fixed

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_3_0.eot');
    src: url('../fonts/museo-sans/2B930E_3_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_3_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_3_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_3_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 500;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_8_0.eot');
    src: url('../fonts/museo-sans/2B930E_8_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_8_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_8_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_8_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_0_0.eot');
    src: url('../fonts/museo-sans/2B930E_0_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_0_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_0_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_0_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 700;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_5_0.eot');
    src: url('../fonts/museo-sans/2B930E_5_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_5_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_5_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_5_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_9_0.eot');
    src: url('../fonts/museo-sans/2B930E_9_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_9_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_9_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_9_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 900;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_2_0.eot');
    src: url('../fonts/museo-sans/2B930E_2_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_2_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_2_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_2_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 100;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_4_0.eot');
    src: url('../fonts/museo-sans/2B930E_4_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_4_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_4_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_4_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 100;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_7_0.eot');
    src: url('../fonts/museo-sans/2B930E_7_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_7_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_7_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_7_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_1_0.eot');
    src: url('../fonts/museo-sans/2B930E_1_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_1_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_1_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_1_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 300;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_6_0.eot');
    src: url('../fonts/museo-sans/2B930E_6_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_6_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_6_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_6_0.svg#wf') format('svg');
}

// @import url("//hello.myfonts.net/count/2b930e");
// Museo Sans Fixed;
@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 500;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_3_0.eot');
    src: url('../fonts/museo-sans/2B930E_3_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_3_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_3_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_3_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 500;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_8_0.eot');
    src: url('../fonts/museo-sans/2B930E_8_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_8_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_8_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_8_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 700;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_0_0.eot');
    src: url('../fonts/museo-sans/2B930E_0_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_0_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_0_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_0_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 700;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_5_0.eot');
    src: url('../fonts/museo-sans/2B930E_5_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_5_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_5_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_5_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 900;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_9_0.eot');
    src: url('../fonts/museo-sans/2B930E_9_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_9_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_9_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_9_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 900;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_2_0.eot');
    src: url('../fonts/museo-sans/2B930E_2_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_2_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_2_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_2_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 100;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_4_0.eot');
    src: url('../fonts/museo-sans/2B930E_4_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_4_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_4_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_4_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 100;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_7_0.eot');
    src: url('../fonts/museo-sans/2B930E_7_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_7_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_7_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_7_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 300;
    font-style: normal;
    src: url('../fonts/museo-sans/2B930E_1_0.eot');
    src: url('../fonts/museo-sans/2B930E_1_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_1_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_1_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_1_0.svg#wf') format('svg');
}

@font-face {
    font-family: 'MuseoSansFixed';
    font-weight: 300;
    font-style: italic;
    src: url('../fonts/museo-sans/2B930E_6_0.eot');
    src: url('../fonts/museo-sans/2B930E_6_0.eot?#iefix') format('embedded-opentype'),
        url('../fonts/museo-sans/2B930E_6_0.woff') format('woff'),
        url('../fonts/museo-sans/2B930E_6_0.ttf') format('truetype'),
        url('../fonts/museo-sans/2B930E_6_0.svg#wf') format('svg');
}

// Adobe Garamond Pro;

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: italic;
    font-weight: normal;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Italic.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: italic;
    font-weight: 300;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Italic.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: italic;
    font-weight: 400;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-SemiboldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: italic;
    font-weight: 500;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-SemiboldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Bold.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: 100;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: 200;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Semibold.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Semibold.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Bold.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: 700;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Bold.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: normal;
    font-weight: 900;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-Bold.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Adobe Garamond Pro';
    font-style: italic;
    font-weight: bold;
    src: url('../fonts/adobe-garamond-pro/AGaramondPro-BoldItalic.otf') format('opentype'),
        url('../fonts/adobe-garamond-pro/AGaramondPro-BoldItalic.woff') format('woff');
}

/*
@font-face {
  font-family: 'Muli-Regular';
  src: url("../fonts/Muli-Regular.ttf");
  src: url("../fonts/Muli-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Muli-Regular';
  src: url("../fonts/Muli-Italic.ttf");
  src: url("../fonts/Muli-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Muli-Italic';
  src: url("../fonts/Muli-Italic.ttf");
  src: url("../fonts/Muli-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Muli-Bold';
  src: url("../fonts/Muli-Bold.ttf");
  src: url("../fonts/Muli-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

@font-face {
  font-family: 'Muli-Light';
  src: url("../fonts/Muli-Light.ttf");
  src: url("../fonts/Muli-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Muli-ExtraLightItalic';
  src: url("../fonts/Muli-ExtraLightItalic.ttf");
  src: url("../fonts/Muli-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic; }
  */
