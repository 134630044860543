@mixin custom-thumbnail-label($bg: #222, $color: #eee, $icon: '') {
    background: $bg;
    color: $color;
    @if $icon != '' {
        &:before {
            padding-right: 0.2em;
            @extend .glyphicon, .glyphicon-#{$icon};
        }
    }
}
.thumbnail-labels {
    @extend .list-unstyled;
    position: absolute;
    top: 6px;
    // left: $grid-gutter-width / -3;
    left: 0;
    font-size: 10px;
}
.thumbnail-label {
    @extend .pull-left;
    clear: left;
    margin-bottom: 0.4em;
    padding: 0.2em 0.5em;
    line-height: 1;
    white-space: nowrap;
    text-transform: uppercase;
    @include custom-thumbnail-label();
}
/// Labels by name

// .thumbnail-label-featured{
//   @include custom-thumbnail-label(green,white,star);
// }
// .thumbnail-label-new{
//   @include custom-thumbnail-label(red,white,asterisk);
// }
// .thumbnail-label-best{
//   @include custom-thumbnail-label(pink,brown);
// }
// .thumbnail-label-purchased{
//   @include custom-thumbnail-label(purple,yellow);
// }
.thumbnail-label-visible,
.thumbnail-label-restriction-a-rated,
.thumbnail-label-locked {
    @include custom-thumbnail-label($brand-danger, white);
}
.thumbnail-label-shortlisted,
.thumbnail-label-finalist {
    @include custom-thumbnail-label(green, white);
}
// .thumbnail-label-restriction{
//   @include custom-thumbnail-label(purple, #7dd1ff);
// }
// .thumbnail-label-wishlist{
//   @include custom-thumbnail-label(black, yellow);
// }
// .thumbnail-label-processing{
//   @include custom-thumbnail-label(#ffc4b3, #2d2a87);
// }
// .thumbnail-label-soldOut{
//   @include custom-thumbnail-label(red,yellow);
// }
