// General Styles

.gray-title-sm {
    font-weight: 500;
    color: #9c9c9c;
    font-size: 16px;
}

.gray-title-lg {
    font-weight: 300;
    color: #505050;
    font-size: 30px;
}

.gray-text-sm {
    color: #9c9c9c;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
}

.gray-text-md {
    font-weight: 300;
    color: #9c9c9c;
    font-size: 20px;
    //  font-style: italic;
}

// Navbar

.navbar-brand-logo {
    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -ms-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;

    height: 17px;
    width: 90px;
    fill: #eaeaea;
}

.navbar-brand-logo:hover {
    fill: #ffffff !important;
}

.navbar-brand-logo.transparent {
    transition: all 500ms ease-in-out;
    fill: #3b3b3b;
}

.navbar-brand-logo.transparent:hover {
    fill: #000000 !important;
}

.navbar-inverse .navbar-nav > .active > a {
    background-color: transparent;
    fill: #ffffff !important;
    text-shadow: 0px 0px 1px rgba(255, 255, 255, 1);
}

.navbar-inverse .navbar-nav > .active > a:hover {
    background-color: transparent;
}

//.navbar-brand svg {
//  height: 17px;
//  width: 90px;
//  fill: #3b3b3b;
//}

.navbar.transparent {
    background: rgba(0, 0, 0, 0);
    border-color: transparent;
}

.navbar-inner a {
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;

    //  color: #eaeaea !important;
    font-weight: bold;
    font-size: 22px;
}

.navbar-inner a:hover {
    color: #ffffff !important;
    text-shadow: 0px 0px 1px rgba(255, 255, 255, 1);
}

.navbar.transparent {
    background: rgba(0, 0, 0, 0);
}

.navbar-inner.transparent a {
    color: #4a4a4a !important;
    //  font-family: museo_sans100;
    //  font-weight: bold;
    //  font-size: 1.3em;
}

.navbar-inner.transparent a:hover {
    color: #828282 !important;
    text-shadow: 0px 0px 10px rgb(255, 255, 255);

    //  color: #000000 !important;
}

@mixin svg-color($color: #000) {
    svg {
        stroke: $color;
    }
}

// .navbar-right a {
// padding-left: 5px !important;
// padding-right: 5px !important;
// }

// Sub headers
.subheader {
    height: 60px;
    padding-top: 17px;
}

.subheader-selection {
    font-weight: 100;
    font-size: 20px;
    color: #828282;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 13px;
    margin-right: 50px;

    border-bottom-style: solid;
    border-bottom-width: 0px;
    border-bottom-color: #bdbdbd;

    -webkit-transition: all 200ms ease-in-out;
    -moz-transition: all 200ms ease-in-out;
    -ms-transition: all 200ms ease-in-out;
    -o-transition: all 200ms ease-in-out;
    transition: all 200ms ease-in-out;
}

//
//.subheader-selection-login {
//  margin-right: 0;
//}

.subheader-selection:hover {
    color: black !important;
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-bottom-color: #bdbdbd;
}

.subheader-selection.active {
    font-weight: 300;
    color: #3e3e3e;
    border-bottom-style: solid;
    border-bottom-width: 5px;
    border-bottom-color: #8c8c8c;
}

.full-hl {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    border-bottom: 1px solid #d3d3d3;
}

// No borders on container
//section.container {
//  width: auto !important;
//}

.square {
    font-size: 1px;
    width: 16px;
    height: 16px;
    background: transparent;
    border: 1px solid gray;
    vertical-align: middle;
    outline: none !important;
}

.square.active::after,
.square.selected-not-active::after {
    content: ' ';
    width: 8px;
    height: 8px;
    background: dimgray;
    display: block;
    //  border-top: 0;
    left: -3px;
    position: relative;
    margin-top: 1px;
    //  left: 6px;
    //  position: absolute;
    //  margin-top: -5px;

    &.square.selected-not-active::after {
        background: #d1d1d1;
        border: #d1d1d1;
    }
}

.transitioned-arrow {
    -webkit-transition: all 70ms ease-in-out;
    -moz-transition: all 70ms ease-in-out;
    -ms-transition: all 70ms ease-in-out;
    -o-transition: all 70ms ease-in-out;
    transition: all 70ms ease-in-out;
}

.transitioned-arrow.active {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}

footer {
    color: #c9c9c9;
}

header,
header .container {
    height: 50px !important;
}

.footer-links-color a,
.footer-links-color span {
    color: #d3d3d3;

    -webkit-transition: all 100ms ease-in-out;
    -moz-transition: all 100ms ease-in-out;
    -ms-transition: all 100ms ease-in-out;
    -o-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
}

.footer-links-color a:hover {
    color: white;
}

.footer-top-line {
    margin-top: 10px;
}

.footer-bottom-line {
    font-size: 12px;
}

.footer-facebook:hover {
    color: #3e5298 !important;
    //  background-color: white !important;
    //  border-radius: 3px;
}

.footer-twitter:hover {
    color: #4a9ced !important;
}

.footer-google:hover {
    color: #d73b2e !important;
}

.footer-linkedin:hover {
    color: #1c78b4 !important;
}

@media all and (max-width: 1200px) {
}

@media all and (max-width: 992px) {
    .footer-top-line {
        text-align: center;
    }
}

@media all and (max-width: 768px) {
    .navbar-inner a,
    .navbar-inner i {
        //    line-height: 10px !important;
    }

    .navbar-nav.pull-left {
        //    margin-left: 10px;
    }

    .navbar-nav > li {
        //    float: left;
    }
}

@media all and (max-width: 480px) {
    .navbar-inner.transparent {
        display: none;
    }

    .navbar-brand.transparent {
        float: none;
        position: absolute;
        left: 35%;
    }

    .navbar-nav.pull-left {
        margin-left: 10px;
    }
}
