$icon-font-path: 'bootstrap-sass/assets/fonts/bootstrap/';
$body-bg: #fff;
$white: #fff;
$gray-darker: lighten(#000, 13.5%); // #222
$gray-dark: lighten(#000, 20%); // #333
$gray: lighten(#000, 33.5%); // #555
$gray-light: lighten(#000, 46.7%); // #777
$gray-light-2: lighten(#000, 60%); //
$gray-lighter: lighten(#000, 93.5%); // #eee
// $gray-lighter                          : lighten(#000, 60%); // #eee

$brand-primary: #113c58;
$brand-primary-hover: lighten(#113c58, 20%);
$brand-gray: #4a4a4a;

$brand-success: #5cb85c;
$brand-info: #5bc0de;
$brand-warning: #f0ad4e;
$brand-danger: #d9534f;

$energized: #ffc900 !default;
$brand-custom: #fc3d03;
$brand-custom-hover: #9a2f1f;
//$brand-primary:         $brand-custom;

// -----  #ALERTS  ----------------

// $state-success-text:             #333;
// $state-success-bg:               #fff;
// $state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%);

// $state-info-text:                #333;
// $state-info-bg:                  #fff;
// $state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%);

$state-warning-text: #333;
$state-warning-bg: #fff;
$state-warning2-bg: $brand-warning;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%);

$state-warning3-text: #8a6d3b !default;
$state-warning3-bg: #fcf8e3 !default;
$state-warning3-border: darken(adjust-hue($state-warning3-bg, -10), 5%) !default;

$alert-warning3-bg: $state-warning3-bg !default;
$alert-warning3-text: $state-warning3-text !default;
$alert-warning3-border: $state-warning3-border !default;

// $state-danger-text:              #333;
// $state-danger-bg:                #fff;
// $state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%);

//** Border color for inputs on focus
$input-border-focus: transparent;

//** Global text color on `<body>`.
// $text-color:            $gray-dark !default;

//** Global textual link color.
$link-color: #256789;
//** Link hover color set via `darken()` function.
$link-hover-color: lighten($link-color, 15%);
//** Link hover decoration.
// $link-hover-decoration: underline !default;
//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: 'proxima-nova', sans-serif;
// $font-family-serif:       Georgia, "Times New Roman", Times, serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
// $font-family-monospace:   Menlo, Monaco, Consolas, "Courier New", monospace !default;
// $font-family-base:        $font-family-sans-serif !default;

$font-size-base: 16px;
// $font-size-large:         ceil(($font-size-base * 1.25)) !default; // ~18px
// $font-size-small:         ceil(($font-size-base * 0.85)) !default; // ~12px

// $font-size-h1:            floor(($font-size-base * 2.6)) !default; // ~36px
// $font-size-h2:            floor(($font-size-base * 2.15)) !default; // ~30px
// $font-size-h3:            ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;
$font-size-h6: $font-size-base;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
// $line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

//** By default, this inherits from the `<body>`.
// $headings-font-family:    inherit !default;
$headings-font-weight: 700;
// $headings-line-height:    1.1 !default;
// $headings-color:          inherit !default;

//$icon-font-path                        : '../fonts/bootstrap/';

$grid-gutter-width: 40px;
$border-radius-base: 0;
$border-radius-large: 0;
$border-radius-small: 0;

// $page-header-border-color              : $gray-light;
// $hr-border                             : $gray-light;

//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.

$btn-font-weight: 600;

$btn-default-color: $brand-primary;
$btn-default-bg: #fff;
$btn-default-border: #ddd;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $brand-primary;

$btn-custom-color: #fff;
$btn-custom-bg: $brand-custom;
$btn-custom-border: $brand-custom;

$btn-success-color: $brand-success;
$btn-success-bg: #fff;
$btn-success-border: #ddd;

$btn-info-color: $brand-info;
$btn-info-bg: #fff;
$btn-info-border: #ddd;

$btn-warning-color: $brand-danger;
$btn-warning-bg: #fff;
$btn-warning-border: #ddd;

$btn-danger-color: #fff;
$btn-danger-bg: $brand-danger;
$btn-danger-border: $brand-danger;

// $btn-link-disabled-color:        $gray-light !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: 2px;
$btn-border-radius-large: 2px;
$btn-border-radius-small: 2px;

///////////////// FORMS
// $input-bg:                       #fff;
//** `<input disabled>` background color
// $input-bg-disabled:              #fafafa;

//** Text color for `<input>`s
// $input-color:                    $gray;
//** `<input>` border color
// $input-border:                   #ccc;

//== Progress bars
//
//##

//** Background color of the whole progress component
$progress-bg: transparent;
$progressbar-opcaity: 0.1;
//** Progress bar text color
// $progress-bar-color:          #fff !default;
// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;

// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;

// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;

// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;

// Extra large screens > 13"
$screen-xl: 1280px !default;
$screen-xl-min: $screen-xl !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-xl-desktop: $screen-xl-min !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
$screen-lg-max: ($screen-xl-min - 1) !default;
//** Default progress bar color
// $progress-bar-bg:             $brand-primary !default;
//** Success progress bar color
// $progress-bar-success-bg:     $brand-success !default;
//** Warning progress bar color
// $progress-bar-warning-bg:     $brand-warning !default;
//** Danger progress bar color
// $progress-bar-danger-bg:      $brand-danger !default;
//** Info progress bar color
// $progress-bar-info-bg:        $brand-info !default;
//== Jumbotron
//
//##

// $jumbotron-padding:              30px !default;
$jumbotron-color: $body-bg;
// $jumbotron-bg:                   $gray-lighter !default;
// $jumbotron-heading-color:        inherit !default;
// $jumbotron-font-size:            ceil(($font-size-base * 1.5)) !default;
//                                     == Nav Underline

$nav-underline-bg: $body-bg;
$nav-underline-container-border-color: $gray-light;
$nav-underline-border-height: 1px;
$nav-underline-border-hover-color: $gray-light;
$nav-underline-active-border-color: $gray-light;

$nav-underline-active-border-height: 4px;
$nav-underline-link-color: $gray-light;
$nav-underline-link-hover-color: $gray-light;
$nav-underline-active-link-color: $gray-light;
$nav-underline-active-link-hover-color: $gray-light;

//== Thumbnails
//
//##

//** Padding around the thumbnail image
$thumbnail-padding: 0px;

//** Thumbnail background color
// $thumbnail-bg: $body-bg;
//** Thumbnail border color
// $thumbnail-border: #ddd;
//** Thumbnail border radius
// $thumbnail-border-radius: $border-radius-base;

//** Custom text color for thumbnail captions
// $thumbnail-caption-color: $text-color;
//** Padding around the thumbnail caption
// $thumbnail-caption-padding: 9px;

//== Navbar
//
//##

// Basics of a navbar
$navbar-height: 60px;

// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-md-min;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1);
// $navbar-margin-bottom: $line-height-computed;
// $navbar-border-radius: $border-radius-base;
// $navbar-padding-horizontal: floor(($grid-gutter-width / 2));
// $navbar-padding-vertical: (($navbar-height - $line-height-computed) / 2);
// $navbar-collapse-max-height: 340px;

// $navbar-default-color: #777;
$navbar-default-bg: #fff;
$navbar-default-border: #ccc;

// Navbar links
$navbar-default-link-color: #333;
// $navbar-default-link-hover-color: #333;
// $navbar-default-link-hover-bg: transparent;
$navbar-default-link-active-color: #333;
$navbar-default-link-active-bg: transparent;
// $navbar-default-link-disabled-color: #ccc;
// $navbar-default-link-disabled-bg: transparent;

// Navbar brand label
// $navbar-default-brand-color: $navbar-default-link-color;
// $navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%);
// $navbar-default-brand-hover-bg: transparent;

// Navbar toggle
// $navbar-default-toggle-hover-bg: #ddd;
// $navbar-default-toggle-icon-bar-bg: #888;
// $navbar-default-toggle-border-color: #ddd;

// Inverted navbar
// Reset inverted navbar basics
// $navbar-inverse-color: $gray-light;
// $navbar-inverse-bg: #222;
// $navbar-inverse-border: darken($navbar-inverse-bg, 10%);

// // Inverted navbar links
// $navbar-inverse-link-color: $gray-light;
// $navbar-inverse-link-hover-color: #fff;
// $navbar-inverse-link-hover-bg: transparent;
// $navbar-inverse-link-active-color: $navbar-inverse-link-hover-color;
// $navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%);
// $navbar-inverse-link-disabled-color: #444;
// $navbar-inverse-link-disabled-bg: transparent;

// // Inverted navbar brand label
// $navbar-inverse-brand-color: $navbar-inverse-link-color;
// $navbar-inverse-brand-hover-color: #fff;
// $navbar-inverse-brand-hover-bg: transparent;

// // Inverted navbar toggle
// $navbar-inverse-toggle-hover-bg: #333;
// $navbar-inverse-toggle-icon-bar-bg: #fff;
// $navbar-inverse-toggle-border-color: #333;

//=== Shared nav styles
// $nav-link-padding:                          10px 15px !default;
// $nav-link-hover-bg:                         $gray-lighter !default;

// $nav-disabled-link-color:                   $gray-light !default;
// $nav-disabled-link-hover-color:             $gray-light !default;

//== Tabs
$nav-tabs-border-color: #ccc;

// $nav-tabs-link-hover-border-color:          $gray-lighter !default;

// $nav-tabs-active-link-hover-bg:             $body-bg !default;
// $nav-tabs-active-link-hover-color:          $gray !default;
$nav-tabs-active-link-hover-border-color: #ccc;

$nav-tabs-justified-link-border-color: #ccc;
// $nav-tabs-justified-active-link-border-color:     $body-bg !default;

//== Pills
// $nav-pills-border-radius:                   $border-radius-base !default;
// $nav-pills-active-link-hover-bg:            $component-active-bg !default;
// $nav-pills-active-link-hover-color:         $component-active-color !default;

$navbar-collapse-max-height: auto;

//for iphone5 patches
$screen-iphone5: 320px !default;
$screen-iphone5-max: 321px !default;

// Grid

// $container-large-desktop:      (1140px + ($grid-gutter-width * 3)) ;

// TODO: mode to _bootstrap-override.scss
.modal-xxl {
    max-width: 1233px;
    height: auto;

    .modal-content {
        height: calc(100% - 40px);
    }

    @media (max-width: 1060px) {
        height: auto;
        .modal-content {
            height: auto;
        }
    }
}

.modal-dialog {
    .modal-body {
        //display: flex;
        //flex-direction: column;
        //align-items: center;
        //justify-content: space-between;
    }

    iframe {
        border: none;
    }

    .modal-content {
        margin: 0 auto;
        border-radius: 20px;
        overflow: hidden;
    }

    // Commented because it's not working on mobile where the modal content is bigger than the screen
    //@media (max-width: $screen-xs-min) {
    //    &.modal-dialog {
    //
    //        .modal-content {
    //            position: absolute;
    //            //width: 100%;
    //            margin: 0;
    //            //bottom: 0;
    //            top: 160px;
    //
    //            border-radius: 20px 20px 0 0;
    //        }
    //    }
    //}
}

@media (min-width: 768px) {
    .modal-dialog.modal-xxl {
        width: auto !important;
    }

    .modal-dialog.modal-wide {
        width: 777px !important;
    }
}

@media (max-width: 1060px) {
    .modal-dialog.modal-wide iframe {
        max-height: 700px !important;
        height: 700px !important;
    }
}
