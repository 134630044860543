@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.notifications-wrapper {
    // Disable notifications on mobile view
    position: fixed;
    bottom: 60px;
    left: 0;
    width: 100%;
    z-index: 999;
    margin: 0;

    .notification {
        @media (min-width: $screen-sm-min) {
            width: 700px;
            margin: 4px auto;
        }

        opacity: 0;
        display: flex;
        align-items: center;
        transition: opacity 0.5s ease-in-out;
        transition-delay: 0.1s;
        background-color: #f7f7f7;
        border-radius: 10px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
        margin: 7px 10px 0;
        padding: 17px;

        &.active {
            animation: fadeIn 0.5s ease-in-out;
            opacity: 1;
        }

        &-text {
            line-height: 20px;
            letter-spacing: 0.3px;
            font-family: 'MuseoSans', sans-serif;
            font-size: 16px;
            text-align: left;
            font-weight: 500;
            color: #39393a;
            margin: 0;
        }

        &-icon {
            align-self: self-start;
        }
    }
}
