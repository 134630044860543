@mixin custom-cover-thumbnail-action($icon: 'plus', $color: $text-color) {
    color: $color;
    // background: rgba($color,$progressbar-opcaity);
    .thumbnail-cover-action-icon {
        @extend .glyphicon-#{$icon};
        margin-bottom: 5px;
    }
}

/// Actions by name
.thumbnail-cover-indication-zip {
    @include custom-cover-thumbnail-action('briefcase');
}

/// Actions by name
.thumbnail-cover-action-upload {
    @include custom-cover-thumbnail-action('upload');
}
.thumbnail-cover-action-file {
    @include custom-cover-thumbnail-action('file');
}
.thumbnail-cover-action-file-image {
    @include custom-cover-thumbnail-action('picture');
}
.thumbnail-cover-action-file-video {
    @include custom-cover-thumbnail-action('facetime-video');
}
.thumbnail-cover-action-file-zip {
    @include custom-cover-thumbnail-action('compressed');
}
.thumbnail-cover-action-upload-failed {
    @include custom-cover-thumbnail-action('upload', $brand-danger);
}
.thumbnail-cover-action-processing {
    @include custom-cover-thumbnail-action('cog');
    .thumbnail-cover-action-icon {
        animation: spin 6s infinite linear;
    }
}
.thumbnail-cover-action-processing-failed {
    @include custom-cover-thumbnail-action('cog', $brand-danger);
}

@keyframes spin {
    to {
        transform: rotate(1turn);
    }
}
