.queue-container {
    .queue-selection-line {
        display: flex;
        align-items: center;
        position: relative;
        transition: background-color 0.2s ease-in-out;

        &.queue-selection-line-add-artwork {
            max-width: 75%;
        }

        &.downloaded {
            background-color: #dcf0ff;
            border-radius: 8px;
        }

        &.failedDownload {
            background-color: #fff3f3;
            border-radius: 8px;
        }

        &.downloading {
            background-color: #dcf0ff;
            border-radius: 8px;
            animation: pulse 1.5s infinite ease-in-out;
        }

        @keyframes pulse {
            0% {
                background-color: #f6fcff; /* Start with the same color */
            }
            50% {
                background-color: #dcf0ff; /* A lighter shade for pulsing */
            }
            100% {
                background-color: #f6fcffff; /* End with the original color */
            }
        }

        &:hover {
            background-color: #f3f3f3;
            border-radius: 8px;
        }

        &:has(.queue-drag-placeholder):hover {
            background-color: unset;
        }

        .queue-image-container {
            position: relative;
            width: 55px;
            height: 55px;
            border-radius: 8px;
            overflow: hidden; /* Ensures the gradient stays within the bounds */
        }

        .queue-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }

        /* Gradient overlay */
        .queue-image-gradient-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0) 50%);
            pointer-events: none; /* Allows interaction with the image */
            border-radius: 8px;

            &.queue-selection-image-scheduled {
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) 100%);
            }
        }

        /* Icons positioning */
        .queue-image-icon-corner {
            position: absolute;
            //width: 12px;
            //height: 12px;
        }

        .queue-image-icon-top-left {
            top: 5px;
            left: 5px;
        }

        .queue-image-icon-top-right {
            top: 5px;
            right: 5px;
        }

        .queue-image-icon-in-queue {
            background: url('../modules/devices/img/download_in_queue.svg') no-repeat center;
            width: 19px;
            height: 20px;
            background-size: 100% 100%;
        }

        .queue-image-icon-schedule {
            background: url('../modules/devices/img/queue_on_schedule.svg') no-repeat center;
            width: 16px;
            height: 17px;
        }

        .queue-image-icon-not-scheduled {
            background: url('../modules/devices/img/queue_not_playing_on_schedule.svg') no-repeat center;
            width: 16px;
            height: 17px;
        }

        .queue-image-icon-downloaded {
            background: url('../modules/devices/img/queue_downloaded.svg') no-repeat center;
            width: 16px;
            height: 17px;
        }

        /* Playing Now text styling */
        .queue-image-playing-now {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 11px;
            background-color: #f7254d;
            color: white;
            font-size: 8px;
            text-align: center;
            line-height: 11px;
            z-index: 10;
            border-radius: 0 0 5px 5px;
        }

        .queue-titles {
            display: flex;
            flex-direction: column;
            justify-content: space-between; /* Distributes the title and subtitle */
            height: 80%; /* Takes 80% of the container height */
            margin-left: 10px;
        }

        .queue-titles-title {
            font-weight: bold;
        }

        .queue-titles-subtitle {
            font-style: italic;
            color: #666; /* Optional: Makes the subtitle slightly lighter */
            margin-top: 0; /* Ensures minimal space above the subtitle */
            font-weight: 300;
        }

        .queue-titles-section {
            margin-bottom: 3px;
            .queue-titles-subtitle {
                font-style: unset;
            }
        }

        .queue-actions {
            position: absolute;
            right: 10px; /* Fixed position for all buttons */
            top: 30%; /* Centering vertically */
            transform: translateY(-30%);

            &.queue-actions-add-art {
                top: 50%;
                transform: translateY(-50%);
            }

            &.queue-actions-download-management {
                right: 30px;
            }
        }

        .btn-download,
        .btn-downloading,
        .btn-delete,
        .btn-retry-download,
        .btn-scheduled {
            position: absolute;
            right: 40px; /* Fixed position for both buttons */
        }

        .btn-scheduled {
            background: url('../modules/devices/img/queue_on_schedule.svg') no-repeat center;
            width: 16px;
            height: 17px;
        }

        .btn-download {
            background: url('../modules/devices/img/queue_download.svg') no-repeat center;
            width: 22px;
            height: 22px;
        }

        .btn-downloading {
            background: url('../modules/devices/img/queue_download_progress.svg') no-repeat center;
            width: 22px;
            height: 22px;
        }

        .btn-delete {
            background: url('../modules/devices/img/queue_downloaded.svg') no-repeat center;
            width: 23px;
            height: 23px;
            top: -2px;
            background-size: 100% 100%;
            filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%);
        }

        .btn-delete-hover {
            background: url('../modules/devices/img/queue_remove_download.svg') no-repeat center;
            width: 22px;
            height: 22px;
            right: 41px; /* Fixed position for both buttons */
            top: -2px;
            background-size: unset;
            filter: unset;
            text-decoration: none;
        }

        .btn-retry-download {
            background: url('../modules/devices/img/queue_download_error.svg') no-repeat center;
            width: 22px;
            height: 22px;
        }

        .btn-remove {
            background: url('../modules/devices/img/queue_remove.svg') no-repeat center;
            position: absolute;
            right: 0; /* Offset to maintain a fixed position */
            width: 22px;
            height: 22px;
        }

        .queue-actions-file-size {
            color: black;
            font-size: 10px;
            margin-left: -17px;
            line-height: 45px;
            text-decoration: none;
        }
    }
    .queue-drag-placeholder {
        width: 55px;
        height: 55px;
        border: 2px dotted black; // Set the border style to dotted
        display: flex;
        align-items: center; // Vertically center content
        justify-content: center; // Horizontally center content
        animation: fadeBorder 2s infinite;
        border-radius: 8px;
    }
    .queue-currently-playing {
        vertical-align: text-top;
        span {
            animation: fadeColor 4s infinite;
            font-size: 19px;
        }
    }

    @keyframes fadeColor {
        0%,
        100% {
            opacity: 1; // Fully opaque
        }
        50% {
            opacity: 0.5; // Nearly transparent
        }
    }

    @keyframes fadeBorder {
        0%,
        100% {
            border-color: rgba(0, 0, 0, 1); // Fully opaque
        }
        50% {
            border-color: rgba(0, 0, 0, 0.5); // Nearly transparent
        }
    }
}

.downloads-mgmt-info-container {
    display: flex;
    padding: 20px;

    .downloads-mgmt-column {
        text-align: left;
    }

    .downloads-mgmt-column:not(:last-child) {
        margin-right: 80px;
    }

    .downloads-mgmt-title {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
        color: black;
    }

    .downloads-mgmt-details {
        font-size: 14px;
        margin: 0px 0;
        color: black;
    }
}

.queue-mgmt-unsaved {
    background-color: #f8d7da;
    border-radius: 4px;
    color: #f7254d;
    font-size: 16px;
    font-weight: 600;
    height: 29px;
    align-content: center;
    margin-bottom: 10px;

    .glyphicon {
        margin-left: 15px;
        margin-right: 6px;
        font-size: 19px;
        vertical-align: -4px;
        font-weight: 100;
    }
}
