.progress {
    height: 4px;
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: none;
    .thumbnail-cover-actions & {
        height: 100%;
        .progress-bar {
            @include progress-bar-variant(rgba($progress-bar-bg, $progressbar-opcaity));
            position: relative;
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
                height: 4px;
                @include progress-bar-variant($progress-bar-bg);
            }
        }
        .progress-bar-success {
            @include progress-bar-variant(rgba($progress-bar-success-bg, $progressbar-opcaity));
            &:before {
                @include progress-bar-variant($progress-bar-success-bg);
            }
        }

        .progress-bar-info {
            @include progress-bar-variant(rgba($progress-bar-info-bg, $progressbar-opcaity));
            &:before {
                @include progress-bar-variant($progress-bar-info-bg);
            }
        }

        .progress-bar-warning {
            @include progress-bar-variant(rgba($progress-bar-warning-bg, $progressbar-opcaity));
            &:before {
                @include progress-bar-variant($progress-bar-warning-bg);
            }
        }

        .progress-bar-danger {
            @include progress-bar-variant(rgba($progress-bar-danger-bg, $progressbar-opcaity));
            &:before {
                @include progress-bar-variant($progress-bar-danger-bg);
            }
        }
    }
}

// Bar of progress
.progress-bar {
    box-shadow: none;
    // opacity: 0.5;
}
