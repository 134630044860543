@mixin custom-thumbnail-action($icon: 'plus') {
    a {
        @extend .glyphicon, .glyphicon-#{$icon};
    }
}
// @mixin custom-thumbnail-action-fa($icon:'plus'){
//   a{
//     @extend .fa, .fa-#{$icon};
//   }
// }

/// Actions by name
.thumbnail-action-wishlist.on {
    @include custom-thumbnail-action('heart');
}
.thumbnail-action-wishlist.off {
    @include custom-thumbnail-action('heart-empty');
}
.thumbnail-action-follow.on {
    @include custom-thumbnail-action('minus-sign');
}
.thumbnail-action-follow.off {
    @include custom-thumbnail-action('plus-sign');
}
.thumbnail-action-admin.on {
    @include custom-thumbnail-action('font');
    a {
        color: white;
    }
}
.thumbnail-action-admin.off {
    @include custom-thumbnail-action('font');
}
.thumbnail-action-share {
    @include custom-thumbnail-action('share-alt');
}
.thumbnail-action-manage {
    @include custom-thumbnail-action('list-alt');
}
.thumbnail-action-report {
    @include custom-thumbnail-action('flag');
}
.thumbnail-action-photo {
    @include custom-thumbnail-action('camera');
}
.thumbnail-action-edit {
    @include custom-thumbnail-action('pencil');
}
.thumbnail-action-delete {
    @include custom-thumbnail-action('trash');
}
.thumbnail-action-social-share {
    @include custom-thumbnail-action('share');
}
