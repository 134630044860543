.docs {
    * {
        white-space: normal !important;
    }
    strong {
        font-weight: 500;
    }
    margin-bottom: 80px;
    ol {
        counter-reset: item;
        padding-left: 0;
    }
    > ol {
        padding-left: 3em;
        > li > ol {
            margin-bottom: 20px;
        }
    }
    li {
        // padding-left: 3em;
        position: relative;
        display: block;
        margin-bottom: 6px;
        &:before {
            content: counters(item, '.') '.';
            counter-increment: item;
            display: block;
            position: absolute;
            left: -3em;
            // padding-right: 0.5em;
            top: 0;
        }
    }
    h3 {
        display: inline;
        font-size: $font-size-h4;
    }
    .nested-list {
        list-style-position: outside;
        padding-left: 2em;
        li {
            display: list-item;
            &:before {
                display: none;
            }
        }
    }
    ul.nested-list {
        list-style-type: disc;
    }
    .lower-roman {
        list-style-type: lower-roman;
    }
    .lower-latin {
        list-style-type: lower-latin;
    }
}

@media (max-width: $screen-sm-max) {
    .doc-body {
        max-height: 20em;
        overflow-y: scroll;
    }

    .docs {
        margin-bottom: 10px;
    }
}
