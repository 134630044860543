@import 'artcast-thumbnail-labels', 'artcast-thumbnail-actions', 'artcast-thumbnail-cover-actions';
@mixin thumbnail-proportion($w: 1, $h: 1) {
    padding-bottom: percentage($h / $w);
}

.artcast-thumbnail {
    position: relative;
    border: 0;
    margin-bottom: 50px;
    background-color: transparent;
    .caption {
        padding: 0;
        position: relative;
        small {
            color: #999;
        }
    }
    .repeated-thumbnail {
        margin-bottom: 3.5em;
        @media (max-width: $screen-xs-max) {
            margin-bottom: 2.5em;
        }

        &.ng-enter {
            // -webkit-transition:all 0.3s linear;
            // transition:all 0.3s linear;
        }

        &.ng-leave.ng-leave-active,
        &.ng-enter,
        &.ng-move {
            opacity: 0;
        }

        &.ng-leave,
        &.ng-enter.ng-enter-active,
        &.ng-move.ng-move-active {
            opacity: 1;
        }
    }

    .thumbnail-upload-form,
    .catalog-thumbnail {
        margin-bottom: 10px;
    }

    .thumbnail-no-link {
        .thumbnail-content {
            &:hover {
                a .thumbnail-images {
                    opacity: 1;
                }
            }
        }
    }
    .thumbnail-content {
        position: relative;
        margin-bottom: 10px;
        /*border:2px solid #333;*/

        .thumbnail-caption-over &,
        .thumbnail-1-to-1 & {
            border: 0;
        }

        &:hover {
            .thumbnail-images-container {
                background: #000;
            }
            a .thumbnail-images {
                opacity: 0.7;
            }
            .thumbnail-actions {
                /*       right: 3px; */
                opacity: 1;
                a:focus {
                    text-decoration: none;
                }
            }
        }

        .thumbnail-actions-visible & {
            .thumbnail-actions {
                opacity: 1;
            }
        }
    }
    .thumbnail-images-container {
        display: block;
        position: relative;
        width: 100%;
        height: 0;
        @include thumbnail-proportion(16, 9);
        background: #ddd;
        overflow: hidden;
        .thumbnail-1-to-1 & {
            /*     border:0; */
            // border-radius: 10px;
            // overflow:hidden;
        }
    }
    .thumbnail-images {
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        display: flex;
        flex-flow: row nowrap;
        transition: opacity 0.2s;
    }
    .thumbnail-image {
        flex: 1 1 auto;
        @extend %cover-background;
        display: block;
        background-color: $thumbnail-border;
    }
    .thumbnail-cover-actions {
        // @extend .thumbnail-images;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $body-bg;
        // position: absolute;
        // top:0;
        // left:0;
        // right:0;
        // bottom:0;
        input {
            display: none;
        }
    }

    .thumbnail-cover-actions.thumbnail-cover-actions-no-background {
        background: none;
    }

    .thumbnail-cover-action {
        // @extend .thumbnail-image;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding: 1em;
        text-align: center;
        text-decoration: none;
        font-size: $font-size-large;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        border: 2px solid #333;
    }
    .thumbnail-cover-action-icon,
    .thumbnail-cover-action-title,
    .thumbnail-cover-action-subtitle,
    .thumbnail-cover-action-upper-subtitle {
        // display: block;
        flex: 0 1 auto;

        // background-image: linear-gradient(top, $thumbnail-border, pink);
        // display: inline-flex;
        // flex-flow: column nowrap;
        // justify-content: center;
        // align-items: center;
        position: relative;
        // z-index:3;
        // opacity: 0.5;
    }

    .thumbnail-cover-action-icon {
        font-size: 1.5em !important;
        // height: 50%;
        // width: 1em;
        // flex: 0 10 auto;
        // text-align: center;
        // margin:0 auto;
        @extend .glyphicon;
    }
    .thumbnail-cover-action-title,
    .thumbnail-cover-action-subtitle,
    .thumbnail-cover-action-upper-subtitle {
        // height: 25%;

        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
    }
    .thumbnail-cover-action-title {
        font-weight: bold;
        font-size: 16px;
    }
    .thumbnail-cover-action-subtitle {
        font-size: 11px;
        margin-top: 15px;
    }
    .thumbnail-cover-action-upper-subtitle {
        font-size: 13px;
        margin-bottom: 15px;
    }

    .thumbnail-actions {
        @extend .list-unstyled;
        position: absolute;
        top: 3px;
        right: 3px;

        /*   background: rgba; */
        /*   border: 1px solid $btn-default-border; */
        opacity: 0;
        text-align: -webkit-right;

        // -webkit-transition:all 0.2s ease;
        transition: opacity 0.2s;

        font-size: $font-size-base;
        .tooltip-inner {
            white-space: nowrap;
        }
        input {
            display: none;
        }
    }

    @media (max-width: $screen-sm-max) {
        .thumbnail-action .tooltip {
            display: none !important;
        }
    }

    .thumbnail-actions.thumbnail-actions-photo {
        right: 0;
    }
    .thumbnail-action {
        a {
            font-size: 1em !important;
            display: block;
            height: 2em;
            width: 2em;
            text-align: center;
            color: #fff;
            text-decoration: none;
            text-shadow: 0 0 2px black, 0 0 1px black;
            text-shadow: 0 1px 0 #999,
                /* 0 2px 0 #666,
           0 3px 0 #bbb,
           0 4px 0 #b9b9b9,
           0 5px 0 #aaa, */
                    0 5px 1px rgba(0, 0, 0, 0.1),
                0 0 5px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
                0 4px 10px rgba(0, 0, 0, 0.25),
                /*0 10px 10px rgba(0,0,0,.2),
      0 20px 20px rgba(0,0,0,.15)*/;

            &:hover {
                /*       color: #fff; */
            }
            &:before {
                line-height: 2em;
            }
        }
    }
    .thumbnail-title,
    .thumbnail-subtitle {
        // padding-left: 0;
        // padding-right: 0;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    .thumbnail-title {
        font-weight: 700;
        small {
            font-weight: 400;
        }
        .thumbnail-caption-over & {
            position: absolute;
            top: -($line-height-computed + $font-size-h4 + ($line-height-computed / 2)+4);
            left: 5px;
            color: #fff;
            font-weight: 600;
            line-height: 1;
        }
        .thumbnail-title-text {
            .thumbnail-caption-over & {
                text-overflow: clip;
                white-space: normal;
            }
        }
        &.thumbnail-title-over {
            position: absolute;
            bottom: -20px;
            width: 100%;

            .thumbnail-title-text {
                &-alert {
                    background: $gray-light;
                    color: #fff;
                    opacity: 0.8;
                    font-size: 13px;
                    font-weight: 400;
                    margin: -1px 0 20px;
                    padding: 3px 5px;
                    text-align: center;
                }
            }
        }
    }
    .thumbnail-subtitle {
        // min-height: 33px;
    }
    .thumbnail-title-text {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
    // .thumbnail-userpic,
    .thumbnail-title-extra {
        flex: 0 0 auto;
        display: block;
        align-self: flex-end;
    }

    .thumbnail-userpic {
        display: inline-block;
        width: $line-height-computed + $font-size-h4;
        height: $line-height-computed + $font-size-h4;
        margin-right: $thumbnail-caption-padding;
        @extend %cover-background;
        // border-radius: 50%;
        // border:1px solid #333;
    }

    .thumbnail-title-extra {
        margin-left: $thumbnail-caption-padding * 2;
        .thumbnail-title & {
            // font-weight: 400;
        }
    }

    .thumbnail-description {
        height: $line-height-base * 12px * 2;
        overflow: hidden;
        font-size: 12px;
        margin-top: 5px;
        /*   position:relative; */
    }

    // thumbnail proportions
    .thumbnail-1-to-1 {
        .thumbnail-images-container {
            @include thumbnail-proportion(1, 1);
        }
    }
    .thumbnail-16-to-5 {
        .thumbnail-images-container {
            @include thumbnail-proportion(16, 5);
        }
    }
    // thumbnail black caption
    .thumbnail-caption-over {
        .thumbnail-content {
            overflow: hidden;
        }
        .thumbnail-images {
            bottom: -15px;
            top: 0;
            left: -15px;
            right: -15px;
        }

        .thumbnail-image {
            overflow: hidden;
            position: relative;
            &:after {
                /*       content:'';
              position: absolute;
              bottom: 0px;
              top: 0;
              left:0px;
              right:0px;
              background:inherit;
              // background:red;
              // background-position: center bottom;
              filter: blur(10px) brightness(.6);
              mask-image: linear-gradient(top, rgba(0,0,0,0) 40%, rgba(0,0,0,1) 80%); */

                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;

                bottom: 0;
                background-image: linear-gradient(
                    to top,
                    rgba(0, 0, 0, 0.5) 15%,
                    rgba(0, 0, 0, 0.2) 45%,
                    rgba(0, 0, 0, 0) 70%
                );
            }
        }
    }
    // thumbnail no border
    .thumbnail-borderless {
        background: none;
        border: none;
        .thumbnail-content {
            border: 1px solid $thumbnail-border;
        }
        .caption {
            border-top: none;
        }
        .thumbnail-title,
        .thumbnail-subtitle {
            padding-right: 0;
            padding-left: 0;
        }

        &.thumbnail-caption-over {
            .thumbnail-title {
                padding-left: $thumbnail-caption-padding;
                padding-right: $thumbnail-caption-padding;
                border: 1px solid $thumbnail-border;
                border-width: 0 1px 1px;
            }
        }
    }
    // thumbnail small
    .thumbnail-small {
        .thumbnail-actions {
            font-size: $font-size-base;
        }
        .thumbnail-title {
            font-size: $font-size-h5;
            margin-bottom: ($line-height-computed / 3);
        }
        .thumbnail-subtitle {
            margin-top: ($line-height-computed / 3);
            margin-bottom: ($line-height-computed / 3);
            font-size: $font-size-h6;
            min-height: 25px;
        }

        .thumbnail-userpic {
            width: $line-height-computed + $font-size-h5;
            height: $line-height-computed + $font-size-h5;
        }
        .btn-sm {
            @extend .btn-xs;
        }
        &.thumbnail-caption-over {
            .thumbnail-images-container {
                margin-bottom: ($line-height-computed + $font-size-h5) * -1;
            }
            .thumbnail-title {
                height: $line-height-computed + $font-size-h5;
                line-height: $line-height-computed + $font-size-h5;
            }
        }
        .thumbnail-cover-action {
            font-size: $font-size-base;
        }
        .thumbnail-cover-action-subtitle,
        .thumbnail-cover-action-upper-subtitle {
            font-size: $font-size-small;
            .btn {
                @extend .btn-sm;
            }
        }
    }
    // thumbnail with actions expanded
    .thumbnail-expanded {
        font-size: $font-size-large;
        margin-bottom: 2em;
        .thumbnail-content {
            &:hover {
                .thumbnail-images {
                    opacity: 1;
                }
            }
        }
        .thumbnail-actions {
            opacity: 1;
            top: 100%;
            right: 0;
            margin: ($grid-gutter-width / 2 + 1) -1px 0 0;
        }
        .thumbnail-action {
            @extend .pull-right;
            margin-bottom: 0;
        }
    }
    // thumbnail - watermark
    .thumbnail-watermark {
        .thumbnail-content {
            &:after {
                content: '';
                position: absolute;
                width: 26%;
                height: 26%;
                top: 37%;
                left: 37%;
                background-image: url(../niio-components/images/logo_short.svg);
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center center;
                opacity: 0.4;
            }
        }
    }

    remote-artwork-thumbnail {
        a.btn.btn-default {
            position: absolute;
            top: 8px;
            left: 28px;
            z-index: 10000;
        }

        .thumbnail-images-container {
            border: 3px #444 solid;
            -webkit-transition: border 0.1s ease-in-out;
            transition: border 0.1s ease-in-out;

            &.displayed {
                border-bottom: 5px #387ef5 solid;
            }
        }

        .thumbnail-image,
        .artwork-remote-image {
            display: block;
            position: relative;
            @extend %cover-background;
            width: 100%;
            height: 0;
            @include thumbnail-proportion(16, 9);
            -webkit-transform: translate3d(0, 0, 0);

            .artwork-remote-opacity {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                //padding-bottom: calc(100%);
                @include thumbnail-proportion(16, 9);

                background: linear-gradient(
                    to bottom,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0.65) 100%
                ); /* W3C */
                //background:rgba(0,0,0,0.6);
                z-index: 2;
            }
            .artwork-remote-image-icon-wrapper {
                position: absolute;
                display: block;
                width: 100%;
                height: 20px;
                text-align: center;
                top: 50%;
                transform: translateY(-50%);
                z-index: 3;

                .artwork-remote-image-icon {
                    position: relative;
                    display: inline-block;
                    width: 25px;
                }
            }

            .artwork-remote-image-scheduled-icon-wrapper {
                position: absolute;
                display: block;
                width: 100%;
                height: 20px;
                text-align: right;
                top: 5px;
                right: 5px;
                z-index: 3;

                .artwork-remote-image-scheduled-icon {
                    position: relative;
                    display: inline-block;
                    width: 22px;
                }
            }
        }
    }
}
