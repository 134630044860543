.main-sidebar {
    &.discover-main-sidebar {
        font-family: MuseoSans, Helvetica, Arial, sans-serif;
        letter-spacing: 0.23px;
        & > div {
            padding-bottom: 5px;
            b {
                font-size: 12px;
                font-weight: 600;
            }
        }
        .list-group-item-row {
            padding-left: 0;
            font-size: 16px;
            word-break: keep-all;
            white-space: nowrap;

            a {
                color: #333;

                &::before {
                    margin-right: 10px;
                    content: '';
                    display: inline-block;
                    border-radius: 50%;
                    border: 2px solid #fff;
                    height: 14px;
                    width: 14px;
                    position: relative;
                    box-shadow: 0px 0px 0px 1px #000;
                }
            }
            &.active {
                font-weight: 500;

                a::before {
                    background: black;
                }
            }
        }
    }
    // .page-with-header &{
    // margin-top:($line-height-computed * 1);
    // }
    //
    // List groups
    // --------------------------------------------------

    // Base class
    //
    // Easily usable on <ul>, <ol>, or <div>.

    .list-group {
        // No need to set list-style: none; since .list-group-item is block level
        margin-bottom: 20px;
        padding-left: 0; // reset padding because ul and ol
        font-size: 14px;
        .list-group {
            font-size: 0.9em;
            margin-bottom: -1px;
            margin-top: -1px;
            margin-left: 10px;
        }
    }

    // Individual list items
    //
    // Use on `li`s or `div`s within the `.list-group` parent.

    .list-group-item {
        position: relative;
        display: block;
        padding: 10px 15px;
        padding: 0;
        // Place the border on the list items and negative margin up for better styling
        margin-bottom: -1px;
        // margin-bottom: 0;
        background-color: $list-group-bg;
        border: 1px solid $list-group-border;
        border: none;

        // Round the first and last items
        &:first-child {
            @include border-top-radius($list-group-border-radius);
        }
        &:last-child {
            margin-bottom: 0;
            @include border-bottom-radius($list-group-border-radius);
        }

        // Align badges within list items
        > .badge {
            float: right;
        }
        > .badge + .badge {
            margin-right: 5px;
        }
    }
    .list-group-item-row {
        padding: 5px 6px;
        //  border: 1px solid $list-group-border;
        border-width: 1px 0;
        display: block;
        flex-flow: row nowrap;

        &.active {
            font-weight: 700;
        }
        .glyphicon-chevron-down {
            &.collapsed {
                @extend .glyphicon-chevron-right;
            }
        }

        select {
            border: none !important;
            width: 200px;
            font-style: italic;
        }
    }
    .list-group-item-text {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .list-group-item-textbox {
        flex: 1 1 auto;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // white-space: nowrap;
    }
    .list-group-item-extra {
        flex: 0 0 auto;
        padding: 0 0.25em;
        input[type='checkbox'] {
            vertical-align: text-top;
            margin: 0;
        }
    }
    .list-group-collapse-toggle {
        cursor: pointer;
        &.collapsed {
            * {
                transform: rotate(-90deg);
            }
        }
    }

    // Linked list items
    //
    // Use anchor elements instead of `li`s or `div`s to create linked list items.
    // Includes an extra `.active` modifier class for showing selected items.

    a.list-group-item {
        color: $list-group-link-color;

        .list-group-item-heading {
            color: $list-group-link-heading-color;
        }

        // Hover state
        &:hover,
        &:focus {
            text-decoration: none;
            color: $list-group-link-hover-color;
            background-color: $list-group-hover-bg;
        }
    }

    .list-group-item {
        // Disabled state
        &.disabled,
        &.disabled:hover,
        &.disabled:focus {
            background-color: $list-group-disabled-bg;
            color: $list-group-disabled-color;

            // Force color to inherit for custom content
            .list-group-item-heading {
                color: inherit;
            }
            .list-group-item-text {
                color: $list-group-disabled-text-color;
            }
        }

        a:focus,
        a:hover {
            text-decoration: none;
        }

        // Active class on item itself, not parent
        &.active,
        &.active:hover,
        &.active:focus {
            z-index: 2; // Place active items above their siblings for proper border styling
            color: $list-group-active-color;
            background-color: $list-group-active-bg;
            border-color: $list-group-active-border;

            // Force color to inherit for custom content
            .list-group-item-heading,
            .list-group-item-heading > small,
            .list-group-item-heading > .small {
                color: inherit;
            }
            .list-group-item-text {
                color: $list-group-active-text-color;
            }
        }
    }

    // Contextual variants
    //
    // Add modifier classes to change text and background color on individual items.
    // Organizationally, this must come after the `:hover` states.

    @include list-group-item-variant(success, $state-success-bg, $state-success-text);
    @include list-group-item-variant(info, $state-info-bg, $state-info-text);
    @include list-group-item-variant(warning, $state-warning-bg, $state-warning-text);
    @include list-group-item-variant(danger, $state-danger-bg, $state-danger-text);

    // Custom content options
    //
    // Extra classes for creating well-formatted content within `.list-group-item`s.

    .list-group-item-heading {
        margin-top: 0;
        margin-bottom: 5px;
    }
    .list-group-item-text {
        margin-bottom: 0;
        line-height: 1.3;
    }
}

.sidebar-title {
    display: inline-block;
    font-weight: 700;
    text-transform: uppercase;
    padding: 5px 6px 10px;
    color: #333;
}

.clearLink {
    font-size: 14px;
    color: #6f6f6f;
    text-decoration: underline;
    transition: opacity 0.5s;

    &.no-list {
        color: #6f6f6f;
        opacity: 0;
        pointer-events: none;
    }
}

.mr-5 {
    margin-right: 5px;
}
