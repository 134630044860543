$primary-foreground-color: #fff; // #fff default
$primary-background-color: #2b333f; // #2B333F default
$center-big-play-button: true; // true default

.video-js .vjs-tech {
    //position: static;
}

.video-js {
    padding-top: 0;
    font-size: 10px;
    background-color: black;
    color: $primary-foreground-color;
}

.vjs-default-skin .vjs-big-play-button {
    //font-size: 3em;
    //$big-play-width: 2em;
    //$big-play-height: 1.5em;
    //line-height: $big-play-height;
    //height: $big-play-height;
    //width: $big-play-width;
    //border: 0.06666em solid $primary-foreground-color;
    //border-radius: 2px;
    //@if $center-big-play-button {
    //  left: 50%;
    //  top: 50%;
    //  margin-left: -($big-play-width / 2);
    //  margin-top: -($big-play-height / 2)-0.2;
    //} @else {
    //  left: 0.5em;
    //  top: 0.5em;
    //}
}

.video-js .vjs-control-bar,
  //.video-js .vjs-big-play-button,
.video-js .vjs-menu-button .vjs-menu-content {
    /* IE8 - has no alpha support */
    background-color: $primary-background-color;
    /* Opacity: 1.0 = 100%, 0.0 = 0% */
    background-color: rgba($primary-background-color, 0.9);
}

.vjs-control-bar {
    //border: 2px solid $primary-foreground-color;
    //box-sizing: border-box;
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
    }
}

// Make a slightly lighter version of the main background
// for the slider background.
$slider-bg-color: black; //lighten($primary-background-color, 33%);

/* Slider - used for Volume bar and Progress bar */
.video-js .vjs-slider {
    //background-color: $slider-bg-color;
    //background-color: rgba($slider-bg-color, 1.0);
    background-color: #73859f;
    background-color: rgba(115, 133, 159, 0.5);
}

/* The slider bar color is used for the progress bar and the volume bar
   (the first two can be removed after a fix that's coming) */
.video-js .vjs-volume-level,
.video-js .vjs-play-progress,
.video-js .vjs-slider-bar {
    background: $primary-foreground-color;
}

.video-js .vjs-load-progress {
    background: #bfc7d3;
    background: rgba(115, 133, 159, 0.5);
}

.video-js .vjs-load-progress div {
    background: white;
    background: rgba(115, 133, 159, 0.75);
}

/////////////////////////////////////////////////
.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
    outline: 0;
    // border-color: #333;
    background-color: #fff;
    //background-color: rgba(1, 1, 1, 1);
}

.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0.1s, opacity 0.1s;
    -moz-transition: visibility 0.1s, opacity 0.1s;
    -o-transition: visibility 0.1s, opacity 0.1s;
    transition: visibility 0.1s, opacity 0.1s;
}

.video-js.vjs-user-inactive .vjs-progress-control .vjs-mouse-display,
.video-js.vjs-user-inactive .vjs-progress-control .vjs-mouse-display:after {
    visibility: hidden;
    opacity: 0;
    -webkit-transition: visibility 0.1s, opacity 0.1s;
    -moz-transition: visibility 0.1s, opacity 0.1s;
    -o-transition: visibility 0.1s, opacity 0.1s;
    transition: visibility 0.1s, opacity 0.1s;
}

.vjs-menu li.vjs-menu-item:focus,
.vjs-menu li.vjs-menu-item:hover {
    background-color: #dfefff;
    color: #2d2d2d;
}

.vjs-menu li.vjs-selected,
.vjs-menu li.vjs-selected:focus,
.vjs-menu li.vjs-selected:hover {
    background-color: #93c6fa;
    /*background-color: rgba(115,133,159,.5);*/
    color: #2d2d2d;
}

.vjs-subs-caps-button {
    display: none !important;
}

.fallbackSrc .vjs-quality-selector {
    display: none !important;
}

.vjs-looping .vjs-loading-spinner {
    display: none;
}

.video-js .vjs-big-play-button {
    background-color: transparent;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../modules/core/img/play.svg');
    background-repeat: no-repeat;
    background-position: 50% calc(50% - 10px);
    border: none !important; // @stylint ignore
    box-shadow: none !important; // @stylint ignore
    .vjs-icon-placeholder:before {
        content: '';
    }
    &:before {
        content: '';
        display: none;
    }

    &:hover {
        background-color: transparent;
        opacity: 0.7;
    }
    @media (max-width: $screen-xs) {
        background-size: 50px;
    }
}

.vjs-big-play-centered .vjs-big-play-button {
    margin-left: -0.75em !important;
}

.video-js:hover .vjs-big-play-button,
.video-js .vjs-big-play-button:focus {
    border-color: #fff;
    // background-color: #73859f;
    // background-color: rgba(115, 133, 159, 0.5);
    -webkit-transition: all 0s;
    -moz-transition: all 0s;
    -ms-transition: all 0s;
    -o-transition: all 0s;
    transition: all 0s;
}
